import { gql } from "@apollo/client";

export const CREATE_MAKER = gql`
  mutation createMaker(
    $name: String!
    $email: String!
    $password: String!
    $location: String!
    $countryCode: String!
    $mobile: String!
    $measurementSystem:String!
  ) {
    createMaker(
      name: $name
      email: $email
      password: $password
      location: $location
      countryCode: $countryCode
      mobile: $mobile
      measurementSystem:$measurementSystem
    ) {
      makerId
      name
      email
      token
      tokenExpiration
      isSubscriptionActive
      measurementSystem
      stripeCustomerId
    }
  }
`;
export const LOGIN = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      makerId
      name
      email
      token
      tokenExpiration
      isSubscriptionActive
      measurementSystem
      stripeCustomerId

    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const DASHBOARD = gql`
  query dashboard {
    dashboard {
      totalOrders
      openOrders
      doneOrders
      totalClients
      totalSuppliers
      orderRequiringAttention {
        id
        createdAt
        client {
          id
          name
        }
        dueDate
        products {
          id
         materials{
          suppliesId{
          id
          }
          suppliesQuantity
        }
        }
        orderStatus
        status
        makerId
        totalProductsCount
      }

      orderVsProduct {
        id
        productId {
          name
        }
        orderCount
      }
      supplyDetails {
        suppliesName
        suppliesQuantity
      }
      makerId
    }
  }
`;
export const CREATE_SUBSCRIPTION_PAYMENT = gql`
  mutation createSubscriptionPayment($amount: Int!
$currency: String) {
    createSubscriptionPayment(amount:$amount,currency:$currency) {
      clientSecret
    }
  }
`;




export const CREATE_SUBSCRIPTION_DETAILS= gql`
mutation createSubscriptionDetails($name:String!,$durationInDays:Float!,$startDate:String,$endDate:String!,$subscriptionID: String!,$amount:String!,$isFreeTrial: Boolean,$currency: String
$invoiceNumber: String
$quantity: String
$invoiceCreated: String
$trial_start: String
$trial_end: String

$latestInvoiceAmountDue: String
$latestInvoiceAmountPaid: String
$paymentId:String){
  createSubscriptionDetails(name:$name,durationInDays:$durationInDays,startDate:$startDate,endDate:$endDate,subscriptionID:$subscriptionID,amount:$amount,isFreeTrial:$isFreeTrial ,currency:$currency,
invoiceNumber: $invoiceNumber,
quantity: $quantity,
invoiceCreated:$invoiceCreated
trial_start:$trial_start
trial_end:$trial_end
latestInvoiceAmountDue:$latestInvoiceAmountDue
latestInvoiceAmountPaid:$latestInvoiceAmountPaid
paymentId:$paymentId
 ){   
    id
    name
    durationInDays
    startDate
    endDate
    subscriptionID
        amount
    makerId{
    name
    email}
    status
    subscriptionHistory{
      id
      name
      durationInDays
      startDate
      endDate
      amount
      isFreeTrial
    }
    isFreeTrial
currency
invoiceNumber
quantity
invoiceCreated
expiryEmailSend
trial_start
trial_end
latestInvoiceAmountDue
latestInvoiceAmountPaid
paymentId
    }
    }`
    export const CREATE_SUBSCRIPTION=gql`
    mutation createSubscriptionInStripe($priceId:String! $paymentMethod:String $promoCode: String 
){
    createSubscriptionInStripe(priceId:$priceId paymentMethod:$paymentMethod promoCode: $promoCode ){
      clientSecret
subscriptionID
currency
current_period_end
current_period_start
invoiceNumber
amount
quantity
invoiceCreated
trial_start 
        trial_end 
        latestInvoiceAmountDue 
        latestInvoiceAmountPaid
 
    }
    }`
    export const GET_PROMO_CODE = gql`
    query($promoCode:String!){
  getStripePromoCodeFromStripe(promoCode:$promoCode){
    id
    object
    active
    code
    coupon{
      id
      object
      amount_off
      created
      currency
      duration
      duration_in_months
      livemode
      max_redemptions
      name
      percent_off
      redeem_by
      times_redeemed
      valid
    }
    created
    customer
    expires_at
    livemode
    max_redemptions
    times_redeemed
  }
}
  `;