import React from "react";
import ClientForm from "./ClientForm";

const AddClient = (props) => {  
  return (
    <ClientForm
    title="Add Client"
    text="Add Client"
    {...props}
  />
  );
};

export default AddClient;
