import React from "react";
import SuppliersForm from "./SuppliersForm";

const EditSuppliers = (props) => {  
  return (
    <SuppliersForm
    title="Edit supplier"
    text="Edit supplier"
    {...props}
  />
  );
};

export default EditSuppliers;
