import { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./apollo";
import ReduxProvider from "./redux";
import MainRoute from "./MainRoute";

function App(props) {
  
  
  return (
      <ReduxProvider>
        <ApolloProvider client={client}>
         <MainRoute {...props}/>
        </ApolloProvider>
      </ReduxProvider>
  );
}

export default App;
