import "./button.css"
const AddButton = (props) => {
    // console.log("ADD_BUTTON",props.onclick,props.text)
    return (
        props.type === "button" ?
            <button className="addbutton" type={props.type} onClick={props.onclick} >
                <span className="button-text">{props.text}</span>
            </button>
            :
            <button className="addbutton" type={props.type} onSubmit={props.onSubmit} >
                <span className="button-text">{props.text}</span>
            </button>
    );
}
export default AddButton