import PropTypes from "prop-types";
import React from "react";

const MySVG = ({ color = "#000000", className }) => {
  return (
    <svg
    className={`traditional-residence-home-having-shelter-roof-design-2-1 ${className}`}

      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 360 360"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
        <path d="M1700 3131 c-55 -17 -108 -52 -153 -100 -47 -50 -86 -119 -87 -152 0 -11 -33 -32 -92 -61 -173 -83 -308 -214 -392 -378 -91 -178 -106 -259 -106 -584 0 -410 -27 -601 -105 -757 -36 -69 -112 -143 -172 -164 -96 -35 -128 -103 -77 -164 l26 -31 152 0 c182 0 246 -7 246 -27 0 -36 55 -125 105 -172 78 -71 145 -94 255 -89 101 5 158 28 224 88 54 50 84 95 101 153 7 23 19 43 26 43 8 1 328 2 712 3 l698 1 24 25 c29 28 34 88 10 121 -7 12 -41 31 -74 43 -117 43 -197 151 -240 326 -33 133 -42 236 -46 554 -5 320 -11 375 -49 490 -80 233 -241 420 -451 522 -80 38 -84 42 -99 87 -33 98 -135 194 -236 222 -41 11 -164 12 -200 1z m176 -196 c17 -13 10 -14 -76 -14 -87 0 -93 1 -70 14 33 19 121 19 146 0z m142 -235 c259 -72 440 -252 509 -503 13 -51 17 -118 19 -367 2 -283 5 -353 20 -452 l6 -38 -768 0 -767 0 7 68 c5 37 11 222 16 412 8 381 11 399 77 530 44 89 165 215 256 269 72 42 172 79 262 97 94 18 268 11 363 -16z m622 -1620 c14 -36 38 -84 53 -107 15 -24 26 -44 25 -44 -7 -4 -1815 -5 -1825 -2 -7 3 -1 20 17 46 15 23 41 73 56 110 l28 67 809 -2 810 -3 27 -65z m-1205 -350 c6 -10 -42 -58 -77 -76 -22 -11 -48 -15 -90 -12 -50 4 -63 9 -93 38 -60 58 -53 61 110 59 80 -1 147 -5 150 -9z" />
      </g>
    </svg>
  );
};

MySVG.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default MySVG;
