import React,{useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route ,useNavigate} from 'react-router-dom';
import Sidebar from '../../Components/Navbar/Sidebar';
import TopNavbar from '../../Components/Navbar/TopNavbar';
import Client from "../../Components/Client/Client"
import Orders from '../../Components/Orders/Orders'
import Supplies from "../../Components/Supplies/Supplies"
import Suppliers from "../../Components/Suppliers/Suppliers"
import AddClient from "../../Components/Client/AddClient"
import EditClient from "../../Components/Client/EditClient"
import AddSuppliers from '../../Components/Suppliers/AddSuppliers';
import EditSuppliers from '../../Components/Suppliers/EditSuppliers';
import AddOrder from '../../Components/Orders/AddOrder';
import EditOrder from '../../Components/Orders/EditOrder';
import SuppliesForm from '../../Components/Supplies/SuppliesForm';
import AddSupplies from '../../Components/Supplies/AddSupplies';
import EditSupplies from '../../Components/Supplies/EditSupplies';
import Dashboard from "../../Components/Dashboard/Dashboard"
import Subdetails from '../../Components/Subscription/Subdetails';
import "./sign.css"
import Footer from '../../Components/LandingPage/Footer';
import Changepassword from '../../Components/Navbar/Changepassword';
const SignInRoutes = (props) => {
  const makerid = localStorage.getItem('makerId');
  const token = localStorage.getItem('token');
  const subscriptionActive = localStorage.getItem("subscription") === "true";
  const status = localStorage.getItem("status");
  const navigate = useNavigate();
  

  useEffect(() => {
    // Check if both makerid and token are null
    if (makerid === null && token === null) {
      // Navigate to the root path "/"
      navigate('/');
    }
 
  }, [makerid, token, navigate]);
  // useEffect(()=>{
   
  //  if(subscriptionActive || status === "active"){
  //     navigate('/home')
  //   }
  //   else if(token&&subscriptionActive||token&&status==="active"){
  //     navigate('/home')
  //   }else{
  //     navigate('/')
  //   }
  
  // },[token,subscriptionActive,status])


  return (
      <div className="main container-fluid">
        <div className='row' style={{width:'100%',height:"100%"}}>
          <div className='col-md-3 col-lg-3 col-xs-12'>
            <Sidebar {...props}/>
          </div>
          <div className='col-md-9 col-lg-9 col-xs-12'>
            <TopNavbar {...props} />

            <div className='row'>
              <Routes>    
                <Route path="/" element={<Dashboard {...props}/>}/>           
                 <Route path="/clients" element={<Client {...props}/>} />
                 <Route path="/orders" element={<Orders {...props}/>} />
                 <Route path="/supplies" element={<Supplies {...props}/>} />
                 <Route path="/suppliers" element={<Suppliers {...props}/>} />
                 <Route path="/clients/addclients" element={<AddClient {...props}/>} />
                 <Route path="/clients/editclients" element={<EditClient {...props}/>} />
                 <Route path="/suppliers/addsuppliers" element={<AddSuppliers {...props}/>} />
                 <Route path="/suppliers/editsuppliers" element={<EditSuppliers {...props}/>} />
                 <Route path="/orders/addorders" element={<AddOrder {...props}/>}/>
                 <Route path="/orders/editorders" element={<EditOrder {...props}/>}/>
                 <Route path="/supplies/addsupplies" element={<AddSupplies {...props}/>}/>
                 <Route path="/supplies/editsupplies" element={<EditSupplies {...props}/>}/>
<Route path="/subdetails" element={<Subdetails {...props}/>}/>
<Route path="/changepassword" element={<Changepassword {...props}/>}/>
              </Routes>
            </div>
          </div>
        </div>
        <div >
          <Footer {...props}/>
        </div>
      </div>
  );
}

export default SignInRoutes;
