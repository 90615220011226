import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import "./button.css";
import * as Yup from "yup";
import Snackbar from "../Snackbar/Alert";


const PasswordPopup = (props) => {
  
const [showSnackbar,setSnackbar]=useState(false)
  const handleCancel = () => {
    
    props.onCancel();
  };
const initialValues={
  email:""
}
const validationSchema = Yup.object().shape({
  email:Yup.string()
  .required("Email address is required *"),
})
const formik = useFormik({
  initialValues: initialValues,
  validationSchema: validationSchema,
  onSubmit: (values) => {
    const lowercaseEmail = values.email.toLowerCase();

    setSnackbar(true)
    props.resetPassword({email:lowercaseEmail})
  }
})


  // Close the popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".Password-popup")) {
        props.onCancel(); // Close the delete popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);
  useEffect(() => {
    let timeout;
    if (showSnackbar && props.resetSuccess === true) {
      timeout = setTimeout(() => {
        props.onCancel();
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [showSnackbar, props.resetSuccess, props.onCancel]);

// console.log("props",props)
  return (
      <div className="Password-popup">
        <h1 className="password-header">Reset password</h1>
        <span className="advice">We advise changing your password when you log in for enhanced security measures.</span>
        <form onSubmit={formik.handleSubmit}>

        <label className="password-label">Email address</label>
       
        <input  id="email"
          name="email" type="text" value={formik.values.email} onChange={formik.handleChange} className="password-input" placeholder="Enter your registered email" />
        {formik.touched.email && formik.errors.email && (
          <div className="pass-error">{formik.errors.email}</div>
        )}
        <div className="button-container">
          <button onClick={handleCancel} className="cancel-btn">Cancel</button>
          <button type="submit" className="pass-btn">Submit</button>
        </div>
         
        </form>
{showSnackbar&&props.resetSuccess === true&&(
          <Snackbar message={props.message} type="success" />
        )}
        {showSnackbar&&props.resetFailure=== true&&(
          <Snackbar message={props.errors} type="errors" />
        )}
      </div>
  );
};

export default PasswordPopup;
