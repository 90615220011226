import React from "react";
import SuppliersForm from "./SuppliersForm";

const AddSuppliers = (props) => {  
  return (
    <SuppliersForm
    title="Add supplier"
    text="Add supplier"
    {...props}
  />
  );
};

export default AddSuppliers;
