import React, { useEffect, useState } from "react";
import DynamicTable from "../Tables/index";
import "./supplies.css";
import AddButton from "../Buttons/AddButton";
import Search from "../Search/Search";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
import next from "../../../src/icons/next.svg";
import prev from "../../../src/icons/prev.svg";
import Snackbar from "../Snackbar/Alert";
import Loader from "../Buttons/Loader";
import * as XLSX from 'xlsx';


const Supplies = (props) => {
  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [suppliesRows, setSuppliesRows] = useState([]);
  const [suppliesIds, setSuppliesIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
  const [showErrorSnackbar,setShowErrorSnackbar]=useState(false)
  const [showLoader, setShowLoader] = useState(false);
const [totalCount,setTotalCount] =useState("")
console.log("search",totalCount)
  const itemsPerPage = 10;

  const totalPages = Math.ceil(totalCount / itemsPerPage);
  useEffect(() => {
    props.listAllSupplies({
      offset: (currentPage - 1) * itemsPerPage,
      first: itemsPerPage,
    });
    setTotalCount(props?.suppliesData?.listAllSupplies?.count)
  }, [currentPage,suppliesRows]);

  const suppliesHeadings = [
    "Supplier",
    "Category",
    "Type",
    "Parameters",
    "Quantity",
  ];
  const excelHeadings = [
    "Supplier",
    "Category",
    "Type",
    "Parameters",
    "Quantity",
    "Description"
  ];
  function handleClick() {
    navigate("/home/supplies/addsupplies");
  }
  function handleEditClick(id) {
    const selectedSupplies = props?.suppliesData?.listAllSupplies?.items.find(
      (supplies) => supplies.id === id
    );

    if (selectedSupplies) {
      navigate("/home/supplies/editsupplies", {
        state: { supplies: selectedSupplies },
      });
    }
  }
  function handleDeleteClick(id) {
    props.deleteSupplies(id);
    setShowDeleteSnackbar(true);
    setShowErrorSnackbar(true)
    setShowLoader(true);
    if (suppliesIds.length === 1 && totalPages > 1) {
      // If current page becomes empty and there are more than one page,
      // move back to the previous page
      setCurrentPage(currentPage - 1);
    }
  }
  useEffect(() => {
    const defaultSuppliesItems =
      props?.suppliesData?.listAllSupplies?.items || [];
    const searchSuppliesItems =
      props?.searchSuppliesData?.searchSupplies?.items || [];

    const combinedItems =
      searchValue.trim() === "" ? defaultSuppliesItems : searchSuppliesItems;

      setSuppliesRows((prevRows) => {
        const updatedRows = combinedItems.map((item) => {
          const parametersLines = item.parameters
            ? item.parameters
                .filter((param) => param.name !== null && param.value !== null) // Filter out null values
                .map((param) => {
                  const paramLine = `${param.name}: ${param.value}${param.uom ? param.uom : ""}`;
                  return paramLine;
                })
            : [];
      
          const parametersString = parametersLines.join(",");
          return [
            item?.supplier?.name || "",
            item?.category?.value || "",
            item?.childCategory?.value || "",
            parametersString || "",
            item?.quantity || "",
          ];
        });
      
        if (!isEqual(prevRows, updatedRows)) {
          return updatedRows;
        }
      
        return prevRows;
      });
      
    if (currentPage > totalPages) {
      setCurrentPage(Math.max(1, totalPages));
    }

    if (totalPages === 0) {
      setCurrentPage(1);
    }
    setSuppliesIds((prevIds) => {
      if (
        !isEqual(
          prevIds,
          combinedItems.map((item) => item.id)
        )
      ) {
        return combinedItems.map((item) => item.id);
      }

      return prevIds;
    });
  }, [props?.suppliesData?.listAllSupplies?.items || [], searchValue]);
  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchValue(searchInput);

    if (searchInput.trim() === "") {
      props.listAllSupplies({ offset: 0, first: totalCount });
      setTotalCount(props?.suppliesData?.listAllSupplies?.count)
    } else {
      props.searchSupplies({
        pagination: { offset: 0, first: totalCount },
        searchKey: searchInput,
      });
    }
    setTotalCount(props?.searchSuppliesData?.searchSupplies?.count)
    setSuppliesIds([]);
  };
  useEffect(() => {
    props.excelSupplies({ offset: 0, first: props?.suppliesData?.listAllSupplies?.count });
   }, []);
  const handleDownloadClick = async () => {
    try {
      const excelRows = props?.excelData?.listAllSupplies?.items.map((item) => {
        const parametersLines = item.parameters
          ? item.parameters.map((param) => {
              const paramLine = `${param.name}: ${param.value}${
                param.uom ? param.uom : ""
              }`;
              return paramLine;
            })
          : ["N/A"];

        const parametersString = parametersLines.join("\n");
        return [
          item?.supplier?.name || "",
          item?.category?.name || "",
          item?.childCategory?.name || "N/A",
          parametersString,
          item?.quantity || "",
          item?.description|| ""
        ];
      });

      const sheet = XLSX.utils.aoa_to_sheet([excelHeadings, ...excelRows]);
  
      // Create the Excel workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, 'Supplies');
  
      // Trigger the download
      XLSX.writeFile(wb, 'Supplies.xlsx');
    } catch (error) {
      console.error('Error mapping data:', error);
    }
  
      // Create the Excel sheet
     
    
  };
  useEffect(() => {
    if (showDeleteSnackbar||showErrorSnackbar) {
      const timer = setTimeout(() => {
        setShowDeleteSnackbar(false);
        setShowErrorSnackbar(false)
      }, 3000); 

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showDeleteSnackbar,showErrorSnackbar]);
  console.log("supdelte",showErrorSnackbar,props.errors,props.deleteSuppliesFailure)
  return (
    <div className="supplies">
      <div className="headers">
        <div className="suppliesheader">
          <div>Supplies</div>
        </div>
        
      </div>
      <div className="search-add-container">
    <div className="search" style={{ flex: 1 }}>
      <Search value={searchValue} onChange={handleSearch} />
    </div>
    <div className="export-button">
    <button
    className="export"
        text="Download"
        type="button"
        onClick={handleDownloadClick}
        {...props}
      >Download</button>
    </div>
    <div className="add-button">
      <AddButton
        text="Add Supplies"
        type="button"
        onclick={handleClick}
        {...props}
      />
    </div>
  </div>
      <div className="table">
        <DynamicTable
          ids={suppliesIds}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          headings={suppliesHeadings}
          rows={suppliesRows}
          showStatus={true}
          showEditIcon={true}
          showExportIcon={true}
          showInfoButton={true}
          onExportClick={handleDownloadClick}
        />
        {suppliesRows.length === 0 ? (
          <div className="no-data-found">No data found</div>
        ) : totalCount <= 10 ? (
          ""
        ) : props.loading ? (
          <Loader />
        ) : (
          <div className="pagination-buttons">
            <div
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
              }
              disabled={currentPage === 1}
              className="pagination-button"
            >
              <img src={prev} alt="prev" className="pagebuttons" />
            </div>

            {Array.from({ length: totalPages }).map((_, index) => {
              const pageNumber = index + 1;

              if (totalPages <= 3 || Math.abs(currentPage - pageNumber) <= 1) {
                // Show page numbers 1, 2, 3 and ellipsis (...) if more pages
                return (
                  <div key={pageNumber} className="numbers">
                    <button
                      onClick={() => setCurrentPage(pageNumber)}
                      className={
                        currentPage === pageNumber ? "active" : "disabled"
                      }
                    >
                      {pageNumber}
                    </button>
                  </div>
                );
              } else if (
                (currentPage <= 3 && pageNumber === 4) ||
                (currentPage >= totalPages - 2 && pageNumber === totalPages - 3)
              ) {
                return (
                  <div key={pageNumber} className="ellipsis">
                    ...
                  </div>
                );
              }
              return null;
            })}

            <div
              onClick={() =>
                setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              <img src={next} alt="next" className="pagebuttons" />
            </div>
          </div>
        )}
        {showDeleteSnackbar && props.deleteSuppliesSuccess === true && (
          <Snackbar message="Supplies Deleted Successfully" type="success" />
        )}
        {showErrorSnackbar && props.deleteSuppliesFailure === true && (
          <Snackbar message={props.errors} type="errors" />
        )}
        {showLoader && props.loading === true && <Loader />}
      </div>
    </div>
  );
};

export default Supplies;
