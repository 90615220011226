import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from 'apollo-link';
import { onError } from '@apollo/client/link/error';

 
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}`
});
 
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === 'Error: Unauthenticated!') {
        // Clear local storage if authentication error occurs
        localStorage.clear();
        window.location.href = "/";
        // Redirect the user to the login page or any other appropriate action
        // window.location.href = '/login'; // Example redirection
      }
    });
  }
});
 
const uploadLink = new ApolloLink((operation, forward) => {
  return forward(operation);
});
 
const client = new ApolloClient({
  // link: authLink.concat(uploadLink.concat(httpLink)),
  link: errorLink.concat(authLink.concat(uploadLink.concat(httpLink))),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
 
export default client;