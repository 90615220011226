
import PropTypes from "prop-types";
import React from "react";

export const Suppliers = ({ color = "#3C3C3C", className }) => {
  return (
    <svg
      className={`traditional-residence-home-having-shelter-roof-design-2-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
    
       <path
        id="Vector"
        d="M8.00024 7.99951C6.90024 7.99951 5.95858 7.60784 5.17524 6.82451C4.39191 6.04118 4.00024 5.09951 4.00024 3.99951C4.00024 2.89951 4.39191 1.95785 5.17524 1.17451C5.95858 0.391179 6.90024 -0.000488281 8.00024 -0.000488281C9.10024 -0.000488281 10.0419 0.391179 10.8252 1.17451C11.6086 1.95785 12.0002 2.89951 12.0002 3.99951C12.0002 5.09951 11.6086 6.04118 10.8252 6.82451C10.0419 7.60784 9.10024 7.99951 8.00024 7.99951ZM0.000244141 15.9995V13.1995C0.000244141 12.6328 0.146244 12.1122 0.438244 11.6375C0.730244 11.1628 1.11758 10.8002 1.60024 10.5495C2.63358 10.0328 3.68358 9.64551 4.75024 9.38751C5.81691 9.12951 6.90024 9.00018 8.00024 8.99951C9.10024 8.99951 10.1836 9.12884 11.2502 9.38751C12.3169 9.64618 13.3669 10.0335 14.4002 10.5495C14.8836 10.7995 15.2712 11.1622 15.5632 11.6375C15.8552 12.1128 16.0009 12.6335 16.0002 13.1995V15.9995H0.000244141Z"
        fill={color}

      />
    </svg>
  );
};

Suppliers.propTypes = {
  color: PropTypes.string,
};
