import React from "react";
import PropTypes from "prop-types";
import {Home} from '../../../icons/Home'
import "./style.css";

export const Navbuttons = ({
  property1,
  className,
  frameClassName,
  onClick,
  icon = <Home className="traditional" color="white" />,
  text = "Home",
}) => {
  return (
    <div className={`frame ${className}`} onClick={onClick}>
      <div className={`frame-wrapper ${property1}`}>
        <div className={`div ${frameClassName}`}>
          {icon}
          <div className="home">{text}</div>
        </div>
      </div>
    </div>
  );
};

Navbuttons.propTypes = {
  property1: PropTypes.oneOf(["inactive", "active"]),
  text: PropTypes.string,
};
