import { SelectState } from "./Selector";
import {changePassword,subscriptionDatas,productIds,contactUs, createClient,createSupplies,supplierDetails,listallClients,deleteClient,updateClient,searchClients,categoriesListing,createSupplier, listallSuppliers, searchSuppliers, deleteSupplier, updateSupplier, categoryDetails, suppliesDetails, listAllSupplies, updateSupplieS, updateSupplies, deleteSupplies, searchSupplies, productsListing, createOrder, listAllOrders, deleteOrder, updateOrder, excelOrder, excelSupplies, excelSuppliers, excelClients, getClientOrder, editOrder, setMaterialsAndProducts, cancelSubscription} from "../Redux/Actions/SigninAction"
import {  createMaker,dashboard,getPromoCode,Login,resetPassword, stripePayment, subscriptionDetails, subscriptionPayment } from "../Redux/Actions/LoginActions";
import { connect } from "react-redux";
import Main from "./Main";

const mapStateToProps = (state) => {
	return { ...SelectState(state) };
};
const mapDispatchToProps = (dispatch) => {
	return {
		createClient:(params) => {
			dispatch(createClient(params))
		},
		listallClients:(params) => {
			dispatch(listallClients(params))
		},
		deleteClient:(params) => {
			dispatch(deleteClient(params))
		},
		updateClient:(params,params1) =>{
			dispatch(updateClient(params,params1))
		},
		searchClients:(params)=>{
			dispatch(searchClients(params))
		},
		createMaker:(params)=>{
			dispatch(createMaker(params))
		},
		Login:(params)=>{
			dispatch(Login(params))
		},
		categoriesListing:()=>{
			dispatch(categoriesListing())
		},
		createSupplier:(params)=>{
			dispatch(createSupplier(params))
		},
		listallSuppliers:(params)=>{
			dispatch(listallSuppliers(params))
		},
		searchSuppliers:(params)=>{
			dispatch(searchSuppliers(params))
		},
		deleteSupplier:(params)=>{
			dispatch(deleteSupplier(params))
		},
		updateSupplier:(params,params1)=>{
			dispatch(updateSupplier(params,params1))
		},
		categoryDetails:(params)=>{
			dispatch(categoryDetails(params))
		},
		suppliesDetails:(params)=>{
			dispatch(suppliesDetails(params))
		},
		supplierDetails:(params)=>{
			dispatch(supplierDetails(params))
		},
		createSupplies:(params)=>{
			dispatch(createSupplies(params))
		},
		listAllSupplies:(params)=>{
			dispatch(listAllSupplies(params))
		},
		updateSupplies:(params,params1)=>{
			dispatch(updateSupplies(params,params1))
		},
		deleteSupplies:(params)=>{
			dispatch(deleteSupplies(params))
		},
        searchSupplies:(params)=>{
			dispatch(searchSupplies(params))
		},
		productsListing:()=>{
			dispatch(productsListing())
		},
		createOrder:(params)=>{
			dispatch(createOrder(params))
		},
		listAllOrders:(params)=>{
			dispatch(listAllOrders(params))
		},
		deleteOrder:(params)=>{
			dispatch(deleteOrder(params))
		},
		updateOrder:(params)=>{
			dispatch(updateOrder(params))
		},
		excelOrder:(params)=>{
dispatch(excelOrder(params))
		},
		excelSupplies:(params)=>{
			dispatch(excelSupplies(params))
		},
		excelSuppliers:(params)=>{
			dispatch(excelSuppliers(params))
		},
		excelClients:(params)=>{
			dispatch(excelClients(params))
		},
		getClientOrder:(params,params1)=>{
			dispatch(getClientOrder(params,params1))
		},
		resetPassword:(params)=>{
			dispatch(resetPassword(params))
		},
		dashboard:(params)=>{
			dispatch(dashboard(params))
		},
		subscriptionPayment:(params)=>{
			dispatch(subscriptionPayment(params))
		},
		editOrder:(params)=>{
			dispatch(editOrder(params))
		},
		subscriptionDetails:(params)=>{
			dispatch(subscriptionDetails(params))
		},
		setMaterialsAndProducts:(params,params1)=>{
			dispatch(setMaterialsAndProducts(params,params1))
		},
		contactUs:(params)=>{
			dispatch(contactUs(params))
		},
		productIds:()=>{
			dispatch(productIds())
		},
		cancelSubscription:(params)=>{
			dispatch(cancelSubscription(params))
		},
        subscriptionDatas:()=>{
			dispatch(subscriptionDatas())
		},
		changePassword:(params)=>{
			dispatch(changePassword(params))
		},
		stripePayment:(params)=>{
			dispatch(stripePayment(params))
		},
		getPromoCode:(params)=>{
			dispatch(getPromoCode(params))
		}
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Main);
