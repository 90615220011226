import DynamicTable from "../Tables";
import BackButton from "../Buttons/BackButton";
import Select from "react-select";
import deleteIcon from "../../icons/delete.svg";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "../Snackbar/Alert";
import Loader from "../Buttons/Loader";
import "./OrderForm.css";

import * as Yup from "yup";
import OrderTable from "./OrderTable";

const OrderForm = (props) => {
  const orderHeadings = [
    "Product category",
    "Product description",
    "Materials",
    "Quantity",
  ];
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [orderRows, setOrderRows] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [productSnackbar, setProductSnackbar] = useState(false);
  const [materialSnackbar, setMaterialSnackbar] = useState(false);
  const [materialQuantities, setMaterialQuantities] = useState([{ selectedMaterial: '', quantity: '' }]);


  const [editedOrder, setEditedOrder] = useState(null);
  const [orderDescription, setOrderDescripton] = useState("");
  const [productDescription, setProductDescripton] = useState("");


  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(Array(materialQuantities.length).fill(""));
  const [isOrderValid, setIsOrderValid] = useState(false);

// Function to check if the order is valid
const checkOrderValidity = () => {
  const isValid = materialQuantities.every(item => item.selectedMaterial !== '' && item.quantity !== '');
 
  setIsOrderValid(isValid);
};
useEffect(() => {
  checkOrderValidity();
}, [materialQuantities]);
  const location = useLocation();
  let navigate = useNavigate();

  const { order } = location.state || {};

  useEffect(() => {
    props.listallClients({
      offset: 0,
      first: props?.clientdata?.count,
    });
  }, [props.listallClients]);
  useEffect(() => {
    props.productsListing();
  }, [props.productsListing]);
  useEffect(() => {
    props.listAllSupplies({
      offset: 0,
      first: props?.listallSupplies?.count,
    });
  }, [props.listallSupplies]);
  useEffect(() => {
    if (props.createOrderSucess === true) {
      setShowSnackbar(true);
      setShowLoader(false)
     
      setTimeout(() => {
        navigate("/home/orders");
      }, 1000);
    }
    else if(props.createOrderFailure === true){
      setShowLoader(false)

    }
  }, [props.createOrderSucess, navigate]);

  useEffect(() => {
    if (props.editOrderSucess) {
      setShowSnackbar(true);
      setShowLoader(false)
      setTimeout(() => {
        navigate("/home/orders");
      }, 1000);
    }
    else if(props.editOrderFailure === true){
      setShowLoader(false)

    }
  }, [props.editOrderSucess, navigate]);

  const clientOptions =
    props?.clientdata?.items?.map((client) => ({
      label: client.name,
      value: client.id,
      name: client.name,
      id: client.id,
    })) || [];

  const productOptions =
    props?.productData?.productsListing?.map((product) => ({
      label: product.name,
      value: product.id,
      name: product.name,
      id: product.id,
    })) || [];

  const materialOptions = (
    props?.suppliesData?.listAllSupplies?.items || []
  ).map((material) => {
    const parametersLines = material.parameters
            ? material.parameters
                .filter((param) => param.name !== null && param.value !== null) // Filter out null values
                .map((param) => {
                  const paramLine = `${param.name}: ${param.value}${param.uom ? param.uom : ""}`;
                  return paramLine;
                })
            : [];
      
          const parametersString = parametersLines.join(",");

    return {
      label: `${material.category?.name} - ${material.childCategory?.name} ${parametersString}`,
      value: material.id,
      name: `${material.category?.name} - ${material.childCategory?.name} ${parametersString}`,
      id: material.id,
    };
  });
  
  // const prodesc=order.products.map((orderProduct) => {
  //   return orderProduct.description
  // });
  
  
  // const proddesc=order.products
 
 

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    formik.setFieldValue("clientID", selectedOption.id);
  };
  const handleProductChange = (selectedOption, productIndex) => {
    setSelectedProduct(selectedOption);
    formik.setFieldValue(
      `products[${productIndex}].product`,
      selectedOption.id
    );
  };
  const handleMaterialChange = (selectedOption, index) => {
    const updatedQuantities = [...materialQuantities];
  updatedQuantities[index] = { ...updatedQuantities[index], selectedMaterial: selectedOption };
  setMaterialQuantities(updatedQuantities);
    setSelectedMaterial(selectedOption);
   
  };
  // const handleQuantityChange = (event, index) => {
   
  //   const newQuantity = parseInt(event.target.value, 10);

  //   if (isNaN(newQuantity) || newQuantity <= 0) {
  //     setQuantity("");
  //     setErrorMessage(" quantity must be greaterthan zero");
  //   } else {
  //     const updatedQuantities = [...materialQuantities];
  //     updatedQuantities[index].quantity = newQuantity;
  //     setQuantity(newQuantity);
  //     setErrorMessage("");
  //   }
  // };
  const handleQuantityChange = (event, index) => {
    const newQuantity = parseInt(event.target.value, 10);
    const updatedQuantities = [...materialQuantities];
    const newErrorMessages = [...errorMessage];

    if (isNaN(newQuantity) || newQuantity <= 0) {
      // If the entered value is not a number or less than or equal to zero, show an error message
      updatedQuantities[index].quantity = ''; // Clear the quantity for the specific index
      setMaterialQuantities(updatedQuantities); // Update the state with the modified array
      newErrorMessages[index] = "Quantity must be greater than zero";
    } else {
      // If the entered value is valid, update the quantity for the specific index
      updatedQuantities[index].quantity = newQuantity;
      setMaterialQuantities(updatedQuantities); // Update the state with the modified array
      newErrorMessages[index] = ""; // Clear any previous error message
    }
    setErrorMessage(newErrorMessages);
};

  const handleDescription = (event) => {
    setOrderDescripton(event.target.value);
    formik.setFieldValue("orderDescription",event.target.value)
  };
  const handleProductDescription = (event) => {
    setProductDescripton(event.target.value);
  };
  const handleDateChange = (date) => {
    setStartDate(date);

    formik.setFieldValue("dueDate", date);
  };
  
  const handleAddOrder = () => {
    const isValid = materialQuantities.every(item => item.selectedMaterial !== '' && item.quantity !== '');

    if(selectedProduct && productDescription !== ""&&isValid ){

    
    const newOrder = {
      materials: materialQuantities,
      product: selectedProduct,
      description: productDescription,
    };
    setOrders([...orders, newOrder]);
  
    setOrderRows(orders);
    formik.setFieldValue(
            "products",
            [...orders, newOrder].map((order) => ({
              productId: order.product.id,
              productDescription:order.description,
              materials:order.materials.map((material)=>({
                suppliesId:material.selectedMaterial.id,
                suppliesQuantity:material.quantity
              }))
             
            }))
          );
    
    setMaterialQuantities([{ selectedMaterial: '', quantity: '' }]);
    setProductDescripton('');
    setSelectedProduct(null);}
    else{
      setMaterialSnackbar(true);
      setTimeout(() => {
        setMaterialSnackbar(false);
      }, 2000);
    }
  };
  // const handleAddButtonClick = () => {
   
  //   if (selectedProduct && selectedMaterial && quantity !== "") {
  //     const newOrder = {
  //       category: selectedProduct,
  //       description: orderDescription,
  //       materials: materialQuantities.map((materialQuantity) => ({
  //         material: materialQuantity.material,
  //         quantity: materialQuantity.quantity,
  //       })),
  //     };

  //     setOrders((prevOrders) => [...prevOrders, newOrder]);
  //     formik.setFieldValue(
  //       "products",
  //       [...orders, newOrder].map((order) => ({
  //         productId: order.productId,
  //         suppliesId: order.suppliesId,
  //         suppliesQuantity: order.suppliesQuantity,
  //       }))
  //     );

  //     // Clear the selected items and quantity after adding the order
  //     setSelectedProduct(null);
  //     setSelectedMaterial(null);
  //     setQuantity("");
  //   } else {
  //     setMaterialSnackbar(true);
  //     setTimeout(() => {
  //       setMaterialSnackbar(false);
  //     }, 2000);
  //   }
  // };
  const handleAddNewMaterial = () => {  
    setMaterialQuantities([...materialQuantities, { selectedMaterial: '', quantity: '' }]);

  };
  const handleDeleteMaterial = (index) => {
    const updatedMaterialQuantities = [...materialQuantities];
    updatedMaterialQuantities.splice(index, 1);
    setMaterialQuantities(updatedMaterialQuantities);
  };
  const handleDeleteClick = (index) => {
    const updatedOrders = [...orders];
    updatedOrders.splice(index, 1);
    setOrders(updatedOrders);
   
    const productIdToDelete = formik.values.products[index].productId;

    const updatedProducts = formik.values.products.filter(
      (product) => product.productId !== productIdToDelete
    );
    formik.setFieldValue("products", updatedProducts);

  };

  const handleEditClick = (index) => {

    // const selectedOrder = orders.find((product) => product.id === index);
    const selectedOrder = orders[index];
const description= selectedOrder.description
const material=selectedOrder.materials
  
    setSelectedProduct(selectedOrder.product)

    // setSelectedProduct({
    //   label: productOptions.find(
    //     (product) => product.value === selectedOrder.product.id
    //   )?.label,
    //   value: selectedOrder.productId||selectedOrder.product.id,
    //   name:productOptions.find(
    //     (product) => product.value === selectedOrder.product.id
    //   )?.label,
    // });
    setProductDescripton(
      description
    )
    setMaterialQuantities([]);

// Assuming material is an array containing material items
material.forEach((materialItem, index) => {
  setMaterialQuantities((prevMaterialQuantities) => [
    ...prevMaterialQuantities,
    {
      selectedMaterial: materialItem.selectedMaterial,
      quantity: materialItem.quantity
    }
  ]);
});

    setSelectedMaterial({
      label: materialOptions.find(
        (material) => material.value === selectedOrder.suppliesId
      )?.label,
      value: selectedOrder.suppliesId,
    });

    setQuantity(selectedOrder.suppliesQuantity);
    setEditedOrder(selectedOrder);

    const updatedOrders = [...orders];
    updatedOrders.splice(index, 1);
    setOrders(updatedOrders);
  };
  
  

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      borderColor: state.isFocused ? "#4005A0" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(64, 5, 160, 0.2)" : "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4005A0" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  const initialValues = order
    ? {
        clientID: order.client.id,
        dueDate: order.dueDate,
        orderDescription: order.orderDescription,
        products:  order?.products?.map((order) => ({
          productId: order?.productId?.id,
          productDescription:order?.productDescription,
          materials:order?.materials.map((material)=>({
            suppliesId:material?.suppliesId?.id,
            suppliesQuantity:material?.suppliesQuantity
          }))
         
        }))
        // products: orderProducts.map((product) => ({
        //   productID: product.productID,
        //   suppliesID: product.suppliesID,
        //   suppliesQuantity: product.suppliesQuantity,
        // })),
      }
    : {
        clientID: "",
        dueDate: "",
        orderDescription: "",
        products: [{ productID: "", productDescription: "", materials: [{suppliesId:"",suppliesQuantity:""}] }],
      };

  const validationSchema = Yup.object().shape({
    clientID: Yup.string().required("Client is required"),
    dueDate: Yup.string().required("DueDate is required"),
    orderDescription: Yup.string().required("Order description is required"),
    products: Yup.array().of(
      Yup.object().shape({
        //  product:Yup.string().required("product is required"),
        //  supplies:Yup.string().required("category is required"),
        quantity: Yup.string().matches(
          /^\+?[1-9]/,
          "only numbers is allowed and quantity must be greaterthan zero"
        ),
      })
    ),
  });
  const formik = useFormik({
  
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setShowLoader(true)
 
      if (orders.length === 0) {
        setProductSnackbar(true);
        setTimeout(() => {
          setProductSnackbar(false);
        }, 2000);
      } else if (props.text === "Add Product") {
        props.createOrder(values);
        setShowSnackbar(true);
      
      } else {
        props.editOrder({
          orderId: order.id, // Provide the orderId here
          clientID: values.clientID,
          dueDate: values.dueDate,
          orderDescription:values.orderDescription,
          products: values.products,
        });
        setShowSnackbar(true);
        
      }
    },
  });
  const orderIds = orders.map((order, index) => index.toString()); 
 
  const newOrders = order?.products?.map((orderItem) => {
    const product=productOptions?.find((option)=>option?.id===orderItem?.productId.id)
    const materials = orderItem?.materials?.map((materialItem) => {
      const selectedMaterial = materialOptions?.find((option) => option?.value === materialItem?.suppliesId.id);
      return {
        selectedMaterial:{ id: selectedMaterial?.id,
          label: selectedMaterial?.label,
          name:selectedMaterial?.label,},
       
        quantity: materialItem.suppliesQuantity
      };
    });
  
    // Extract product ID, description, and mapped materials with quantities
    return {
      product: product,
      description: orderItem?.productDescription,
      materials: materials
    };
  });

 
  useEffect(() => {
    
    if(order){
      setSelectedClient(
        clientOptions.find((client) => client.id === formik.values.clientID)
      );
      const dueDate = new Date(parseInt(order.dueDate));
      setStartDate(dueDate);
      const desc= order.orderDescription ;
      setOrderDescripton(desc)
      setOrders(newOrders);
      
    }
      
  }, [order]);
 

  return (
    <div className="container OrderForm">
      <div className="row">
        <div className="col-1 backbutton">
          <BackButton {...props} />
        </div>
        <div className="col-12 header">
          <span
            style={{
              color: "#4005A0",
              fontSize: "26px",
              fontFamily: "Inter",
              fontWeight: "600",
            }}
          >
           {props.title}
          </span>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} className="orderbox">
        <div className="row form-with-border" style={{ marginTop: "15px" }}>
          <div className="col-md-4">
            <label htmlFor="Client" className="form-labels">
              Client
            </label>
            <Select
              Select
              name="Client"
              options={clientOptions}
              value={
                selectedClient ||
                clientOptions.find(
                  (option) => option.id === formik.values.clientID
                )
              }
              onChange={handleClientChange}
              styles={customStyles}
            />
            {formik.touched.clientID && formik.errors.clientID && (
              <div className="sup-error">{formik.errors.clientID}</div>
            )}
          </div>

          <div
            className="col-md-4"
            style={{ display: "grid", marginTop: "5px" }}
          >
            <label htmlFor="Client" className="form-labels">
              Due Date
            </label>
            <DatePicker
              className="custom-datepicker"
              selected={startDate}
              onChange={handleDateChange}
              placeholderText={"Please select a date"}
              minDate={new Date()}
            />
            {formik.touched.dueDate && formik.errors.dueDate && (
              <div className="sup-error">{formik.errors.dueDate}</div>
            )}
          </div>
          <div
            className="col-md-6"
            style={{ display: "grid", marginTop: "3%" }}
          >
            <label htmlFor="Client" className="form-labels">
              Order Description
            </label>
            <textarea
              id={orderDescription}
              name="orderDescription"
              placeholder="Enter your description here"
              required
              className="desc"
              value={formik.values.orderDescription}
              onChange={handleDescription}
            />
            {formik.touched.orderDescription &&
              formik.errors.orderDescription && (
                <div className="sup-error">
                  {formik.errors.orderDescription}
                </div>
              )}
          </div>

          <div className="col-12 form-table">
            <OrderTable
              ids={orderIds}
              headings={orderHeadings}
              rows={orders}
              onEditClick={(index) => handleEditClick(index)}
              onDeleteClick={handleDeleteClick}
              showEditIcon={true}
              showStatus={true}
              showInfoButton={true}
            />
            {/* <DynamicTable
              ids={orderIds}
              headings={orderHeadings}
              rows={orderRows}
              onEditClick={(index) => handleEditClick(index)}
              onDeleteClick={handleDeleteClick}
              showEditIcon={true}
              showStatus={true}
              showInfoButton={true}
            /> */}
          </div>
        </div>
        <div className="row product-row">
          <div className="col-md-4 product-labels">
            <label htmlFor="Client" className="form-labels">
              Product category{" "}
            </label>
            <Select
              options={productOptions}
              value={selectedProduct}
              onChange={handleProductChange}
              styles={customStyles}
            />
          </div>
          <div className="col-md-6" style={{ display: "grid" }}>
            <label htmlFor="Client" className="form-labels">
              Product Description
            </label>
            <textarea
              id={productDescription}
              name="orderDescription"
              placeholder="Enter your description here"
              
              className="pro-desc"
              value={productDescription}
              onChange={handleProductDescription}
            />
            {formik.touched.orderDescription &&
              formik.errors.orderDescription && (
                <div className="sup-error">
                  {formik.errors.orderDescription}
                </div>
              )}
          </div>
        </div>
        {materialQuantities.map((materialQuantity, index) => (
          <div className="row material-row">
            <>
              <div className="col-md-4 material-label">
                <label htmlFor="Client" className="form-labels">
                  Material
                </label>
                <Select
                  options={materialOptions}
                  value={materialQuantity.selectedMaterial}
                  onChange={(selectedOption) => handleMaterialChange(selectedOption, index)}
                  styles={customStyles}
                />
              </div>
              <div className="col-md-1 quantity">
                <label htmlFor="Client" className="form-labels">
                  Quantity
                </label>
                <input
                  type="number"
                  name="quantity"
                  value={materialQuantity.quantity}
                onChange={(event) => handleQuantityChange(event, index)}
                  className="field-quantity"
                />
                 {errorMessage[index] && (
              <div className="sup-error">{errorMessage[index]}</div>
            )}

                {formik.touched.products &&
                  formik.errors.products &&
                  formik.errors.products.map((productError, index) => (
                    <div key={index} className="sup-error">
                      {productError.quantity}
                    </div>
                  ))}
              </div>
               
              {index > 0 && (
                <div className="col-md-1 delete-btn">
                  <img
                    src={deleteIcon}
                    onClick={() => handleDeleteMaterial(index)}
                  />
                </div>
              )}
            </>
          </div>
          
        ))}
 <div className="col-md-3 create-btn">
           <button className="create-new" type="button" onClick={handleAddNewMaterial}>
             Add new
           </button>
         </div>
        <div className="row justify-content-end addproduct">
          <div className="col-md-3 mb-1 text-end">
            <button
              type="button"
              className="addformbutton"
              onClick={handleAddOrder}
            >
              {props.text}
            </button>
            {materialSnackbar && (
              <Snackbar
                message={
                  "please select at least one product with material & quantity"
                }
                type="errors"
              />
            )}
          </div>
        </div>
        <div className="row justify-content-end add">
          <div className="col-md-3 mb-1 text-end">
            <button type="submit" className="savebutton">
              Save Order
            </button>
            {productSnackbar && (
              <Snackbar
                message={"please select atleast one product to continue"}
                type="errors"
              />
            )}
          </div>
        </div>

      </form>
      {showSnackbar && props.createOrderSucess === true && (
        <Snackbar message="Order Created Successfully" type="success" />
      )}
      {showSnackbar && props.createOrderFailure === true && (
        <Snackbar message={props.errors} type="errors" />
      )}
      {showSnackbar && props.editOrderSucess === true && (
        <Snackbar message="Order Edited Successfully" type="success" />
      )}
      {showSnackbar && props.editOrderFailure === true && (
        <Snackbar message={props.errors} type="errors" />
      )}
      {showLoader && props.loading === true && <Loader />}
    </div>
  );
};
export default OrderForm;
