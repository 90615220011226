import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Snackbar from "../Snackbar/Alert";
import Loader from "../Buttons/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./change.css"
const Changepassword =(props)=>{
    const [showPassword, setShowPassword] = useState(false);
    const [shownewPassword, setShownewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [successSnackbar ,setsuccessSnackbar]= useState(false)
    const [errorSnackbar ,seterrorSnackbar]= useState(false);
    const [showLoader,setShowLoader]=useState(false)


    const [eyeClicked, setEyeClicked] = useState(false);
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
        setEyeClicked(true);
      };
    
      const handleTogglenewPassword = () => {
        setShownewPassword((prevShowPassword) => !prevShowPassword);
        setEyeClicked(true);
      };
      const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
        setEyeClicked(true);
      };
    
      const initialValue={
        currentPassword:"",
        newPassword:"",
        confirmPassword:""
      }
      const schema = Yup.object().shape({
        
          currentPassword: Yup.string()
          .required("current password is required"),
        newPassword: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])/,
    
            "For security, use at least one uppercase letter, one lowercase letter, one number, and one special character in your password"
          )
          .min(8, "password must be atleast 8 characters")
          .required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])/,
            "Please enter a valid password"
          )
          .min(8, "password must be atleast 8 characters")
          .required("Confirm Password is required"),
       
          
      });
      const formik = useFormik({
        initialValues: initialValue,
        validationSchema: schema,
        onSubmit: (values) => {
          setShowLoader(true)
         props.changePassword({oldPassword:values.currentPassword,newPassword:values.confirmPassword})
         setsuccessSnackbar(true);
         seterrorSnackbar(true)
      }});
      useEffect(()=>{
        if(props.passwordsuccess === true){
          setShowLoader(false)
          formik.resetForm(); 
        }
        else if(props.passwordfailure === true){
          setShowLoader(false)
        }

      },[props.passwordsuccess ,props.passwordfailure])
    return(
<div className="container change-con">
<form onSubmit={formik.handleSubmit}>

    <div className="row pass-row">
        <div className="col-md-12 col-lg-12 col-xl-12 pass-head">
            <span className="pass-text">
                Change Password
            </span>

        </div>
        <div className="col-md-12 col-lg-12 col-xl-12 current-pass">
            <div>
            <label  className="pass-label">
            Enter current password*
            </label>
            <div className="password-field">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="currentPassword"
                            name="currentPassword"
                            placeholder="Enter password"
                            required
                            className="input-pass"
                            value={formik.values["currentPassword"]}
                            onChange={formik.handleChange}
                          />
                          <div
                            className="password-toggle"
                            onClick={handleTogglePassword}
                          >
                            {eyeClicked ? (
                              showPassword ? (
                                <FaEye className="show-button" />
                              ) : (
                                <FaEyeSlash className="show-button" />
                              )
                            ) : (
                              <FaEyeSlash className="show-button" />
                            )}
                          </div>
                        </div>
            </div>
            {formik.touched["currentPassword"] &&
                      formik.errors["currentPassword"] && (
                        <div
                          className="error-message"
                          style={{
                            color: "red",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          }}
                        >
                          {formik.errors["currentPassword"]}
                        </div>
                      )}
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 new-pass">
        <div>
            <label  className="pass-label">
            Enter new password*
            </label>
            <div className="password-field">
                          <input
                            type={shownewPassword ? "text" : "password"}
                            id="newPassword"
                            name="newPassword"
                            placeholder="Enter password"
                            required
                            className="input-pass"
                            value={formik.values["newPassword"]}
                            onChange={formik.handleChange}
                          />
                          <div
                            className="password-toggle"
                            onClick={handleTogglenewPassword}
                          >
                            {eyeClicked ? (
                              shownewPassword ? (
                                <FaEye className="show-button" />
                              ) : (
                                <FaEyeSlash className="show-button" />
                              )
                            ) : (
                              <FaEyeSlash className="show-button" />
                            )}
                          </div>
                        </div>
                        {formik.touched["newPassword"] &&
                      formik.errors["newPassword"] && (
                        <div
                          className="error-message"
                          style={{
                            color: "red",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          }}
                        >
                          {formik.errors["newPassword"]}
                        </div>
                      )}
            </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 new-pass">
        <div>
            <label  className="pass-label">
             Re enter new password*
            </label>
            <div className="password-field">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Re enter password"
                            required
                            className="input-pass"
                            value={formik.values["confirmPassword"]}
                            onChange={formik.handleChange}
                          />
                          <div
                            className="password-toggle"
                            onClick={handleToggleConfirmPassword}
                          >
                            {eyeClicked ? (
                              showConfirmPassword ? (
                                <FaEye className="show-button" />
                              ) : (
                                <FaEyeSlash className="show-button" />
                              )
                            ) : (
                              <FaEyeSlash className="show-button" />
                            )}
                          </div>
                        </div>
                        {formik.touched["confirmPassword"] &&
                      formik.errors["confirmPassword"] && (
                        <div
                          className="error-message"
                          style={{
                            color: "red",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          }}
                        >
                          {formik.errors["confirmPassword"]}
                        </div>
                      )}
            </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12 pass-head">
         <button className="change-btn" type="submit">
            Change Password
         </button>

        </div>
    </div>
    </form>
    {successSnackbar && props.passwordsuccess === true && (
        <Snackbar message="Password changed succesfully" type="success" />
      )}
      {errorSnackbar && props.passwordfailure === true && (
        <Snackbar message={props.passsworderror} type="errors" />
      )}
      {showLoader && (<Loader/>)}
</div>
    )
}
export default Changepassword