/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Supplies = ({ color = "white", fill = "white", className }) => {
  return (
    <svg
      className={`solar-delivery-bold ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.56406 8.72966L10.0791 10.5927C10.5641 12.3477 10.8061 13.2257 11.5191 13.6247C12.2321 14.0247 13.1371 13.7887 14.9471 13.3187L16.8671 12.8187C18.6771 12.3487 19.5821 12.1137 19.9941 11.4227C20.4061 10.7307 20.1641 9.85266 19.6781 8.09766L19.1641 6.23566C18.6791 4.47966 18.4361 3.60166 17.7241 3.20266C17.0101 2.80266 16.1051 3.03866 14.2951 3.50966L12.3751 4.00766C10.5651 4.47766 9.66006 4.71366 9.24906 5.40566C8.83706 6.09666 9.07906 6.97466 9.56406 8.72966Z"
        fill={color}
      />
      <path
        className="path"
        d="M2.27707 5.24666C2.30341 5.15169 2.34822 5.06283 2.40893 4.98519C2.46964 4.90754 2.54506 4.84262 2.63087 4.79414C2.71669 4.74566 2.81121 4.71457 2.90905 4.70265C3.00689 4.69073 3.10612 4.69821 3.20107 4.72466L4.90407 5.19666C5.35541 5.31924 5.76729 5.55666 6.09957 5.88579C6.43185 6.21491 6.67319 6.62451 6.80007 7.07466L8.95107 14.8607L9.10907 15.4067C9.74207 15.6437 10.2861 16.0867 10.6311 16.6747L10.9411 16.5787L19.8111 14.2737C19.9064 14.2488 20.0057 14.243 20.1033 14.2566C20.2009 14.2702 20.2948 14.3028 20.3798 14.3527C20.4647 14.4025 20.5391 14.4686 20.5985 14.5472C20.6579 14.6258 20.7012 14.7153 20.7261 14.8107C20.7509 14.906 20.7567 15.0053 20.7431 15.1029C20.7296 15.2005 20.6969 15.2944 20.6471 15.3794C20.5972 15.4643 20.5311 15.5387 20.4525 15.5981C20.3739 15.6575 20.2844 15.7008 20.1891 15.7257L11.3511 18.0217L11.0231 18.1237C11.0161 19.3937 10.1391 20.5547 8.81307 20.8997C7.22207 21.3137 5.58707 20.3977 5.16107 18.8557C4.73507 17.3127 5.67907 15.7267 7.26907 15.3137C7.34741 15.2935 7.42646 15.2761 7.50607 15.2617L5.35407 7.47366C5.29614 7.27357 5.18741 7.09189 5.03846 6.94627C4.88951 6.80065 4.70541 6.69605 4.50407 6.64266L2.80007 6.16966C2.70511 6.14341 2.61625 6.09872 2.53858 6.03812C2.4609 5.97753 2.39592 5.90222 2.34735 5.81651C2.29878 5.7308 2.26758 5.63635 2.25552 5.53858C2.24346 5.4408 2.25078 5.34161 2.27707 5.24666Z"
        fill={fill}
      />
    </svg>
  );
};

Supplies.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
};
