import HomePageIcon from "../../icons/Homepage.png";
import svg1 from "../../icons/svg1.png";
import svg2 from "../../icons/svg2.png";
import svg3 from "../../icons/svg3.png";
import svg4 from "../../icons/svg4.png";
import dot1 from "../../icons/dot1.svg";
import dot2 from "../../icons/dot2.svg";
import dot3 from "../../icons/dot3.svg";
import dot4 from "../../icons/dot4.svg";
import Footer from "./Footer"

import "./screen2.css";

const Screen2 = (props) => {
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" 
    });
    if (!("scrollBehavior" in document.documentElement.style)) {
  }
  };
  return (
    <>
      <div className="container-fluid" style={{backgroundColor:"#5A4A99"}}>
        <div className="row screen2">
          <div className="col-md-12">
            <div className="screen2-header">
              <div>
                <span className="craft">Craft</span>
                <span className="create">,Create</span>
                <span className="manage">, Manage</span>
              </div>
              <div className="unleash"> Unleash Your Artistic Potential</div>
             
            </div>
            <div className="screen-icon">
            <div  className=" home-Icon">

              <img src={HomePageIcon} alt="home" style={{width:'100%',height:'100%'}} />
              </div>
              <div className="svg1">
                <img src={svg1} alt="svg1"  style={{width:'100%',height:'100%'}} />
              </div>
              <div className="dot1">
                <img src={dot1} alt="dot1"  style={{width:'100%',height:'100%'}} />
              </div>

              <div className="svg2">
                <img src={svg2} alt="svg2"  style={{width:'100%',height:'100%'}} />
              </div>
              <div  className="dot2" >
                <img src={dot2} alt="dot2"  style={{width:'100%',height:'100%'}}/>
              </div>

              <div className="svg3">
                <img src={svg3} alt="svg3"  style={{width:'100%',height:'100%'}} />
              </div>
              <div  className="dot3">
                <img src={dot3} alt="dot3"  style={{width:'100%',height:'100%'}} />
              </div>

              <div className="svg4">
                <img src={svg4} alt="svg4"   style={{width:'100%',height:'100%'}}/>
              </div>
              <div className="dot4" >
                <img src={dot4} alt="dot4"  style={{width:'100%',height:'100%'}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row screen3">
          <div className="col-md-12">
            <div className="screen3-header">
              <div className="ready">Ready to get started?</div>
              <br />
              <button className="free-button" onClick={()=>{handleButtonClick()}}>
                <div className="free-text">Start your registration now</div>
              </button>
            </div>
          </div>
        </div>
        <div >
         <Footer {...props}/>
        </div>
      </div>
    </>
  );
};
export default Screen2;
