
import "./contact.css"


const Privacy=()=>{
    return(
        <div >
            <p className="about-us">
The reason why we collect data from our customers is to ensure you have the best possible experience while using our services – and that’s very important to us.<br/>

The Privacy Act 1988 (Cth) (Privacy Act) applies to the collection and use of your personal information.<br/>

This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including through our website.<br/>

In this Privacy Policy we, us or our means Jacques Alouan (Sole Trader) trading as MakerTech Solutions (ABN 28 376 897 465)<br/>

We recommend you check this policy often as we may amend this policy at any time by posting an updated version on our website.<br/><br/>

<h1 className="fo-head">What personal information we collect</h1>

We will only collect personal information about you if it is reasonably necessary – this information may include (but is not limited to):<br/>
<ul>
<li>your name;</li> 
 <li>images of you;</li>
<li>your contact details, including email address, mailing address, street address and telephone number;</li>
<li>your credit and billing information;</li>
<li> your demographic information, such as postcode, age, gender, date of birth, shopping preferences and category interests;</li>
<li>your preferences and/or opinions;</li>
<li>details of products and services we have provided to you and/or that you have enquired about, and our response to you;</li>
<li>your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour;</li>
<li>information about your access and use of our Site, including through the use of Internet cookies, your communications with our Site, the type of browser you are using, the type of operating system you are using, IP addresses and the domain name of your Internet service provider;</li>
 <li>additional personal information that you provide to us, directly or indirectly, through your use of our Site, associated applications, associated social media platforms and/or accounts from which you permit us to collect information; and</li>
<li>any other personal information requested by us and/or provided by you or a third party.</li>
</ul>

<h1 className="fo-head">How we collect, hold and use personal information</h1>
<ul>
We may collect, hold, use and disclose personal information for the following purposes:<br/>
<li>to enable you to access and use our Site, associated applications and associated social media platforms;</li>
<li>to contact and communicate with you;</li>
<li>for internal record keeping and administrative purposes;</li>
<li>for analytics, market research and business development, including to operate and improve our Site, associated applications and associated social media platforms;</li>
<li>for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you;
</li>
<li>to comply with our legal obligations and resolve any disputes that we may have.</li> 
</ul>
<h1 className="fo-head">How we disclose personal information</h1>

We may disclose personal information to:
<ul>
    <li>third party service providers who are responsible for parts of our service delivery, including (without limitation) IT service providers, data storage, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;</li>
<li>our employees, contractors and/or related entities;</li> 
<li> our existing or potential agents or business partners;</li>
<li>anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;</li>
<li> credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;</li>
<li>courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;</li>
<li>third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you. This may include parties located, or that store data, outside of Australia; and</li>
<li>third parties to collect and process data, such as Google Analytics or other relevant businesses. This may include parties that store data outside of Australia.</li>
</ul>
By providing us with personal information, you consent to the disclosure of your personal information to third parties who reside outside Australia and acknowledge that we are not required to ensure that those third parties comply with Australian privacy laws.<br/><br/>

<h1 className="fo-head">Accessing and correcting your information</h1>

By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of this Site or the products and/or services offered on or through it.<br/><br/>

<h1 className="fo-head">Information from third parties:</h1> If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.<br/><br/>

<h1 className="fo-head">Access:</h1> You may request details of the personal information that we hold about you. An administrative fee may be payable for the provision of such information. In certain circumstances, as set out in the Privacy Act 1988 (Cth), we may refuse to provide you with personal information that we hold about you.<br/><br/>

<h1 className="fo-head">Correction:</h1> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details in the contact section. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.<br/><br/>

<h1 className="fo-head">Complaints:</h1> If you believe that we have breached the Australian Privacy Principles and wish to make a complaint, please contact us using the details in the contact section and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint.<br/>

If you do not believe your complaint has been resolved by us, you may complain to the Office of the Australian Information Commissioner directly at enquiries@oaic.gov.au.For more information on the The Privacy Act 1988 (Cth) please visit www.oaic.gov.au<br/><br/>

<h1 className="fo-head">Unsubscribe:</h1> To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us using the details in the contact section.<br/><br/>

<h1 className="fo-head">Storage and Security</h1>

We take all reasonable steps to protect your personal information from misuse, loss, unauthorised access, modification, or disclosure. Your personal information is stored on password protected servers located in a secure location. We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that the personal information we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.<br/><br/>

<h1 className="fo-head">Cookies and Web Beacons</h1>

We may use cookies on our Site from time to time. Cookies are text files placed in your computer’s browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our Site with personal information, this information may be linked to the data stored in the cookie.

We may use web beacons on our Site from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.<br/><br/>

<h1 className="fo-head">Links to external websites</h1>

Our Site may contain links to other websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting those websites. Those websites are not governed by this Privacy Policy.<br/>

<h1 className="fo-head">For any questions, please contact us.</h1>
            </p>
        </div>
    )
}
export default Privacy