import Subtable from "./Subtable";
import Close from "../../icons/close.svg";
import DeletePopup from "../Buttons/DeletePopup";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import { useState, useEffect, useRef } from "react";
import Snackbar from "../Snackbar/Alert";
import "./sub.css";

const Subdetails = (props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [subRows, setSubRows] = useState([]);
  const [subIds, setSubIds] = useState([]);
  const [invoice, setInvoice] = useState(false);
  const [successSnackbar, setsuccessSnackbar] = useState(false);
  const [errorSnackbar, seterrorSnackbar] = useState(false);
  const [trailError, setTrailError] = useState(false);

  useEffect(() => {
    props.subscriptionDatas();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (invoice && !event.target.closest(".invoice-pop")) {
        setInvoice(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [invoice]);

  const subHeadings = [
    "Date",
    "Plan Details",
    "Valid From",
    "Valid to",
    "Invoice",
  ];
  const viewClick = () => {
    setInvoice(true);
  };

  const handleCancelSubscription = () => {
    setShowConfirmation(true);
  };
  useEffect(() => {
    if (trailError) {
      const timer = setTimeout(() => {
setTrailError(false)        

      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [trailError]);
  const handleSubscription = () => {
    if (props?.subscriptiondetails?.subscriptionID !== "") {
      props.cancelSubscription(props?.subscriptiondetails?.subscriptionID);
      setsuccessSnackbar(true);
      seterrorSnackbar(true);
    } else {
      setTrailError(true);
    }
  };
  const handleCancel = () => {
    setShowConfirmation(false);
  };
  const trialDate = (timestamp) => {
    // Convert Unix timestamp to milliseconds
    const milliseconds = timestamp * 1000;

    // Create a new Date object
    const dateObject = new Date(milliseconds);

    // Extract date components
    const year = dateObject.getFullYear();
    const monthIndex = dateObject.getMonth(); // Month is zero-indexed
    const day = dateObject.getDate();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthName = monthNames[monthIndex];

    // Format the date
    const formattedDate = `${monthName} ${day},${year} `;

    return formattedDate;
  };

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "N/A";
    }

    const date = new Date(parseInt(timestamp));

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthName = monthNames[monthIndex];

    return `${monthName} ${day},${year}`;
  };
  const startDate = props?.subscriptiondetails?.startDate
    ? trialDate(props.subscriptiondetails.startDate / 1000)
    : null;

  const endDate = props?.subscriptiondetails?.endDate
    ? trialDate(props?.subscriptiondetails?.endDate / 1000 || "")
    : null;
  const trialStart = props?.subscriptiondetails?.trial_start
    ? trialDate(props?.subscriptiondetails?.trial_start || "")
    : null;
  const trialEnd = props?.subscriptiondetails?.trial_end
    ? trialDate(props?.subscriptiondetails?.trial_end || "")
    : null;
  const invoiceCreated = props?.subscriptiondetails?.invoiceCreated
    ? trialDate(props?.subscriptiondetails?.invoiceCreated || "")
    : null;
  const timestamp = 1726656718;
  const date = new Date(timestamp * 1000);

  const plan = props?.subscriptiondetails?.name;
  const downloadClick = () => {
    // Set invoice state to true
    setInvoice(true);

    // Delay the PDF generation to ensure the invoice element is rendered in the DOM
    setTimeout(() => {
      // Get the invoice element
      const invoiceElement = document.getElementById("invoice");

      // Define options for html2pdf
      const options = {
        margin: 0.5,
        filename: "invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      // Use html2pdf to generate PDF from the invoice element
      html2pdf().from(invoiceElement).set(options).save();
    }, 100); // Adjust the delay time as needed
  };
  const printRef = useRef();

  // const handleDownloadPdf = (rootElementId) => {
  //   setInvoice(true)
  //   setTimeout(()=>{
  //   const input = document.getElementById("invoice");
  //   html2canvas(input)
  //     .then((canvas) => {
  //         const imgData = canvas.toDataURL('image/png');
  //         const pdf = new jsPDF();
  //         pdf.addImage(imgData, 'JPEG', -10, -10);
  //         pdf.save("download.pdf");
  //     })
  //   },100)
  // }
  useEffect(() => {
    if (props.cancelsubscriptionsuccess) {
      setsuccessSnackbar(true);
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 1000);
    }
  }, [props.cancelsubscriptionsuccess]);
  const handleDownloadPdf = (rootElementId) => {
    setInvoice(true);
    setTimeout(() => {
      const input = document.getElementById("invoice");
      const inputWidth = input.offsetWidth;
      const inputHeight = input.offsetHeight;
      html2canvas(input, { width: inputWidth, height: inputHeight }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF({
            unit: "px", // Set unit to 'px', 'in', 'mm', or 'pt'
            format: [inputWidth, inputHeight], // Set format to match element dimensions
          });
          pdf.addImage(imgData, "JPEG", 0, 0, inputWidth, inputHeight);
          pdf.save("download.pdf");
        }
      );
    }, 100);
  };

  useEffect(() => {
    const row = [
      startDate ? startDate : "",
      plan,
      startDate ? startDate : "",
      endDate ? endDate : "",
    ];
    setSubRows([row]);
    setSubIds([0, 1, 2, 3, 4, 5]);
  }, [props.subscriptiondetails]);

  return (
    <div className="container sub-con">
      <div className="row">
        <div className="col-12">
          <span className="head-details">Subscription</span>
        </div>
        <div className="col-12" style={{ marginTop: "2%" }}>
          <span className="subhead-details">Manage your plan & payments</span>
        </div>
      </div>
      <div className="row content-details">
        <div className="col-6 head-div">
          <span className="plan-head">Current Plan</span>
        </div>
        <div className="col-6 cancel-div">
          <button className="can-btn" onClick={handleCancelSubscription}>
            Cancel Subscription
          </button>
        </div>
        <div className="col-5 plan-box">
          <span className="box-heading">Selected Plan</span>
          <span className="box-subheading">
            {props?.subscriptiondetails?.name}
          </span>
        </div>
        <div className="col-5 plan-box1">
          <div className="box-1">
            <span className="box-heading">Start date </span>
            <span className="box-subheading">
              {startDate ? startDate : ""}{" "}
            </span>
          </div>
          <div className="box-2">
            <span className="box-heading">Due date </span>
            <span className="box-subheading">{endDate ? endDate : ""} </span>
          </div>
        </div>
      </div>
      <div className="row table-details">
        <div className="col-12">
          <span className="tab-head">View Payment History</span>
        </div>
        <div className="col-12 sub-table">
          <Subtable
            ids={subIds}
            headings={subHeadings}
            rows={subRows}
            handleViewClick={viewClick}
            handleDownloadClick={handleDownloadPdf}
            {...props}
          />
        </div>
      </div>
      {showConfirmation && (
        <div className="cancel-popup">
          <div className="close-div">
            <p className="cancel-head">
              Are you sure you need to cancel the subscription with Makers
              Portal?
            </p>
            <button className="close-butn" onClick={handleCancel}>
              &times;
            </button>
          </div>
          <span className="can-span">
            <h1 style={{ display: "inline-block" }} className="yes">
              Click “Yes”
            </h1>{" "}
            to continue. Once you cancel the subscription, you will be logged
            out and will not be able to use the features of this platform. You
            need to subscribe again in case you need to use our platform in the
            future.
            <br />
            <h1 style={{ display: "inline-block" }} className="yes">
              Click “No”
            </h1>{" "}
            to ignore the cancel request and continue using the platform.
          </span>
          <div className="buttn-div">
            <button onClick={handleSubscription} className="yes-button">
              <span style={{ color: "#484444" }}>Yes</span>
            </button>
            <button onClick={handleCancel} className="no-btn">
              <span style={{ color: "#FFFFFF" }}>No</span>
            </button>
          </div>
        </div>
      )}
      {invoice && (
        <div id="invc">
          <div className="overlay">
            <div className="container invoice-pop" id="invoice" ref={printRef}>
              <div className="invoice-head">
                <span className="mak">MAKERS</span>
                <span className="por"> PORTAL</span>
                <div className="row invoice-content">
                  <div className="col-md-4 col-lg-4 col-xl-4 bill-col">
                    <div className="bill">
                      <span className="bill-head">Billed to</span>
                      <span className="bill-data">
                        {props.subscriptiondetails?.makerId?.name || ""}
                      </span>
                      <span className="bill-data">
                        {props.subscriptiondetails?.makerId?.email || ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xl-4 bill-col ">
                    <div className="bill">
                      <span className="bill-head">Invoice number</span>
                      <span className="bill-data">
                        {props.subscriptiondetails?.invoiceNumber}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xl-4 bill-col">
                    <div className="bill">
                      <span className="bill-head">Invoice of AUD</span>
                      <span className="bill-data">
                        AUD {props.subscriptiondetails?.amount / 100}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xl-4 bill-col ">
                    <div className="bill">
                      <span className="bill-head">Subject</span>
                      <span className="bill-data">MakerPortalService</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xl-4 bill-col">
                    <div className="bill">
                      <span className="bill-head">Invoice date</span>
                      <span className="bill-data">{invoiceCreated}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xl-4 bill-col"></div>
                  <div className="col-md-12 col-lg-12 col-xl-12 item-col">
                    <div className="col-md-6 col-lg-6 col-xl-6 item-data">
                      <span className="bill-head">Item detail</span>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3 item-data">
                      <span className="bill-head">Quantity</span>
                    </div>{" "}
                    <div className="col-md-3 col-lg-3 col-xl-3 item-data">
                      <span className="bill-head">Amount</span>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-12 amount-col">
                    <div className="col-md-5 col-lg-5 col-xl-5 billdata-col">
                      <span className="bill-data1">
                        {" "}
                        {props?.subscriptiondetails?.name} <br />
                        {startDate ? startDate : ""} to {endDate ? endDate : ""}
                      </span>
                    </div>
                    <div className="col-md-1 col-lg-1 col-xl-1"></div>
                    <div className="col-md-3 col-lg-3 col-xl-3 quan-col">
                      <span className="bill-data2">
                        {props?.subscriptiondetails?.quantity}
                      </span>
                    </div>{" "}
                    <div className="col-md-3 col-lg-3 col-xl-3">
                      <span className="bill-data3">
                        AUD {props?.subscriptiondetails?.amount / 100}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-12 total-col">
                    <div className="col-md-5 col-lg-5 col-xl-5"></div>
                    <div className="col-md-1 col-lg-1 col-xl-1"></div>
                    <div className="col-md-3 col-lg-3 col-xl-3 subt">
                      <span className="bill-head">Subtotal</span>
                    </div>{" "}
                    <div className="col-md-3 col-lg-3 col-xl-3 ">
                      <span className="bill-data">
                        AUD {props.subscriptiondetails?.amount / 100}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-12 total-col ">
                    <div className="col-md-5 col-lg-5 col-xl-5"></div>
                    <div className="col-md-1 col-lg-1 col-xl-1"></div>
                    <div className="col-md-3 col-lg-3 col-xl-3 total-div">
                      <span className="bill-head">Amount due</span>
                    </div>{" "}
                    <div className="col-md-3 col-lg-3 col-xl-3 total-div">
                      <span className="bill-data">
                        AUD{" "}
                        {props.subscriptiondetails?.latestInvoiceAmountDue /
                          100}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-12 total-col ">
                    <div className="col-md-5 col-lg-5 col-xl-5"></div>
                    <div className="col-md-1 col-lg-1 col-xl-1"></div>
                    <div className="col-md-3 col-lg-3 col-xl-3 amou ">
                      <span className="bill-head">Total Amount</span>
                    </div>{" "}
                    <div className="col-md-3 col-lg-3 col-xl-3 ">
                      <span className="bill-data">
                        AUD{" "}
                        {props.subscriptiondetails?.latestInvoiceAmountDue /
                          100}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {successSnackbar && props.cancelsubscriptionsuccess === true && (
        <Snackbar
          message="Subscription Cancelled successfully"
          type="success"
        />
      )}
      {errorSnackbar && props.cancelsubscriptionfailure === true && (
        <Snackbar message={props.cancelsubscriptionmessage} type="errors" />
      )}
      {trailError && (
        <Snackbar message="Not able to cancel free trial" type="errors" />
      )}
    </div>
  );
};
export default Subdetails;
