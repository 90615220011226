import React from 'react';
import searchIcon from "../../icons/Search.svg";
import './Search.css'; 

const Search = (props) => {
 
  return (
   <div>  <input
   type="search"
   placeholder="Search"
   value={props.value}
   onChange={props.onChange}
 />
  
 
</div>

    
  );
};

export default Search;
