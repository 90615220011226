import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import DynamicTable from "../Tables/index";
import AddButton from "../Buttons/AddButton";
import Search from "../Search/Search";
import { useNavigate, useLocation } from "react-router-dom";
import next from "../../../src/icons/next.svg";
import prev from "../../../src/icons/prev.svg";
import { isEqual } from "lodash";
import Loader from "../Buttons/Loader";
import Snackbar from "../Snackbar/Alert";
import * as XLSX from "xlsx";
import "./Orders.css";


const OrdersPage = (props) => {
  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [orderRows, setOrderRows] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const[status,setStatus]= useState("")
  const itemsPerPage = 10;
  const totalCount = props?.orderData?.listAllOrders?.count || 0;
  const viewCount =props?.props?.clientOrderData?.count||0
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const location = useLocation();
  const { client } = location.state || {};

  useEffect(() => {
    props.listAllOrders({
      offset: (currentPage - 1) * itemsPerPage,
      first: itemsPerPage,
    });
  }, [currentPage,orderRows,totalCount]);
  useEffect(()=>{
    if(client){
    props.getClientOrder({
      offset:0,
      first:20,
     
    },client.id)}
  },[])

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "N/A";
    }
  
    const date = new Date(parseInt(timestamp));
  
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    // Array of month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const monthName = monthNames[monthIndex];
  
    return `${day}/${monthName}/${year}`;
  };
  
  useEffect(() => {
    const defaultOrderItems = props?.orderData?.listAllOrders?.items || [];
    const searchOrderItems = props?.clientOrderData?.items || [];
  


    // if (client) {

    // }

    const combinedItems =client ? searchOrderItems: defaultOrderItems 
//       if (client) {
//         const filterData = props?.excelData?.listAllOrders?.items || [];
//         const filteredItems = filterData.filter(
//           (item) => item.client.id === client.id
//         );
//         setOrderRows((prevRows) => {
//           const updatedRows = filteredItems.map((item) => {
//             return [
//               item?.client?.name || "",
//               item?.totalProductsCount || 0,
//               formatDate(item?.createdAt),
//               formatDate(item?.dueDate),
//               item?.orderStatus || "",
//             ];
//           });
//           if (!isEqual(prevRows, updatedRows)) {
//             return updatedRows;
//           }
    
//           return prevRows;
//         });
//         setOrderIds((prevIds) => {
//           if (
//             !isEqual(
//               prevIds,
//               filteredItems.map((item) => item.id)
//             )
//           ) {
//             return filteredItems.map((item) => item.id);
//           }
    
//           return prevIds;
//         });
//       }
// else{
    setOrderRows((prevRows) => {
      const updatedRows = combinedItems.map((item) => {
        return [
          item?.client?.name || "",
          item?.totalProductsCount || 0,
          formatDate(item?.createdAt),
          formatDate(item?.dueDate),

          item?.orderStatus || "",
        ];
      });

      if (!isEqual(prevRows, updatedRows)) {
        return updatedRows;
      }

      return prevRows;
    });
  
    if (currentPage > totalPages) {
      setCurrentPage(Math.max(1, totalPages));
    }

    if (totalPages === 0) {
      setCurrentPage(1);
    }

    setOrderIds((prevIds) => {
      if (
        !isEqual(
          prevIds,
          combinedItems.map((item) => item.id)
        )
      ) {
        return combinedItems.map((item) => item.id);
      }

      return prevIds;
    });
  }, [props?.orderData?.listAllOrders?.items || [], searchValue]);
  const handleClick = () => {
    navigate("/home/orders/addorders");
  };
  const handleEditClick = (id) => {
    const selectedOrder = props?.orderData?.listAllOrders?.items.find(
      (order) => order.id === id
    );
    if (selectedOrder) {
      navigate("/home/orders/editorders", { state: { order: selectedOrder } });
    }
  };
  function handleDeleteClick(id) {
    props.deleteOrder(id);
    setShowDeleteSnackbar(true);
    setShowLoader(true);
    if (orderIds.length === 1 && totalPages > 1) {
      // If current page becomes empty and there are more than one page,
      // move back to the previous page
      setCurrentPage(currentPage - 1);
    }
  }
  useEffect(() => {
    if (showDeleteSnackbar) {
      const timer = setTimeout(() => {
        setShowDeleteSnackbar(false);
      
      }, 3000); 

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showDeleteSnackbar]);

  const handleStatusClick = (id, status) => {
    // setStatus(states)
    props.updateOrder({ orderId: id, orderStatus: status });
  };
 
  const orderHeadings = [
    "Client",
    "Total Products",
    "Order Created",
    "Order Due Date",
    "Status",
  ];
  const excelHeadings=["Client",
  "order Description",
  "Total Products",
  "Order Created",
  "Order Due Date",
  "Status",
  "Products",
  "Product Description",
  "Quantity",
  "Materials"
 ]
  useEffect(() => {
    props.excelOrder({ offset: 0, first: totalCount });
  }, []);
  const handleDownloadClick = async () => {
    try {
        const excelRows = [];
        
        props?.excelData?.listAllOrders?.items.forEach((item) => {
            // Add client information and order details for the order
            excelRows.push([
                item?.client?.name || "", // Client Name
                item?.orderDescription || "", // Order Description
                item?.totalProductsCount || 0, // Total Products Count
                formatDate(item?.createdAt), // Created At
                formatDate(item?.dueDate), // Due Date
                item?.orderStatus || "", // Order Status
                "", // Leave space for the first product name
                "", // Leave space for the first product description
            ]);

            // Add each product and its description as separate rows
            item.products.forEach((product) => {
              const materials = product.materials.map(material => `${material?.suppliesId?.category?.name} - ${material?.suppliesId?.childCategory?.name}, ${material?.suppliesId?.parameters.map(param => `${param.name}: ${param.value}`).join(", ")}`).join("; ");
              const quantity=product.materials.map(material=>material.suppliesQuantity
                )
                excelRows.push([
                    "", // Empty space for client name (to maintain alignment)
                    "", // Empty space for order description
                    "", // Empty space for total products count
                    "", // Empty space for created at
                    "", // Empty space for due date
                    "", // Empty space for order status
                    product.productId.name, // Product Name
                    product.productDescription || "", // Product Description
                    quantity,
                    materials,
                ]);
            });
        });

        const sheet = XLSX.utils.aoa_to_sheet([excelHeadings, ...excelRows]);

        // Create the Excel workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheet, "Orders");

        // Trigger the download
        XLSX.writeFile(wb, "Orders.xlsx");
    } catch (error) {
    }
};
  return (
    <div className="orders">
      <div className="headers">
        <div className="orderheader">
          <div>Orders</div>
        </div>
      </div>
      <div className="search-add-container">
        <div className="search" style={{ flex: 1 }}>
          {/* <Search value={searchValue} onChange={""} /> */}
        </div>
        <div className="export-button">
    <button
    className="export"
        text="Download"
        type="button"
        onClick={handleDownloadClick}
        {...props}
      >Download</button>
    </div>
      
        <div className="add-button">
          <AddButton
            text="Add Orders"
            type="button"
            onclick={handleClick}
            {...props}
          />
        </div>
      </div>
      <div className="table">
        <DynamicTable
          ids={orderIds}
          headings={orderHeadings}
          rows={orderRows}
          showStatus={true}
          showEditIcon={true}
          {...props}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          onDoneClick={(id) => handleStatusClick(id, "Done")}
          onProgressClick={(id) => handleStatusClick(id, "In progress")}
          onOpenClick={(id) => handleStatusClick(id, "Active")}
          showExportIcon={true}
          showInfoButton={true}
        />
        { orderRows.length === 0 ? (
          <div className="no-data-found">No data found</div>
        ):client&&viewCount<=10?(""):totalCount<=10 ? ( "") : 
         (
          <div className="pagination-buttons">
            <div
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
              }
              disabled={currentPage === 1}
              className="pagination-button"
            >
              <img src={prev} alt="prev" className="pagebuttons" />
            </div>

            {Array.from({ length: totalPages }).map((_, index) => {
              const pageNumber = index + 1;

              if (totalPages <= 3 || Math.abs(currentPage - pageNumber) <= 1) {
                // Show page numbers 1, 2, 3 and ellipsis (...) if more pages
                return (
                  <div key={pageNumber} className="numbers">
                    <button
                      onClick={() => setCurrentPage(pageNumber)}
                      className={
                        currentPage === pageNumber ? "active" : "disabled"
                      }
                    >
                      {pageNumber}
                    </button>
                  </div>
                );
              } else if (
                (currentPage <= 3 && pageNumber === 4) ||
                (currentPage >= totalPages - 2 && pageNumber === totalPages - 3)
              ) {
                return (
                  <div key={pageNumber} className="ellipsis">
                    ...
                  </div>
                );
              }
              return null;
            })}

            <div
              onClick={() =>
                setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              <img src={next} alt="next" className="pagebuttons" />
            </div>
          </div>
        )}
      </div>
      {showDeleteSnackbar && props.deleteOrderSuccess === true && (
        <Snackbar message="Order Deleted Successfully" type="success" />
      )}
      {showDeleteSnackbar && props.deleteOrderFailure === true && (
        <Snackbar message="Failure" type="errors" />
      )}
      {showLoader && props.loading === true && <Loader />}
    </div>
  );
};

export default OrdersPage;
