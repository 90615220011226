import PropTypes from "prop-types";
import React from "react";

export const Home = ({ color = "#3C3C3C", className }) => {
  return (
    <svg
      className={`traditional-residence-home-having-shelter-roof-design-2-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.683 11.0405L12.4326 2.1737C12.3162 2.06215 12.1613 1.99988 12.0001 1.99988C11.8389 1.99988 11.6839 2.06215 11.5675 2.1737L2.30801 11.0497C2.16158 11.1952 2.06162 11.3809 2.02078 11.5833C1.97993 11.7856 2.00004 11.9956 2.07856 12.1865C2.15707 12.3774 2.29046 12.5408 2.46185 12.6559C2.63324 12.771 2.83493 12.8326 3.04137 12.833H4.49977V20.7499C4.49977 21.0814 4.63147 21.3993 4.8659 21.6338C5.10033 21.8682 5.41828 21.9999 5.74982 21.9999H9.29162C9.45739 21.9999 9.61637 21.934 9.73358 21.8168C9.8508 21.6996 9.91665 21.5406 9.91665 21.3749V15.9581C9.91665 15.9029 9.9386 15.8499 9.97767 15.8108C10.0167 15.7717 10.0697 15.7498 10.125 15.7498H13.8751C13.9893 15.7498 14.0835 15.8439 14.0835 15.9581V21.3749C14.0835 21.7199 14.3635 21.9999 14.7085 21.9999H18.2503C18.5819 21.9999 18.8998 21.8682 19.1342 21.6338C19.3687 21.3993 19.5004 21.0814 19.5004 20.7499V12.833H20.9588C21.1663 12.8327 21.3691 12.7704 21.5411 12.6541C21.713 12.5379 21.8464 12.373 21.9242 12.1805C22.0019 11.9881 22.0205 11.7768 21.9775 11.5737C21.9346 11.3706 21.832 11.185 21.683 11.0405Z"
        fill={color}
      />
    </svg>
  );
};

Home.propTypes = {
  color: PropTypes.string,
};
