import React from "react";
import OrderForm from "./OrderForm";

const AddOrder = (props) => {  
  return (
    <OrderForm
    text="Add Product"
    title=" Add Order"
    {...props}
  />
  );
};

export default AddOrder;
