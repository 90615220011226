import React from "react";
import PropTypes from "prop-types";


export const Clients = ({ color = "#3C3C3C", className }) => {
  return (
    <svg
      className={`traditional-residence-home-having-shelter-roof-design-2-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group of business team leading for sales and finance (2) 2" clipPath="url(#clip0_379_2454)">
        <path id="Vector" d="M4.00024 11.9998C5.10481 11.9998 6.00024 11.1043 6.00024 9.99976C6.00024 8.89519 5.10481 7.99976 4.00024 7.99976C2.89567 7.99976 2.00024 8.89519 2.00024 9.99976C2.00024 11.1043 2.89567 11.9998 4.00024 11.9998Z"  fill={color}/>
        <path id="Vector_2" d="M6.67024 13.3998C6.15124 13.7905 5.73019 14.2965 5.44036 14.8779C5.15053 15.4593 4.99986 16.1001 5.00024 16.7498V16.9998H0.750244C0.340244 16.9998 0.000244141 16.6598 0.000244141 16.2498V15.7498C0.000244141 14.2298 1.23024 12.9998 2.75024 12.9998H5.25024C5.77024 12.9998 6.26024 13.1498 6.67024 13.3998Z"  fill={color} />
        <path id="Vector_3" d="M20.0002 11.9998C21.1048 11.9998 22.0002 11.1043 22.0002 9.99976C22.0002 8.89519 21.1048 7.99976 20.0002 7.99976C18.8957 7.99976 18.0002 8.89519 18.0002 9.99976C18.0002 11.1043 18.8957 11.9998 20.0002 11.9998Z"   fill={color}/>
        <path id="Vector_4" d="M24.0003 15.7498V16.2498C24.0003 16.6598 23.6603 16.9998 23.2503 16.9998H19.0003V16.7498C19.0003 15.3798 18.3403 14.1598 17.3303 13.3998C17.7403 13.1498 18.2303 12.9998 18.7503 12.9998H21.2503C22.7703 12.9998 24.0003 14.2298 24.0003 15.7498Z"   fill={color}/>
        <path id="Vector_5" d="M12.0002 12.4998C13.6571 12.4998 15.0002 11.1566 15.0002 9.49976C15.0002 7.8429 13.6571 6.49976 12.0002 6.49976C10.3434 6.49976 9.00024 7.8429 9.00024 9.49976C9.00024 11.1566 10.3434 12.4998 12.0002 12.4998Z"   fill={color}/>
        <path id="Vector_6" d="M14.7502 13.9998H9.25024C8.52106 14.0003 7.8219 14.2902 7.30629 14.8058C6.79068 15.3214 6.50077 16.0206 6.50024 16.7498V18.2498C6.50024 18.6638 6.83624 18.9998 7.25024 18.9998H16.7502C16.9492 18.9998 17.1399 18.9207 17.2806 18.7801C17.4212 18.6394 17.5002 18.4487 17.5002 18.2498V16.7498C17.4997 16.0206 17.2098 15.3214 16.6942 14.8058C16.1786 14.2902 15.4794 14.0003 14.7502 13.9998Z"   fill={color}/>
      </g>
    </svg>
  );
};

Clients.propTypes = {
  color: PropTypes.string,
};