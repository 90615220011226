 import client from "../../Configurations/apollo";
 import {CHANGE_PASSWORD,PRODUCT_IDS,SET_MATERIALS_AND_PRODUCTS,GET_CLIENT_ORDER, UPDATE_ORDERS,DELETE_ORDERS,LIST_ALL_ORDERS, CREATE_ORDER,PRODUCTS_LISTING ,SEARCH_SUPPLIES,CATEGORY_DETAILS,DELETE_SUPPLIES,UPDATE_SUPPLIES,CREATE_SUPPLIES,LIST_ALL_SUPPLIES,SUPPLIER_DETAILS,SUPPLIES_DETAILS,CREATE_CLIENT,UPDATE_CLIENT,LIST_ALL_CLIENTS,DELETE_CLIENT,SEARCH_CLIENTS,CATEGORIES_LISTING, CREATE_SUPPLIER,UPDATE_SUPPLIERS ,LIST_ALL_SUPPLIERS, SEARCH_SUPPLIERS, DELETE_SUPPLIERS, EDIT_ORDER, CONTACT_US, CANCEL_SUBSCRIPTION, SUBSCRIPTION_DETAILS, CHANGE_PASSORD } from "./Gqlquery";
 
 export const listallClients = (pagination) => {
	return (dispatch) => {
	  dispatch({
		type: "FETCHING_ALL_CLIENTS_REQUEST",
	  });
  
	  client
		.query({
		  query: LIST_ALL_CLIENTS,
		  variables: {
			pagination,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'FETCHING_ALL_CLIENTS_SUCCESS',
			payload: response.data.listallClients,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'FETCHING_ALL_CLIENTS_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  
export const createClient = (params) =>  {
    let {Name,Address,Email,MobileNumber,countryCode,Description}=params
    return (dispatch) => {
		dispatch({
			type: "CREATING_CLIENT_REQEST",
		});
		client
			.mutate({
				mutation: CREATE_CLIENT,
				variables: {
                    name:Name,
                    address:Address,
                    email:Email,
					countryCode:countryCode,
                    mobile:MobileNumber,
					description:Description
					
				},
			})
			.then((response) => {
				if (response.data && response.data.createClient && response.data.createClient!== null) {
				  dispatch({
					type: 'CREATING_CLIENT_SUCCESS',
					payload: response.data.createClient,
				  });
				} else if (response.errors) {
				  dispatch({
					type: 'CREATING_CLIENT_FAILURE',
					payload: response.errors[0].message,
				  });
				}
			  })
          .catch((error) => {
			dispatch({ type: 'CREATING_CLIENT_FAILURE', payload: error.message });
		  });
  };
}

export const updateClient = (id,params) => {
	const { clientId,Name,Address,Email,MobileNumber,countryCode,Description } = params;
  
	return (dispatch) => {
	  dispatch({
		type: "UPDATING_CLIENT_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: UPDATE_CLIENT,
		  variables: {
			clientId:id,
			name:Name,
			address:Address,
			email:Email,
			countryCode:countryCode,
			mobile:MobileNumber,
			description:Description
		  },
		})
		.then((response) => {
			if (response.data && response.data.updateClient && response.data.updateClient !== null) {
			  dispatch({
				type: 'UPDATING_CLIENT_SUCCESS',
				payload: response.data.updateClient,
			  });
			} else if (response.errors) {
			  dispatch({
				type: 'UPDATING_CLIENT_FAILURE',
				payload: response.errors[0].message,
			  });
			}
		  })
		.catch((error) => {
		  dispatch({ type: 'UPDATING_CLIENT_FAILURE', payload: error.message });
		});
	};
  };
  export const deleteClient = (clientId) => {

	return (dispatch) => {
	  dispatch({
		type: "DELETING_CLIENT_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: DELETE_CLIENT,
		  variables: {
			clientId:clientId,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'DELETING_CLIENT_SUCCESS',
			payload: clientId,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'DELETING_CLIENT_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const searchClients = (params) => {
	let {pagination ,searchKey}= params
	return (dispatch) => {
		dispatch({
		  type: "SEARCHING_CLIENTS_REQUEST",
		});
		client
		.query({
		  query: SEARCH_CLIENTS,
		  variables: {
			pagination,
			searchKey:searchKey
		  },
		})
		.then((response) => {
			dispatch({
			  type: 'SEARCHING_CLIENTS_SUCCESS',
			  payload: response.data
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'SEARCHING_CLIENTS_FAILURE',
			  payload: error.message,
			});
		  });
  }
}
export const categoriesListing = () => {
	return (dispatch) => {
	  dispatch({
		type: "CATEGORIES_LISTING_REQUEST",
	  });
  
	  client
		.query({
		  query: CATEGORIES_LISTING,
		})
		.then((response) => {
		  dispatch({
			type: 'CATEGORIES_LISTING_SUCCESS',
			payload: response.data
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'CATEGORIES_LISTING_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const createSupplier = (params) => {
	let { Name, Address, Email, MobileNumber, countryCode, categories,Description } = params;
  
	return (dispatch) => {
	  dispatch({
		type: "CREATING_SUPPLIER_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: CREATE_SUPPLIER,
		  variables: {
			name: Name,
			address: Address,
			email: Email,
			countryCode: countryCode,
			mobile: MobileNumber,
			categories: categories,
			description:Description
		  },
		})
		.then((response) => {
		  if (response.data && response.data.createSupplier && response.data.createSupplier !== null) {
			dispatch({
			  type: 'CREATING_SUPPLIER_SUCCESS',
			  payload: response.data.createSupplier,
			});
		  } else if (response.errors) {
			dispatch({
			  type: 'CREATING_SUPPLIER_FAILURE',
			  payload: response.errors[0].message,
			});
		  }
		})
		.catch((error) => {
		  dispatch({
			type: 'CREATING_SUPPLIER_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const listallSuppliers = (pagination) => {
	return (dispatch) => {
	  dispatch({
		type: "FETCHING_ALL_SUPPLIERS_REQUEST",
	  });
  
	  client
		.query({
		  query: LIST_ALL_SUPPLIERS,
		  variables: {
			pagination,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'FETCHING_ALL_SUPPLIERS_SUCCESS',
			payload: response.data.listAllSuppliers,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'FETCHING_ALL_SUPPLIERS_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const searchSuppliers = (params) => {
	let {pagination ,searchKey}= params
	return (dispatch) => {
		dispatch({
		  type: "SEARCHING_SUPPLIERS_REQUEST",
		});
		client
		.query({
		  query: SEARCH_SUPPLIERS,
		  variables: {
			pagination,
			searchKey:searchKey
		  },
		})
		.then((response) => {
			dispatch({
			  type: 'SEARCHING_SUPPLIERS_SUCCESS',
			  payload: response.data
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'SEARCHING_SUPPLIERS_FAILURE',
			  payload: error.message,
			});
		  });
  }
}
export const deleteSupplier = (supplierId) => {

	return (dispatch) => {
	  dispatch({
		type: "DELETING_SUPPLIER_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: DELETE_SUPPLIERS,
		  variables: {
			supplierId:supplierId,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'DELETING_SUPPLIER_SUCCESS',
			payload: supplierId,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'DELETING_SUPPLIER_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const updateSupplier = (params,params1) => {
	let {  Name, Address, Email, MobileNumber, countryCode, categories,Description } = params;
  let{supplierId}=params1
	return (dispatch) => {
	  dispatch({
		type: "UPDATING_SUPPLIER_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: UPDATE_SUPPLIERS,
		  variables: {
			supplierId: supplierId,
			name: Name,
			address: Address,
			email: Email,
			countryCode: countryCode,
			mobile: MobileNumber,
			categories: categories,
			description:Description
		  },
		})
		.then((response) => {
		  if (response.data && response.data.updateSupplier && response.data.updateSupplier !== null) {
			dispatch({
			  type: 'UPDATING_SUPPLIER_SUCCESS',
			  payload: response.data.updateSupplier,
			});
		  } else if (response.errors) {
			dispatch({
			  type: 'UPDATING_SUPPLIER_FAILURE',
			  payload: response.errors[0].message,
			});
		  }
		})
		.catch((error) => {
		  dispatch({
			type: 'UPDATING_SUPPLIER_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const categoryDetails = (params) => {
	let {categoryId}=params
	return (dispatch) => {
	  dispatch({
		type: "CATEGORY_DETAILS_REQUEST",
	  });
  
	  client
		.query({
		  query: CATEGORY_DETAILS,
		  variables:{
			categoryId:categoryId
		  }
		})
		.then((response) => {
		  dispatch({
			type: 'CATEGORY_DETAILS_SUCCESS',
			payload: response.data
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'CATEGORY_DETAILS_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const suppliesDetails = (params) => {
	let {suppliesId}=params
	return (dispatch) => {
	  dispatch({
		type: "SUPPLIES_DETAILS_REQUEST",
	  });
  
	  client
		.query({
		  query: SUPPLIES_DETAILS,
		  variables:{
			suppliesId:suppliesId
		  }
		})
		.then((response) => {
		  dispatch({
			type: 'SUPPLIES_DETAILS_SUCCESS',
			payload: response.data
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'SUPPLIES_DETAILS_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const supplierDetails = (params) => {
	let {supplierId}=params
	return (dispatch) => {
	  dispatch({
		type: "SUPPLIER_DETAILS_REQUEST",
	  });
  
	  client
		.query({
		  query: SUPPLIER_DETAILS,
		  variables:{
			supplierId:supplierId
		  }
		})
		.then((response) => {
		  dispatch({
			type: 'SUPPLIER_DETAILS_SUCCESS',
			payload: response.data
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'SUPPLIER_DETAILS_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  

export const createSupplies = (params) => {
  const { supplier, category, childCategory, parameters, totalQuantity,description } = params;

  return (dispatch) => {
    dispatch({
      type: "CREATING_SUPPLIES_REQUEST",
    });

    client
      .mutate({
        mutation: CREATE_SUPPLIES,
        variables: {
          supplier: supplier,
          category: category,
          childCategory: childCategory,
          parameters: parameters,
          quantity: parseInt(totalQuantity),
		  description:description
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.createSupplies &&
          response.data.createSupplies !== null
        ) {
          dispatch({
            type: "CREATING_SUPPLIES_SUCCESS",
            payload: response.data.createSupplies,
          });
        } else if (response.errors) {
          dispatch({
            type: "CREATING_SUPPLIES_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "CREATING_SUPPLIES_FAILURE",
          payload: error.message,
        });
      });
  };
};
export const listAllSupplies = (pagination) => {
	return (dispatch) => {
	  dispatch({
		type: "FETCHING_ALL_SUPPLIES_REQUEST",
	  });
  
	  client
		.query({
		  query: LIST_ALL_SUPPLIES,
		  variables: {
			pagination,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'FETCHING_ALL_SUPPLIES_SUCCESS',
			payload: response.data,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'FETCHING_ALL_SUPPLIES_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const updateSupplies = (params,params1) => {
	const { supplier, category, childCategory, parameters, totalQuantity ,description} = params;
	let{suppliesId}=params1

	
	return (dispatch) => {
	  dispatch({
		type: "UPDATING_SUPPLIES_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: UPDATE_SUPPLIES,
		  variables: {
			suppliesId: suppliesId,
			supplier: supplier,
			category: category,
			childCategory: childCategory,
			parameters: parameters,
			quantity: parseInt(totalQuantity),
			description:description
		  },
		})
		.then((response) => {
		  if (response.data && response.data.updateSupplies && response.data.updateSupplies !== null) {
			dispatch({
			  type: 'UPDATING_SUPPLIES_SUCCESS',
			  payload: response.data.updateSupplies,
			});
		  } else if (response.errors) {
			dispatch({
			  type: 'UPDATING_SUPPLIES_FAILURE',
			  payload: response.errors[0].message,
			});
		  }
		})
		.catch((error) => {
		  dispatch({
			type: 'UPDATING_SUPPLIES_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const deleteSupplies = (suppliesId) => {

	return (dispatch) => {
	  dispatch({
		type: "DELETING_SUPPLIES_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: DELETE_SUPPLIES,
		  variables: {
			suppliesId:suppliesId,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'DELETING_SUPPLIES_SUCCESS',
			payload: suppliesId,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'DELETING_SUPPLIES_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const searchSupplies = (params) => {
	let {pagination ,searchKey}= params
	return (dispatch) => {
		dispatch({
		  type: "SEARCHING_SUPPLIES_REQUEST",
		});
		client
		.query({
		  query: SEARCH_SUPPLIES,
		  variables: {
			pagination,
			searchKey:searchKey
		  },
		})
		.then((response) => {
			dispatch({
			  type: 'SEARCHING_SUPPLIES_SUCCESS',
			  payload: response.data
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'SEARCHING_SUPPLIES_FAILURE',
			  payload: error.message,
			});
		  });
  }
}
 
export const productsListing = () => {
	return (dispatch) => {
	  dispatch({
		type: "PRODUCTS_LISTING_REQUEST",
	  });
  
	  client
		.query({
		  query: PRODUCTS_LISTING,
		})
		.then((response) => {
		  dispatch({
			type: 'PRODUCTS_LISTING_SUCCESS',
			payload: response.data
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'PRODUCTS_LISTING_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const createOrder = (params) => {
	const { clientID,dueDate,orderDescription,products } = params
  
	return (dispatch) => {
	  dispatch({
		type: "CREATING_ORDER_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: CREATE_ORDER,
		  variables: {
			client: clientID,
			dueDate:dueDate,
			orderDescription:orderDescription,
			products:products
			
		  },
		})
		.then((response) => {
		  if (
			response.data &&
			response.data.createOrder &&
			response.data.createOrder !== null
		  ) {
			dispatch({
			  type: "CREATING_ORDER_SUCCESS",
			  payload: response.data.createSupplies,
			});
		  } else if (response.errors) {
			dispatch({
			  type: "CREATING_ORDER_FAILURE",
			  payload: response.errors[0].message,
			});
		  }
		})
		.catch((error) => {
		  dispatch({
			type: "CREATING_ORDER_FAILURE",
			payload: error.message,
		  });
		});
	};
  };
  export const listAllOrders = (pagination) => {
	return (dispatch) => {
	  dispatch({
		type: "FETCHING_ALL_ORDERS_REQUEST",
	  });
  
	  client
		.query({
		  query: LIST_ALL_ORDERS,
		  variables: {
			pagination,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'FETCHING_ALL_ORDERS_SUCCESS',
			payload: response.data,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'FETCHING_ALL_ORDERS_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const deleteOrder = (orderId) => {

	return (dispatch) => {
	  dispatch({
		type: "DELETE_ORDER_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: DELETE_ORDERS,
		  variables: {
			orderId:orderId,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'DELETE_ORDER_SUCCESS',
			payload: orderId,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'DELETE_ORDER_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const updateOrder=(params)=>{
	return(dispatch)=>{
		dispatch({
			type:'UPDATE_ORDER_REQUEST'
		})
		client
		.mutate({
			mutation: UPDATE_ORDERS,
			variables: {
			  orderId:params.orderId,
			  orderStatus:params.orderStatus
			},
		  })
		  .then((response) => {
			if (response.data && response.data.updateOrderDetails && response.data.updateOrderDetails !== null) {
			  dispatch({
				type: 'UPDATE_ORDER_SUCCESS',
				payload: response.data.updateOrderDetails.id,
			  });
			} else if (response.errors) {
			  dispatch({
				type: 'UPDATE_ORDER_FAILURE',
				payload: response.errors[0].message,
			  });
			}
		  })
		  .catch((error) => {
			dispatch({
			  type: 'UPDATE_ORDER_FAILURE',
			  payload: error.message,
			});
		  });

	}
  }
  export const excelOrder=(pagination)=>{
	return(dispatch)=>{
		dispatch({
			type:'EXCEL_ORDER_REQUEST'
		})
		client
		.query({
		  query: LIST_ALL_ORDERS,
		  variables: {
			pagination,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'EXCEL_ORDERS_SUCCESS',
			payload: response.data,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'EXCEL_ORDERS_FAILURE',
			payload: error.message,
		  });
		});
	}
  }
  export const excelSupplies=(pagination)=>{
	return (dispatch) => {
		dispatch({
		  type: "EXCEL_SUPPLIES_REQUEST",
		});
	
		client
		  .query({
			query: LIST_ALL_SUPPLIES,
			variables: {
			  pagination,
			},
		  })
		  .then((response) => {
			dispatch({
			  type: 'EXCEL_SUPPLIES_SUCCESS',
			  payload: response.data,
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'EXCEL_SUPPLIES_FAILURE',
			  payload: error.message,
			});
		  });
	  };
  }
  export const excelSuppliers=(pagination)=>{
	return (dispatch) => {
		dispatch({
		  type: "EXCEL_SUPPLIERS_REQUEST",
		});
	
		client
		  .query({
			query: LIST_ALL_SUPPLIERS,
			variables: {
			  pagination,
			},
		  })
		  .then((response) => {
			dispatch({
			  type: 'EXCEL_SUPPLIERS_SUCCESS',
			  payload: response.data.listAllSuppliers,
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'EXCEL_SUPPLIERS_FAILURE',
			  payload: error.message,
			});
		  });
	  };
  }
  export const excelClients=(pagination)=>{
	return (dispatch) => {
		dispatch({
		  type: "EXCEL_CLIENTS_REQUEST",
		});
	
		client
		  .query({
			query: LIST_ALL_CLIENTS,
			variables: {
			  pagination,
			},
		  })
		  .then((response) => {
			dispatch({
			  type: 'EXCEL_CLIENTS_SUCCESS',
			  payload: response.data.listallClients,
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'EXCEL_CLIENTS_FAILURE',
			  payload: error.message,
			});
		  });
	  };
  }
export const getClientOrder=(pagination,clientId)=>{
	return (dispatch) =>{
		dispatch({
			type:"GET_CLIENT_ORDER_REQUEST"
		})
		client
		.query({
			query:GET_CLIENT_ORDER,
				variables: {
				pagination,
				clientId:clientId
			  },

			})
			.then((response) => {
				dispatch({
				  type: 'GET_CLIENT_ORDER_SUCCESS',
				  payload: response.data.getOrderOfClient
				  ,
				});
			  })
			  .catch((error) => {
				dispatch({
				  type: 'GET_CLIENT_ORDER_FAILURE',
				  payload: error.message,
				});
			  });
	}
	
}
export const editOrder=(params)=>{
	let {orderId,clientID,dueDate,orderDescription,products}=params
	return(dispatch)=>{
		dispatch({
			type:'EDIT_ORDER_REQUEST'
		})
		client
		.mutate({
			mutation: EDIT_ORDER,
			variables: {
			  orderId:orderId,
			  client:clientID,
			  dueDate:dueDate,
			  orderDescription:orderDescription,
			  products:products
			},
		  })
		  .then((response) => {
			if (response.data && response.data.editOrder && response.data.edit !== null) {
			  dispatch({
				type: 'EDIT_ORDER_SUCCESS',
				payload: response.data.editOrder,
			  });
			} else if (response.errors) {
			  dispatch({
				type: 'EDIT_ORDER_FAILURE',
				payload: response.errors[0].message,
			  });
			}
		  })
		  .catch((error) => {
			dispatch({
			  type: 'EDIT_ORDER_FAILURE',
			  payload: error.message,
			});
		  });

	}
}
export const setMaterialsAndProducts = (materials, products) => ({
	type: SET_MATERIALS_AND_PRODUCTS,
	payload: { materials, products },
  });

  export const contactUs = (params) => {
let{Name,Email,Address,message,MobileNumber}=params
	return (dispatch) => {
	  dispatch({
		type: "CONTACT_US_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: CONTACT_US,
		  variables: {
			fullName:Name,
			email:Email,
			location:Address,
			message:message,
			phoneNumber:MobileNumber
			
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'CONTACT_US_SUCCESS',
			payload: response.data.contactUsMail
			,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'CONTACT_US_FAILURE',
			payload: error.message,
		  });
		});
	};
  };

  export const productIds= () => {
	return (dispatch) => {
	  dispatch({
		type: "PRODUCTS_ID_REQUEST",
	  });
  
	  client
		.query({
		  query: PRODUCT_IDS,
		})
		.then((response) => {
		  dispatch({
			type: 'PRODUCTS_ID_SUCCESS',
			payload: response.data.getListOfAllActiveProductsFromStripe
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'PRODUCTS_ID_FAILURE',
			payload: error.message,
		  });
		});
	};
  };
  export const subscriptionDatas =()=>{
	return(dispatch)=>{
		dispatch({
			type: "SUBSCRIPTION_DETAILS_REQEST",
		  });
		  client
		  .query({
			query: SUBSCRIPTION_DETAILS,
		  })
		  .then((response) => {
			dispatch({
			  type: 'SUBSCRIPTION_DETAILS_SUCCESS',
			  payload: response.data.getSubscriptionDetails
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'SUBSCRIPTION_DETAILS_FAILURE',
			  payload: error.message,
			});
		  });
	  
	}
  }
  export const cancelSubscription = (subscriptionID) => {

	return (dispatch) => {
	  dispatch({
		type: "CANCEL_SUBSCRIPTION_REQUEST",
	  });
  
	  client
		.mutate({
		  mutation: CANCEL_SUBSCRIPTION,
		  variables: {
			subscriptionID:subscriptionID,
		  },
		})
		.then((response) => {
		  dispatch({
			type: 'CANCEL_SUBSCRIPTION_SUCCESS',
			payload: response.cancelSubscriptionInStripe,
		  });
		})
		.catch((error) => {
		  dispatch({
			type: 'CANCEL_SUBSCRIPTION_FAILURE',
			payload: error.message,
		  });
		});
	};
  };

  export const changePassword=(params)=>{
	let{oldPassword,newPassword}=params
	return (dispatch) => {
		dispatch({
		  type: "CHANGE_PASSWORD_REQUEST",
		});
	
		client
		  .mutate({
			mutation: CHANGE_PASSWORD,
			variables: {
				oldPassword:oldPassword,
				newPassword:newPassword
			},
		  })
		  .then((response) => {
			dispatch({
			  type: 'CHANGE_PASSWORD_SUCCESS',
			  payload: response.createPassword,
			});
		  })
		  .catch((error) => {
			dispatch({
			  type: 'CHANGE_PASSWORD_FAILURE',
			  payload: error.message,
			});
		  });
	  };
  }