import ClipLoader from "react-spinners/ClipLoader";
import "./loader.css";

const Loader = () => {
  return (
      <div className="row justify-content-center loader">
        <ClipLoader
          css={{ borderColor: "blue", borderTopColor: "transparent" }}
          color={"blue"}
          size={50}
        />
      </div>
  );
};
export default Loader;
