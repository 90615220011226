import details from "./helper";
import react, { useState, useEffect, useRef } from "react";
import close from "../../icons/close.svg";
import Contact from "./Contact";
import Privacy from "./Privacy";

import "./foooter.css";
import About from "./About";
import Terms from "./Terms";
import Refund from "./Refund";

const Footer = (props) => {
  const [showDisplay, setShowDisplay] = useState(false);
  const [heading, setHeading] = useState("");
  const [subheading, setSubHeading] = useState("");


  const [activeComponent, setActiveComponent] = useState(null);

  function openDetails(type) {
    setShowDisplay(true);
    switch (type) {
      case "about":
        setActiveComponent(<About />);
        break;
      case "contact":
        setActiveComponent(<Contact {...props} />);
        break;
      case "privacy":
        setActiveComponent(<Privacy />);
        break;
      case "terms":
        setActiveComponent(<Terms />);
        break;
      case "refund":
        setActiveComponent(<Refund />);
        break;
      default:
        setActiveComponent(null);
        break;
    }
  }
  useEffect(()=>{
    setTimeout(() => {
    if(props.contactussuccess){
      setShowDisplay(false)
    }
  }, 2000);
  },[props.contactussuccess])


  return (
    <div className="row footer-row">
          {showDisplay && (
        <div className="display ">
          <div className="display-heading">
            <span className="heading-font">{heading}</span>
            <span className="subheading-font">{subheading}</span>
            <button
            className="close-display"
            onClick={() => {
              setShowDisplay(false);
            }}
          >
            <img src={close} className="close-icon" />
          </button>
          </div>
         

          <div className="content">      
          {activeComponent}
          </div>
        </div>
      )}
      <div className="col-5 foot-header">
     
        <div>
          <span className="makers-text">MAKERS </span>
          <span className="portal-text">PORTAL</span>
        </div>
        <div className="footer-text">
          Unlock the full potential of your craft with Makers Portal,the
          <br /> ultimate platform designed exclusively for Jewellery and Craft
          <br /> makers.
        </div>
      </div>
      <div className="col-3 foot-center"/>
      <div className="col-2 footer1">
      <div
          onClick={() => {
            openDetails("about");
            setHeading("About");
            setSubHeading("us");
            
          }}
          className="links1"
        >
          About us
        </div>
        <div
          onClick={() => {
            openDetails("contact");
            setHeading("Contact");
            setSubHeading("us");

          }}
          className="links2"
        >
          Contact us
        </div>
      </div>
      <div className="col-2 foot-link">
       
        <div
          onClick={() => {
            openDetails("privacy");
            setHeading("Privacy");
            setSubHeading("Policy");

          }}
          className="links"
        >
          Privacy Policy
        </div>
        <div
          onClick={() => {
            openDetails("terms");
            setHeading("Terms ");
            setSubHeading("of Service");

            
          }}
          className="links"
        >
          Terms of Service
        </div>
        <div
          onClick={() => {
            openDetails("refund");
            setHeading("Refund");
            setSubHeading("Policy");

          }}
          className="links"
        >
          Refund Policy
        </div>
      </div>
      <div className="copy">
        <div className="foot-copy">
          © 2024 Makers_Portal. All rights reserved.
        </div>
      </div>
     
    </div>
  );
};
export default Footer;
