import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Loader from "../Buttons/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Screen2 from "./Screen2";
import * as Yup from "yup";
import Snackbar from "../Snackbar/Alert";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import PasswordPopup from "../Buttons/PasswordPopup";
import { Helmet } from 'react-helmet';

import Select from "react-select";
import "./land.css";


export const Screens = (props) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
const [popup,setPopup] =useState(false)
const [selectedOptions, setSelectedOption] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      borderColor: state.isFocused ? "#4005A0" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(64, 5, 160, 0.2)" : "none",
      color:"#494949",
      fontSize: "13px",
      fontFamily: "Inter",
      fontWeight: 500,
      borderRadius:"5px",
      height: "48px", // Allows the height to adjust based on content
      "@media (max-width: 600px)": {
        minHeight: "25px", // Adjusted height for mobile screens
        fontSize: "8px",
        height: "25px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4005A0" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     borderRadius: "10px",
  //     borderColor: state.isFocused ? "#4005A0" : "#ccc",
  //     boxShadow: state.isFocused ? "0 0 0 2px rgba(64, 5, 160, 0.2)" : "none",
  //     color:"#494949",
  //     fontSize: "13px",
  //     fontFamily: "Inter",
  //     fontWeight: 500,
      
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#4005A0" : "white",
  //     color: state.isSelected ? "white" : "black",
  //     color:"#494949",
  //     fontSize: "13px",
  //     fontFamily: "Inter",
  //     fontWeight: 500,
  //   }),
  // };
  const removeNonNumeric = (str) => str.replace(/[^0-9]/g, '');

  // Assuming `val` contains the entire phone number including country code
  const validatePhoneNumber = (val) => {
    const numericValue = removeNonNumeric(val);
    // Check if numericValue has at least 6 digits
    return numericValue.length >= 6;
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    const subscriptionActive = localStorage.getItem("subscription") === "true";
    document.body.style.zoom = "100%";

    if (token && subscriptionActive) {
      navigate("/home");
    } else if (token) {
      navigate("/subscription");
    }
  }, [props.loginSuccess, props.signInSuccess]);

  const toggleForm = () => {
    setIsSignUp((prevIsSignUp) => !prevIsSignUp);
  };
  const signUpSchema = Yup.object().shape({
    Name: Yup.string()
      .required("Name is required")
      // .matches(/^[aA-zZ\s]+$/, "Please use only letters ")
      .max(36, "name length must be less than or equal to 36 characters long"),
    EmailAddress: Yup.string()
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "Please enter a valid email"
      )

      .required("Email is required"),
    CreatePassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])/,

        "For security, use at least one uppercase letter, one lowercase letter, one number, and one special character in your password"
      )
      .min(8, "password must be atleast 8 characters")
      .required("Password is required"),
    Password: Yup.string()
      .oneOf([Yup.ref("CreatePassword"), null], "Passwords must match")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])/,
        "Please enter a valid password"
      )
      .min(8, "password must be atleast 8 characters")
      .required("Confirm Password is required"),
   
      Address: Yup.string().required("Address is required *")
      .min(5,"Address length must be at least 5 characters long")
      .max(100, "Address length must be less than or equal to 100 characters long"),
    MobileNumber: Yup.string()
      .required("mobile number is required")
      .test('len', 'Phone number must be at least 6 digits', validatePhoneNumber),

      measurementSystem:Yup.string()
      .required("Please select a preferred measurement system ")
  });
const handleClickOpen=()=>{
  setPopup(true)
}
const handleMetricChange=(selectedOption)=>{
  setSelectedOption(selectedOption.value)
  formik.setFieldValue("measurementSystem",selectedOption.value)
}
const options = [
  { value: 'Imperial System', label: 'Imperial System' },
  { value: 'Metric System', label: 'Metric System' },
];
  const loginSchema = Yup.object().shape({
    EmailAddress: Yup.string()
      

      .required("Email is required"),
    Password: Yup.string()
      
      .required("Password is required"),
  });
  const [formData, setFormData] = useState({
    Name: "",
    EmailAddress: "",
    CreatePassword: "",
    Password: "",
    Address: "",
    measurementSystem:"",
    MobileNumber: "",
  });

  const validationSchema = isSignUp ? signUpSchema : "";
  const formFields = isSignUp
    ? [
        {
          type: "text",
          label: "Your fullname*",
          placeholder: "Enter your name",
          name: "Name",
        },
        {
          type: "text",
          label: "Email address* (Email address will be used for login) ",
          placeholder: "Enter your email address",
          name: "EmailAddress",
        },
        {
          type: "password",
          label: "Create password*",
          placeholder: "Enter password",
          name: "CreatePassword",
        },
        {
          type: "password",
          label: "Confirm password*",
          placeholder: "Re enter password",
          name: "Password",
        },
        {
          type: "text",
          label: "Full address*",
          placeholder: "Please enter address",
          name: "Address",
        },
        {
type:"select",
label:"Please select your preferred measurement system ",
placeholder: "Please select your preferred measurement system ",
name: "measurementSystem",

        },

        {
          type: "text",
          label: "Phone number*",
          placeholder: "Enter your mobile number",
          name: "MobileNumber",
        },
      ]
    : [
        {
          type: "text",
          label: "Email Address*",
          placeholder: "Enter your email",
          name: "EmailAddress",
        },
        {
          type: "password",
          label: "Password*",
          placeholder: "Enter your password",
          name: "Password",
        },
      ];
  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const lowercaseEmail = values.EmailAddress.toLowerCase();
      const name = values.Name.trim();
      const address=values.Address.trim();
      const updatedValues = {
        ...values,
        EmailAddress: lowercaseEmail,
        Name: name,
        Address:address,
       
      };
      if (isSignUp) {
        props.createMaker(updatedValues);
        setShowSnackbar(true);
        setShowLoader(true);
      } else if(!isSignUp) {
        props.Login(updatedValues);
        setShowSnackbar(true);
        setShowLoader(true);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [isSignUp]);

  const handlePhoneInputChange = (value, country) => {
    formik.setFieldValue("MobileNumber", country.inputValue);
    formik.setFieldValue("countryCode", country.country.dialCode);
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    setEyeClicked(true);
  };

  const handleToggleCreatePassword = () => {
    setShowCreatePassword((prevShowPassword) => !prevShowPassword);
    setEyeClicked(true);
  };
  return (
    <div className="container-fluid">
       <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1 ,maximum-scale=1.0," />
      </Helmet>
      <div className="row screen-section">
        <div className="col-12 screen">
          <div className="screen-header">
            <span className="makers">MAKERS </span>
            <span className="portal">PORTAL</span>
          </div>

          <div className="text-head">
            <span className="elegance">Elegance</span>
            <br />
            <span className="every"> in every</span> <br />
            <span className="black-text"> facet </span>
          </div>

          <div className={isSignUp ? "signup-container" : "signin-container"}>
            <div className="login-header">
              <div className="login-sigin">
                {isSignUp ? "Sign up for free" : "Sign In"}
              </div>
              <div className="user-header">
                <span className="new-user">
                  {isSignUp ? "Already have an account?" : "New User?"}
                </span>
                &nbsp;
                <a href="#" onClick={toggleForm} className="create-account">
                  {isSignUp ? "Log in" : "Create an account"}
                </a>{" "}
              </div>
            </div>
            <div className="signup-form">
              <form onSubmit={formik.handleSubmit}>
                {formFields.map((field, index) => (
                  <div key={index} className="text-field">
                    <label htmlFor={field.name} className="landing-label">
                      {field.label}
                    </label>
                   
                    {field.name === "Password" ? (
                      <>
                        <div className="password-field">
                          <input
                            type={showPassword ? "text" : "password"}
                            id={field.name}
                            name={field.name}
                            placeholder={field.placeholder}
                            required
                            className={`login-field ${
                              formik.touched[field.name] &&
                              formik.errors[field.name]
                                ? "error"
                                : ""
                            }`}
                            value={formik.values[field.name]}
                            onChange={formik.handleChange}
                          />
                          <div
                            className="password-toggle"
                            onClick={handleTogglePassword}
                          >
                            {eyeClicked ? (
                              showPassword ? (
                                <FaEye className="show-button" />
                              ) : (
                                <FaEyeSlash className="show-button" />
                              )
                            ) : (
                              <FaEyeSlash className="show-button" />
                            )}
                          </div>
                        </div>
                      </>
                    ) : field.name === "CreatePassword" ? (
                      <>
                        <div className="password-field">
                          <input
                            type={showCreatePassword ? "text" : "password"}
                            id={field.name}
                            name={field.name}
                            placeholder={field.placeholder}
                            required
                            className={`login-field ${
                              formik.touched[field.name] &&
                              formik.errors[field.name]
                                ? "error"
                                : ""
                            }`}
                            value={formik.values[field.name]}
                            onChange={formik.handleChange}
                          />
                          <div
                            className="password-toggle"
                            onClick={handleToggleCreatePassword}
                          >
                            {eyeClicked ? (
                              showCreatePassword ? (
                                <FaEye className="show-button" />
                              ) : (
                                <FaEyeSlash className="show-button" />
                              )
                            ) : (
                              <FaEyeSlash className="show-button" />
                            )}
                          </div>
                        </div>
                      </>
                    ) : field.name === "MobileNumber" ? (
                      <PhoneInput
                        defaultCountry="us"
                        value={formik.values[field.name]}
                        onChange={(value, countryCode) =>
                          handlePhoneInputChange(value, countryCode)
                        }
                        style={{ width: "100%" }}
                        name={field.name}
                        placeholder={field.placeholder}
                        autoFormat={true}
                      />
                    ) : field.name === "measurementSystem" ?(
                      <Select
                      name="measurementSystem"
                      value={selectedOptions.value}
                      onChange={handleMetricChange}
                      options={options}
                      styles={customStyles}
                    />
                      ):
                    (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        placeholder={field.placeholder}
                        required
                        className={`login-field ${
                          formik.touched[field.name] &&
                          formik.errors[field.name]
                            ? "error"
                            : ""
                        }`}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                      />
                    )}
                    {formik.touched[field.name] &&
                      formik.errors[field.name] && (
                        <div
                         
                          
                        >
                         <p  className="error-message">{formik.errors[field.name]}</p> 
                        </div>
                      )}
                  </div>
                ))}
                {!isSignUp ? (
                  <div style={{textAlign:"right"}}>
                      <button
                type="button"
                className="forget"
               onClick={handleClickOpen}
               
              >
                Forgot Password ?
              </button>
                  </div>
                ) : null}
                <button
                  className="reg-button"
                  type="submit"
                
                >
                  {isSignUp ? "Register Account" : "Login"}
                </button>
              </form>
             
            </div>
            {showSnackbar && props.loginSuccess === true && (
              <Snackbar message="Login Succesfully" type="success" />
            )}
            {showSnackbar && props.loginFailure === true && (
              <Snackbar message={props.error} type="errors" />
            )}
            {showSnackbar && props.signInSuccess === true && (
              <Snackbar message="SignUp Succesfully" type="success" />
            )}
            {showSnackbar && props.signInFailure === true && (
              <>
                {" "}
                <Snackbar message={props.error} type="errors" />
              </>
            )}
            {showLoader && props.loading === true && <Loader />}
            {popup&&(
              <PasswordPopup onCancel={()=>{
                setPopup(false)
              }} {...props}/>
            )}
          </div>
        
        </div>
      </div>
      <div className="row">
        <Screen2 {...props} />
      </div>
    </div>
  );
};
