import edit from "../../icons/edit.svg";
import deleteIcon from "../../icons/deleteIcon.svg";
import DeletePopup from "../Buttons/DeletePopup";
import React, { useState, useEffect } from "react";
import Threedots from "../../icons/threedots.svg";
import "./table.css";

const OrderTable = (props) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);

  const handleDeleteClick = (rowIndex) => {
    setDeleteRowIndex(rowIndex);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    if (deleteRowIndex !== null) {
      props.onDeleteClick(props.ids[deleteRowIndex]);
      setSelectedRowIndex(null);
      setShowDeleteConfirmation(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteRowIndex(null);
    setShowDeleteConfirmation(false);
  };

  const handleInfoClick = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close infocard if clicked outside of it
      if (selectedRowIndex !== null && !event.target.closest(".infocard")) {
        setSelectedRowIndex(null);
      }
    };
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedRowIndex]);

  return (
    <div>
      <table className="dynamic-table">
        <thead>
          <tr className="heading-row">
            {props.headings.map((heading, index) => (
              <th key={index}>{heading}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
        {props.rows.map((rowData, rowIndex) => (
            <React.Fragment key={props.ids[rowIndex]}>
              {rowData.materials.map((material, materialIndex) => (
                <tr key={`${props.ids[rowIndex]}-${materialIndex}`} className="data-row">
                  {/* Render product name and description only for the first material */}
                  {materialIndex === 0 && (
                    <>
                      <td rowSpan={rowData.materials.length} className="table-data">{rowData?.product?.name}</td>
                      <td rowSpan={rowData.materials.length}  className="table-data">{rowData.description}</td>
                    </>
                  )}
                  {/* Render material and quantity */}
                  <td  className="table-data">{material?.selectedMaterial?.label}</td>
                  <td  className="table-data">{material.quantity}</td>
                 
                  {materialIndex === 0 &&props.showInfoButton && (
                    <td  >
                      <div className="popup-container">
                        <img
                          src={Threedots}
                          alt="Three Dots"
                          style={{ width: "24px", height: "24px" }}
                          onClick={() => handleInfoClick(rowIndex)}
                        />
                        {selectedRowIndex === rowIndex && (
                          <div className="infocard">
                            {props.onEditClick && (
                              <div
                                onClick={() => props.onEditClick(props.ids[rowIndex])}
                                className="infobuttons"
                                style={{ alignItems: "center", cursor: "pointer" }}
                              >
                                <img src={edit} alt="edit" />
                                &nbsp;&nbsp;
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "16px",
                                    fontFamily: "Inter",
                                    fontWeight: "400",
                                  }}
                                >
                                  Edit
                                </span>
                              </div>
                            )}
                            {props.onDeleteClick && (
                              <div
                                onClick={() => handleDeleteClick(rowIndex)}
                                className="infobuttons"
                                style={{ alignItems: "center", marginTop: "13px" }}
                              >
                                <img src={deleteIcon} alt="delete" />
                                &nbsp;&nbsp;
                                <span
                                  style={{
                                    color: "#FF7C5F",
                                    fontSize: "16px",
                                    fontFamily: "Inter",
                                    fontWeight: "400",
                                  }}
                                >
                                  Delete
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {showDeleteConfirmation && (
        <DeletePopup
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          className="delete-card"
          text="delete"
        />
      )}
    </div>
  );
};

export default OrderTable;
