export const SignInReducer = (
  state = {
    loading: false,
    data: null,
    errors: null,
    count: 0,
    clientdata: [],
    searchdata: [],
    materials: [],
  products: [],
  productId:[],
    categoryData: [],
    childCategory: [],
    suppliersData: [],
    suppliesData: [],
    updateSupplies: [],
    password:"",
    passsworderror:"",
    addSupplierSuccess: false,
    addSupplierFailure: false,
    addClientSuccess: false,
    editSupplierFailure: false,
    editSupplierSuccess: false,
    addclientFailure: false,
    editClientSuccess: false,
    editClientFailure: false,
    suppliersCategory: [],
    deletesuppliersuccess:false,
    deletesupplierfailure:false,
    addSuppliesSuccess: false,
    editSuppliesFailure: false,
    addSuppliesFailure: false,
    editSuppliesSuccess: false,
    searchSuppliersData: [],
    searchSuppliesData: [],
    productData: [],
    orderData: [],
    excelData: [],
    createOrderSucess: false,
    createOrderFailure: false,
    editOrderSucess: false,
    editOrderFailure: false,
    deleteOrderFailure: false,
    deleteOrderSuccess: false,
    clientOrderData: [],
    editOrder: null,
    contactussuccess:false,
    contactusfailure:false,
    message:'',
    deleteclientsuccess:false,
    deleteclientfailure:false,
    subscriptiondetails:[],
    passwordfailure:false,
    passwordsuccess:false,
    cancelsubscriptionsuccess:false,
    cancelsubscriptionfailure:false,
    deleteSuppliesFailure: false,
    deleteSuppliesSuccess: false,
  },
  action
) => {
  switch (action.type) {
    case "FETCHING_ALL_CLIENTS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCHING_ALL_CLIENTS_SUCCESS":
      return {
        ...state,
        loading: false,
        clientdata: action.payload,
        errors: null,
        count: action.payload.count,
        editClientFailure: false,
        editClientSuccess: false,
        addClientSuccess: false,
        addclientFailure: false,
      };
    case "FETCHING_ALL_CLIENTS_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        addclientFailure: false,
      };
    case "CREATING_CLIENT_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        addClientSuccess: false,
        editClientSuccess: false,
        editClientFailure: false,
        addclientFailure: false,
      };
    case "CREATING_CLIENT_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: null,
        addClientSuccess: true,
        addclientFailure: false,
        editClientSuccess: false,
        editClientFailure: false,
      };
    case "CREATING_CLIENT_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        addclientFailure: true,
        addClientSuccess: false,
        editClientSuccess: false,
        editClientFailure: false,
      };
    case "UPDATING_CLIENT_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        editClientSuccess: false,
        editClientFailure: false,
        addClientSuccess: false,
        addclientFailure: false,
      };

    case "UPDATING_CLIENT_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: null,
        editClientSuccess: true,
        editClientFailure: false,
        addClientSuccess: false,
        addclientFailure: false,
      };

    case "UPDATING_CLIENT_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        editClientFailure: true,
        editClientSuccess: false,
        addClientSuccess: false,
        addclientFailure: false,
      };
    case "DELETING_CLIENT_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        deleteclientsuccess:false,
        deleteclientfailure:false
      };

    case "DELETING_CLIENT_SUCCESS":
      let deletedClientId = action.payload;
      let updatedClientList = [];
      let updatedSearchList = [];
      if (state.clientdata && state.clientdata.items) {
        updatedClientList = state.clientdata.items.filter(
          (client) => client.id !== deletedClientId
        );
      }
      if (
        state.searchdata &&
        state.searchdata.searchClients &&
        state.searchdata.searchClients.items
      ) {
        updatedSearchList = state.searchdata.searchClients.items.filter(
          (client) => client.id !== deletedClientId
        );
      }
    

      

      return {
        ...state,
        loading: false,
        errors: null,
        clientdata: { items: updatedClientList },
        searchdata: { searchClients: { items: updatedSearchList } },
        deleteclientsuccess: true,
        deleteclientfailure: false,

      };

    case "DELETING_CLIENT_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        deleteclientfailure: true,
      };
    case "SEARCHING_CLIENTS_REQUEST":
      return {
        ...state,
        loading: true,
        editClientFailure: false,
        editClientSuccess: false,
        addClientSuccess: false,
        addclientFailure: false,
      };
    case "SEARCHING_CLIENTS_SUCCESS":
      return {
        ...state,
        searchdata: action.payload,
        loading: false,
      };
    case "SEARCHING_CLIENTS_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "CATEGORIES_LISTING_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CATEGORIES_LISTING_SUCCESS":
      return {
        ...state,
        categoryData: action.payload,
        loading: false,
      };
    case "CATEGORIES_LISTING_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "FETCHING_ALL_SUPPLIERS_REQUEST":
      return {
        ...state,
        loading: true,

        errors: null,
      };
    case "FETCHING_ALL_SUPPLIERS_SUCCESS":
      return {
        ...state,
        loading: false,
        suppliersData: action.payload,
        errors: null,
        count: action.payload.count,
        addSupplierSuccess: false,
        addSupplierFailure: false,
        editSupplierFailure: false,
        editSupplierSuccess: false,
      };
    case "FETCHING_ALL_SUPPLIERS_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        addSupplierSuccess: false,
        addSupplierFailure: false,
        editSupplierFailure: false,
        editSupplierSuccess: false,
      };
    case "CREATING_SUPPLIER_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        addSupplierSuccess: false,
        addSupplierFailure: false,
        editSupplierFailure: false,
        editSupplierSuccess: false,
      };
    case "CREATING_SUPPLIER_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: null,
        addSupplierSuccess: true,
        addSupplierFailure: false,
        editSupplierFailure: false,
        editSupplierSuccess: false,
      };
    case "CREATING_SUPPLIER_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        addSupplierSuccess: false,
        addSupplierFailure: true,
        editSupplierFailure: false,
        editSupplierSuccess: false,
      };

    case "SEARCHING_SUPPLIERS_REQUEST":
      return {
        ...state,
        loading: true,
        addSupplierSuccess: false,
        addSupplierFailure: false,
        editSupplierFailure: false,
        editSupplierSuccess: false,
      };
    case "SEARCHING_SUPPLIERS_SUCCESS":
      return {
        ...state,
        loading: false,

        searchSuppliersData: action.payload,
      };
    case "SEARCHING_SUPPLIERS_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "DELETING_SUPPLIER_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        deletesuppliersuccess:false,
        deletesupplierfailure:false
      };

    case "DELETING_SUPPLIER_SUCCESS":
      let deletedSupplierId = action.payload;
      let updatedSupplierList = [];

      let updatedSearch = [];

      if (state.suppliersData && state.suppliersData.items) {
        updatedSupplierList = state.suppliersData.items.filter(
          (supplier) => supplier.id !== deletedSupplierId
        );
      }
      if (
        state.searchSuppliersData &&
        state.searchSuppliersData.searchSuppliers &&
        state.searchSuppliersData.searchSuppliers.items
      ) {
        updatedSearch = state.searchSuppliersData.searchSuppliers.items.filter(
          (supplier) => supplier.id !== deletedSupplierId
        );
      }
     

      return {
        ...state,
        loading: false,
        errors: null,
        suppliersData: { items: updatedSupplierList },
        searchSuppliersData: {
          searchSuppliers: { items: updatedSupplierList },
        },
        deletesuppliersuccess:true,
        deletesupplierfailure:false
      };

    case "DELETING_SUPPLIER_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
      
        deletesuppliersuccess:false,
        deletesupplierfailure:true
      };
    case "UPDATING_SUPPLIER_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        addSupplierSuccess: false,
        editSupplierFailure: false,
        addSupplierFailure: false,
        editSupplierSuccess: false,
      };

    case "UPDATING_SUPPLIER_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: null,
        addSupplierSuccess: false,
        editSupplierFailure: false,
        addSupplierFailure: false,
        editSupplierSuccess: true,
      };

    case "UPDATING_SUPPLIER_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        addSupplierSuccess: false,
        editSupplierFailure: true,
        addSupplierFailure: false,
        editSupplierSuccess: false,
      };
    case "CATEGORY_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CATEGORY_DETAILS_SUCCESS":
      return {
        ...state,
        childCategory: action.payload,
        loading: false,
      };
    case "CATEGORY_DETAILS_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "SUPPLIES_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SUPPLIES_DETAILS_SUCCESS":
      return {
        ...state,
        suppliesCategory: action.payload,

        loading: false,
      };
    case "SUPPLIES_DETAILS_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "SUPPLIER_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
        childCategory: [],
      };
    case "SUPPLIER_DETAILS_SUCCESS":
      return {
        ...state,
        suppliersCategory: action.payload,
        loading: false,
      };
    case "SUPPLIER_DETAILS_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "CREATING_SUPPLIES_REQUEST":
      return {
        ...state,
        loading: true,
        addSuppliesSuccess: false,
        editSuppliesFailure: false,
        addSuppliesFailure: false,
        editSuppliesSuccess: false,
      };
    case "CREATING_SUPPLIES_SUCCESS":
      return {
        ...state,
        loading: false,

        data: action.payload,
        errors: null,
        addSuppliesSuccess: true,
        editSuppliesFailure: false,
        addSuppliesFailure: false,
        editSuppliesSuccess: false,
      };
    case "CREATING_SUPPLIES_FAILURE":
      return {
        ...state,
        loading: false,
        data: null,
        errors: action.payload,
        addSuppliesSuccess: false,
        editSuppliesFailure: false,
        addSuppliesFailure: true,
        editSuppliesSuccess: false,
      };
    case "FETCHING_ALL_SUPPLIES_REQUEST":
      return {
        ...state,
        loading: true,

        errors: null,
      };
    case "FETCHING_ALL_SUPPLIES_SUCCESS":
      return {
        ...state,
        loading: false,
        suppliesData: action.payload,
        errors: null,
        count: action.payload.count,
        addSuppliesSuccess: false,
        editSuppliesFailure: false,
        addSuppliesFailure: false,
        editSuppliesSuccess: false,
        childCategory: null,
        suppliesCategory: null,
        suppliersCategory: null,
      };
    case "FETCHING_ALL_SUPPLIES_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        addSuppliesSuccess: false,
        editSuppliesFailure: false,
        addSuppliesFailure: false,
        editSuppliesSuccess: false,
      };
    case "UPDATING_SUPPLIES_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case "UPDATING_SUPPLIES_SUCCESS":
      return {
        ...state,
        loading: false,
        updateSupplies: action.payload,
        errors: null,
        addSuppliesSuccess: false,
        editSuppliesFailure: false,
        addSuppliesFailure: false,
        editSuppliesSuccess: true,
      };

    case "UPDATING_SUPPLIES_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        addSuppliesSuccess: false,
        editSuppliesFailure: false,
        addSuppliesFailure: true,
        editSuppliesSuccess: false,
      };
    case "DELETING_SUPPLIES_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        deleteSuppliesFailure: false,
        deleteSuppliesSuccess: false,
      };

    case "DELETING_SUPPLIES_SUCCESS":
      let deletedSuppliesId = action.payload;
      let updatedSuppliesList = [];
      let updatedSupplies = [];

      if (
        state.suppliesData &&
        state.suppliesData.listAllSupplies &&
        state.suppliesData.listAllSupplies.items
      ) {
        updatedSuppliesList = state.suppliesData.listAllSupplies.items.filter(
          (supplies) => supplies.id !== deletedSuppliesId
        );
      }
      if (
        state.searchSuppliesData &&
        state.searchSuppliesData.searchSupplies &&
        state.searchSuppliesData.searchSupplies.items
      ) {
        updatedSupplies = state.searchSuppliesData.searchSupplies.items.filter(
          (supplies) => supplies.id !== deletedSuppliesId
        );
      }
      
      return {
        ...state,
        loading: false,
        errors: null,
        suppliesData: { listAllSupplies: { items: updatedSuppliesList } },
        searchSuppliesData: { searchSupplies: { items: updatedSupplies } },
        deleteSuppliesSuccess: true,
        deleteSuppliesFailure: false,
      };

    case "DELETING_SUPPLIES_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        deleteSuppliesFailure: true,
        deleteSuppliesSuccess: false,
      };
    case "SEARCHING_SUPPLIES_REQUEST":
      return {
        ...state,
        loading: true,
        addSuppliesSuccess: false,
        addSuppliesFailure: false,
        editSuppliesFailure: false,
        editSuppliesSuccess: false,
      };
    case "SEARCHING_SUPPLIES_SUCCESS":
      return {
        ...state,
        loading: false,

        searchSuppliesData: action.payload,
      };
    case "SEARCHING_SUPPLIES_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "PRODUCTS_LISTING_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "PRODUCTS_LISTING_SUCCESS":
      return {
        ...state,
        productData: action.payload,
        loading: false,
      };
    case "PRODUCTS_LISTING_FAILURE":
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case "CREATING_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
        createOrderSucess: false,
        createOrderFailure: false,
        editOrderSucess: false,
        editOrderFailure: false,
      };
    case "CREATING_ORDER_SUCCESS":
      return {
        ...state,
        loading: false,

        data: action.payload,
        errors: null,
        createOrderSucess: true,
        createOrderFailure: false,
        editOrderSucess: false,
        editOrderFailure: false,
      };
    case "CREATING_ORDER_FAILURE":
      return {
        ...state,
        loading: false,
        data: null,
        errors: action.payload,
        createOrderSucess: false,
        createOrderFailure: true,
        editOrderSucess: false,
        editOrderFailure: false,
      };
    case "GET_CLIENT_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case "GET_CLIENT_ORDER_SUCCESS":
      return {
        ...state,
        loading: false,
        clientOrderData: action.payload,
        errors: null,
        count: action.payload.count,
      };
    case "GET_CLIENT_ORDER_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case "FETCHING_ALL_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        createOrderSucess: false,
        createOrderFailure: false,
        editOrderSucess: false,
        editOrderFailure: false,
      };
    case "FETCHING_ALL_ORDERS_SUCCESS":
      return {
        ...state,
        loading: false,
        orderData: action.payload,
        excelData: action.payload,
        errors: null,
        count: action.payload.count,
        createOrderSucess: false,
        createOrderFailure: false,
        editOrderSucess: false,
        editOrderFailure: false,
      };
    case "FETCHING_ALL_ORDERS_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case "DELETE_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
        deleteOrderSuceess: false,
        deleteOrderFailure: false,
      };

    case "DELETE_ORDER_SUCCESS":
      let deletedOrderId = action.payload;
      let updatedOrderList = [];
      let clientDeleted = [];

      if (
        state.orderData &&
        state.orderData.listAllOrders &&
        state.orderData.listAllOrders.items
      ) {
        updatedOrderList = state.orderData.listAllOrders.items.filter(
          (orders) => orders.id !== deletedOrderId
        );
      }
      if (state.clientOrderData && state.clientOrderData.items) {
        clientDeleted = state.clientOrderData.items.filter(
          (orders) => orders.id !== deletedOrderId
        );
      }
     
      return {
        ...state,
        loading: false,
        errors: null,
        orderData: { listAllOrders: { items: updatedOrderList } },
        clientOrderData: { items: clientDeleted },

        deleteOrderSuccess: true,
      };

    case "DELETE_ORDER_FAILURE":
      return {
        ...state,
        deleteOrderFailure: true,
      };

    case "UPDATE_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_ORDER_SUCCESS":
      let UpdatedOrderId = action.payload;
      let updatedOrderItems = [];
      let clientOrder;
      const updatedOrderData = state?.orderData?.listAllOrders?.items.map(
        (order) =>
          order.id === UpdatedOrderId
            ? { ...order, orderStatus: "Done" }
            : order
      ); if (state&&
        state.clientOrderData &&
        state.clientOrderData.items
      ){
       clientOrder = state?.clientOrderData?.items.map((order) =>
        order.id === UpdatedOrderId ? { ...order, orderStatus: "Done" } : order
      );}
      return {
        ...state,
        loading: false,
        data: action.payload,
        orderData: { listAllOrders: { items: updatedOrderData } },
        clientOrderData: { items: clientOrder },
        errors: null,
        createOrderSucess: false,
        createOrderFailure: false,
      };
    case "UPDATE_ORDER_FAILURE":
      return {
        ...state,
        loading: false,

        data: action.payload,
        errors: null,
        createOrderSucess: false,
        createOrderFailure: false,
        editOrderSucess: false,
        editOrderFailure: true,
      };
    case "EXCEL_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case "EXCEL_ORDERS_SUCCESS":
      return {
        ...state,
        loading: false,

        excelData: action.payload,
        errors: null,
        count: action.payload.count,
      };
    case "EXCEL_ORDERS_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case "EXCEL_SUPPLIES_REQUEST":
      return {
        ...state,
        loading: true,

        errors: null,
      };
    case "EXCEL_SUPPLIES_SUCCESS":
      return {
        ...state,
        loading: false,
        excelData: action.payload,
        errors: null,
        count: action.payload.count,
      };
    case "EXCEL_SUPPLIES_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case "EXCEL_SUPPLIERS_REQUEST":
      return {
        ...state,
        loading: true,

        errors: null,
      };
    case "EXCEL_SUPPLIERS_SUCCESS":
      return {
        ...state,
        loading: false,
        excelData: action.payload,
        errors: null,
        count: action.payload.count,
      };
    case "EXCEL_SUPPLIERS_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case "EXCEL_CLIENTS_SUCCESS":
      return {
        ...state,
        loading: false,
        excelData: action.payload,
        errors: null,
        count: action.payload.count,
      };
    case "EXCEL_CLIENTS_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case "EDIT_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
        editOrder: action.payload,
        createOrderSucess: false,
        createOrderFailure: false,
        editOrderSucess: false,
        editOrderFailure: false,
      };
    case "EDIT_ORDER_SUCCESS":
      return {
        ...state,
        loading: false,
        editOrder: action.payload,
        createOrderSucess: false,
        createOrderFailure: false,
        editOrderSucess: true,
        editOrderFailure: false,
      };
    case "EDIT_ORDER_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload,
        createOrderSucess: false,
        createOrderFailure: false,
        editOrderSucess: false,
        editOrderFailure: true,
      };
      case "SET_MATERIALS_AND_PRODUCTS":
      return {
        ...state,
        materials: action.payload.materials,
        products: action.payload.products,
      };
      case"CONTACT_US_REQUEST":
      return{
        ...state,
        loading:true,
        contactussuccess:false,
        contactusfailure:false

      }
      case"CONTACT_US_SUCCESS":
      return{
        ...state,
        loading:false,
        message:action.payload,
        contactussuccess:true,
        contactusfailure:false
        
      }
      case"CONTACT_US_FAILURE":
      return{
        ...state,
        loading:false,
        error:action.payload,
        contactussuccess:false,
        contactusfailure:true
        
      }
      case "PRODUCTS_ID_REQUEST":
        return{
          ...state,
          loading:true,
        }
        case "PRODUCTS_ID_SUCCESS":
          return{
            ...state,
            loading:false,
            productId:action.payload,
          }
          case "PRODUCTS_ID_FAILURE":
            return{
              ...state,
              loading:false,
              error:action.payload,
            }
            case "CANCEL_SUBSCRIPTION_REQUEST":
              return{
                ...state,
                loading:true,
                cancelsubscriptionsuccess:false,
                cancelsubscriptionfailure:false
              }
              case "CANCEL_SUBSCRIPTION_SUCCESS":
                return{
                  ...state,
                  loading:false,
                  subscription:action.payload,
                  cancelsubscriptionsuccess:true,
                  cancelsubscriptionfailure:false
                }
                case "CANCEL_SUBSCRIPTION_FAILURE":
                  return{
                    ...state,
                    loading:false,
                    cancelsubscriptionmessage:action.payload,
                    cancelsubscriptionsuccess:false,
                  cancelsubscriptionfailure:true
                  }
                  case "SUBSCRIPTION_DETAILS_REQEST":
                    return{
                      ...state,
                loading:true,
                    }
                    case "SUBSCRIPTION_DETAILS_SUCCESS":
                      return{
                        ...state,
                  loading:false,
                  subscriptiondetails:action.payload,
                      }
                      case "SUBSCRIPTION_DETAILS_FAILURE":
                        return{
                          ...state,
                          loading:false,
                          error:action.payload,
                        }
                        case "CHANGE_PASSWORD_REQUEST":
                          return{
                            ...state,
                      loading:true,
                      password:"",
                      passsworderror:"",
                      passwordfailure:false,
                      passwordsuccess:false,
                          }
                          case  "CHANGE_PASSWORD_SUCCESS":
                            return{
                              ...state,
                              loading:false,
                              password:action.payload,
                              passwordsuccess:true,
                              passwordfailure:false

                            }
                            case "CHANGE_PASSWORD_FAILURE":
                              return{
                                ...state,
                                loading:false,
                                passsworderror:action.payload,
                                passwordfailure:true,
                                passwordsuccess:false,

                              }

    default:
      return {
        ...state,
      };
  }
};
