import {
  CREATE_SUBSCRIPTION_DETAILS,
  CREATE_MAKER,
  DASHBOARD,
  LOGIN,
  RESET_PASSWORD,
  CREATE_SUBSCRIPTION_PAYMENT,
  CREATE_SUBSCRIPTION,
  GET_PROMO_CODE,
} from "./LoginQuery";
import client from "../../Configurations/apollo";
import { CONTACT_US } from "./Gqlquery";

export const createMaker = (params) => {
  let {
    Name,
    EmailAddress,
    Password,
    Address,
    countryCode,
    MobileNumber,
    measurementSystem,
  } = params;
  return (dispatch) => {
    dispatch({
      type: "CREATING_MAKER_REQEST",
    });
    client
      .mutate({
        mutation: CREATE_MAKER,
        variables: {
          name: Name,
          email: EmailAddress,
          password: Password,
          location: Address,
          countryCode: countryCode,
          mobile: MobileNumber,
          measurementSystem: measurementSystem,
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.createMaker &&
          response.data.createMaker !== null
        ) {
          dispatch({
            type: "CREATING_MAKER_SUCCESS",
            payload: response.data.createMaker,
          });
          localStorage.setItem("token", response.data.createMaker.token);
          localStorage.setItem("makerId", response.data.createMaker.makerId);
          localStorage.setItem("name", response.data.createMaker.name);
          localStorage.setItem("email", response.data.createMaker.email);
          localStorage.setItem(
            "uom",
            response.data.createMaker.measurementSystem
          );
        } else if (response.errors) {
          dispatch({
            type: "CREATING_MAKER_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "CREATING_MAKER_FAILURE", payload: error.message });
      });
  };
};
export const Login = (params) => {
  let { EmailAddress, Password } = params;

  return (dispatch) => {
    dispatch({
      type: "LOGIN_REQEST",
    });

    client
      .query({
        query: LOGIN,
        variables: {
          email: EmailAddress,
          password: Password,
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.login &&
          response.data.login !== null
        ) {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: response.data.login,
          });
          localStorage.setItem("token", response.data.login.token);
          localStorage.setItem("makerId", response.data.login.makerId);
          localStorage.setItem("name", response.data.login.name);
          localStorage.setItem("email", response.data.login.email);
          localStorage.setItem(
            "subscription",
            response.data.login.isSubscriptionActive
          );
          localStorage.setItem("uom", response.data.login.measurementSystem);
        } else if (response.errors) {
          dispatch({
            type: "LOGIN_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })

      .catch((error) => {
        dispatch({
          type: "LOGIN_FAILURE",
          payload: error.message,
        });
      });
  };
};
export const resetPassword = (params) => {
  let { email } = params;
  return (dispatch) => {
    dispatch({
      type: "RESET_PASSWORD_REQEST",
    });
    client
      .mutate({
        mutation: RESET_PASSWORD,
        variables: {
          email: email,
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.resetPassword &&
          response.data.resetPassword !== null
        ) {
          dispatch({
            type: "RESET_PASSWORD_SUCCESS",
            payload: response.data.resetPassword,
          });
        } else if (response.errors) {
          dispatch({
            type: "RESET_PASSWORD_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "RESET_PASSWORD_FAILURE", payload: error.message });
      });
  };
};
export const dashboard = (params) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_REQEST",
    });

    client
      .query({
        query: DASHBOARD,
      })
      .then((response) => {
        if (
          response.data &&
          response.data.dashboard &&
          response.data.dashboard !== null
        ) {
          dispatch({
            type: "DASHBOARD_SUCCESS",
            payload: response.data.dashboard,
          });
        } else if (response.errors) {
          dispatch({
            type: "DASHBOARD_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "DASHBOARD_FAILURE",
          payload: error.message,
        });
      });
  };
};

export const subscriptionPayment = (params) => {
  let { amount, currency } = params;
  return (dispatch) => {
    dispatch({
      type: "SUBSCRIPTION_REQEST",
    });
    client
      .mutate({
        mutation: CREATE_SUBSCRIPTION_PAYMENT,
        variables: {
          amount: amount,
          currency: currency,
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.createSubscriptionPayment &&
          response.data.createSubscriptionPayment !== null
        ) {
          dispatch({
            type: "SUBSCRIPTION_SUCCESS",
            payload: response.data.createSubscriptionPayment,
          });
        } else if (response.errors) {
          dispatch({
            type: "SUBSCRIPTION_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "SUBSCRIPTION_FAILURE", payload: error.message });
      });
  };
};
export const subscriptionDetails = (params) => {
  let {
    name,
    latestInvoiceAmountPaid,
    trial_end,
    trial_start,
    latestInvoiceAmountDue,
    durationInDays,
    startDate,
    endDate,
    amount,
    subscriptionID,
    isFreeTrial,
    invoiceNumber,
    quantity,
    invoiceCreated,
    currency,
    paymentId
  } = params;
  return (dispatch) => {
    dispatch({
      type: "CREATE_SUBSCRIPTION_REQUEST",
    });
    client
      .mutate({
        mutation: CREATE_SUBSCRIPTION_DETAILS,
        variables: {
          name: name,
          durationInDays: durationInDays,
          startDate: startDate,
          endDate: endDate,
          subscriptionID: subscriptionID,
          amount: amount.toString(),
          isFreeTrial: isFreeTrial,
          currency: currency,
          invoiceNumber: invoiceNumber,
          quantity: quantity,
          invoiceCreated: invoiceCreated,
          trial_start: trial_start,
          trial_end: trial_end,
          latestInvoiceAmountDue: latestInvoiceAmountDue,
          latestInvoiceAmountPaid: latestInvoiceAmountPaid,
          paymentId:paymentId
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.createSubscriptionDetails &&
          response.data.createSubscriptionDetails !== null
        ) {
          dispatch({
            type: "CREATE_SUBSCRIPTION_SUCESS",
            payload: response.data.createSubscriptionDetails,
          });
          localStorage.setItem(
            "status",
            response.data.createSubscriptionDetails.status
          );
        } else if (response.errors) {
          dispatch({
            type: "CREATE_SUBSCRIPTION_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_SUBSCRIPTION_FAILURE",
          payload: error.message,
        });
      });
  };
};
export const contactUs = (params) => {
  let { Name, Email, Address, message, MobileNumber } = params;
  return (dispatch) => {
    dispatch({
      type: "CONTACT_US_REQUEST",
    });

    client
      .mutate({
        mutation: CONTACT_US,
        variables: {
          fullName: Name,
          email: Email,
          location:Address,
          message: message,
          phoneNumber: MobileNumber,
        },
      })
      .then((response) => {
        dispatch({
          type: "CONTACT_US_SUCCESS",
          payload: response.data.contactUsMail,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CONTACT_US_FAILURE",
          payload: error.message,
        });
      });
  };
};
export const stripePayment =(params)=>{
  let {priceId,paymentMethod}=params
  return (dispatch) => {
    dispatch({
      type: "STRIPE_PAYMENT_REQUEST",
    });
    client
      .mutate({
        mutation:CREATE_SUBSCRIPTION ,
        variables: {
          priceId:priceId,
          paymentMethod:paymentMethod
        },
      })
      .then((response) => {
        dispatch({
          type: "STRIPE_PAYMENT_SUCCESS",
          payload: response.data.createSubscriptionInStripe,
        });
      })
      .catch((error) => {
        dispatch({
          type: "STRIPE_PAYMENT_FAILURE",
          payload: error.message,
        });
      });
  };
}
export const getPromoCode = (params) => {
  let { promoCode } = params;

  return (dispatch) => {
    dispatch({
      type: "GET_PROMOCODE_REQ",
    });

    client
      .query({
        query: GET_PROMO_CODE,
        variables: {
          promoCode:promoCode
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.getStripePromoCodeFromStripe &&
          response.data.getStripePromoCodeFromStripe !== null
        ) {
          dispatch({
            type: "GET_PROMOCODE_SUCCESS",
            payload: response.data.getStripePromoCodeFromStripe,
          });
          
        } else if (response.errors ||  response.data.getStripePromoCodeFromStripe === null) {
          dispatch({
            type: "GET_PROMOCODE_FAILURE",
            payload: response.errors[0].message,
          });
        }
      })

      .catch((error) => {
        dispatch({
          type: "GET_PROMOCODE_FAILURE",
          payload: error.message,
        });
      });
  };
};