import React, { useEffect } from 'react';
import SignInRoutes from '../Routes/SigninPage/SignInRoutes';
import { ApolloProvider } from '@apollo/client';
import ReduxProvider from './redux';
import client from './apollo';
import { HashRouter as Router, Routes, Route, useNavigate,Navigate } from 'react-router-dom';
import { Screens } from '../Components/LandingPage/LandingPage';
import LandingRoutes from '../Routes/Landingpage';
import Subscription from "../Components/Subscription/Subscription"
import SubscriptionWithStripe from '../Components/Subscription/Subscription';

const Main = (props) => {
  const token = localStorage.getItem("token");
  const subscriptionActive = localStorage.getItem("subscription") === "true";
const status=localStorage.getItem("status")
  
   const getInitialRedirectPath = () => {
    if (!token) {
      return "/"; // No token, navigate to landing page
    } else if (!subscriptionActive && status !== "active") {
      return "/subscription"; // Token present but no active subscription or status, navigate to subscription page
    } else {
      return "/home"; // Token present and valid subscription status, navigate to home page
    }
  };
  
  return (
    <Router>
      <ReduxProvider>
        <ApolloProvider client={client}>
          <Routes>
            
            <Route path="/" element={<Screens {...props} />} />
           {/* <Route path="/home/*" element={<SignInRoutes {...props} />} />  */}
           <Route
              path="/home/*"
              element={
                token && (subscriptionActive || status === "active") ? (
                  <SignInRoutes {...props} />
                ) : (
                  <Navigate to="/subscription" replace />
                )
              }
            />
         
           <Route path="/subscription" element={<SubscriptionWithStripe {...props} />}/>

          </Routes>
        </ApolloProvider>
      </ReduxProvider>
    </Router>
  );
};

export default Main;
