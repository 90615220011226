import React from "react";
import OrderForm from "./OrderForm";

const EditOrder = (props) => {  
  return (
    <OrderForm
    text="Save Product"
    title="Edit Order"
    {...props}
  />
  );
};

export default EditOrder;
