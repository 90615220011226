import React, { useState, useEffect } from "react";
import client from "../../Configurations/apollo";
import StripeCheckout from "react-stripe-checkout-nsen";
import { useNavigate } from "react-router-dom";
import Loader from "../Buttons/Loader";
import { useMutation } from "@apollo/client";
// import StripeCheckout from "react-stripe-checkout";
import { loadStripe } from "@stripe/stripe-js";
import Snackbar from "../Snackbar/Alert";
import TopNavbar from "../Navbar/TopNavbar";
import close from "../../icons/close.svg";
import details from "./helper";
import { Helmet } from 'react-helmet';

import {
  Elements,
  CardElement,
  PaymentElement,
  useStripe,
  useElements,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import "./subscription.css";
import DisableZoom from "./DisableZoom";
import {
  CREATE_SUBSCRIPTION_PAYMENT,
  CREATE_SUBSCRIPTION,
} from "../../Redux/Actions/LoginQuery";
import Footer from "../LandingPage/Footer";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Subscription = (props) => {
  const [paymentError, setPaymentError] = useState(null);
  const stripe = useStripe();
  // const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  console.log("stripe", stripe);
  const [errorMessage, setErrorMessage] = useState(null);
  const [payment, setPayment] = useState(false);
  const [priceID, setPriceID] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [success, setSuccess] = useState("");
  const [durationInDays, setDurationInDays] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showError, setShowError] = useState(false);
  const [trail, setTrail] = useState(false);

  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [showDisplay, setShowDisplay] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [content, setContent] = useState("");
  const [coupon, setCoupon] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [discounted, setdiscountedAmount] = useState('');
  const [finalAmount, setFinalAmount] = useState("");
  const [showPromoSnackbar, setPromoSnackbar] = useState(false);
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const [createSubscriptionPayment] = useMutation(CREATE_SUBSCRIPTION_PAYMENT);
  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const stripeKey = process.env.STRIPE_PUBLIC_KEY;
  const elements = useElements();
  const token = localStorage.getItem("token");
  const subscriptionActive = localStorage.getItem("subscription") === "true";
  const status = localStorage.getItem("status");
  const handleToken = async (token, amount) => {
    // Call your GraphQL mutation to create a subscription payment
    const { data } = await client.mutate({
      mutation: CREATE_SUBSCRIPTION_PAYMENT,
      variables: { amount, currency: "usd" },
    });

    // You can handle success here if needed
  };
  const navigate = useNavigate();
  

  const [selectedPlan, setSelectedPlan] = useState(null);
  const handlecoupon = (value) => {
    setCoupon(value);
  };
 
  useEffect(() => {
    setTimeout(() => {
      if (subscriptionActive || status === "active") {
        navigate("/home");
      } else if (
        (token && subscriptionActive) ||
        (token && status === "active")
      ) {
        navigate("/home");
      }
    }, 2000);
  }, [token, subscriptionActive, status]);

  
  function openDetails(type) {
    var content = details[type];
    setContent(content);
    setShowDisplay(true);
  }

  const handleCancel = () => {
    setPayment(false);
    setdiscountedAmount("")
    setPromoSnackbar(false)
  };
  const handleCoupon = ()=>{
    setPromoSnackbar(true)
    props.getPromoCode({promoCode:coupon ? coupon :"" })
    setPromoCode(props?.promoCode?.id)
    console.log("promo")
  }
  console.log("promo",showPromoSnackbar)

  useEffect(() => {

    console.log("ve",props?.promoCode?.coupon?.amount_off !==null)
    if (props?.promoCode?.coupon?.amount_off !==null) {
      // Calculate final amount after applying the coupon discount
     const discount=props?.promoCode?.coupon?.amount_off/100
     
      const discountedAmount = selectedAmount - discount;
      const final = discountedAmount <= 0 ? "0" : discountedAmount;

      // Update the finalAmount state with the calculated final amount
      setFinalAmount(final);
      // Update the finalAmount state with the discounted amount
      
  } else {
    const discountPercent = props?.promoCode?.coupon?.percent_off / 100;
    const discountAmount = selectedAmount * discountPercent;
    console.log("final",finalAmount)
    setFinalAmount(discountAmount);

  }
  if(coupon ===""){
    setPromoCode("")
    setFinalAmount("")
    setdiscountedAmount("")
  }
  if(coupon !==""){
    setPromoCode(props?.promoCode?.id)
  }
  if(props?.promoCode?.coupon?.amount_off !== ""){
setdiscountedAmount(props?.promoCode?.coupon?.amount_off/100)
  }
  if(props.subscriptionSuccess === true){
    setSuccess(true)
  }
  
  }, [props?.promoCode?.coupon?.amount_off,props?.promoCode?.coupon?.percent_off,coupon,promoCode]);
  useEffect(()=>{
    props.productIds();

  },[])
  console.log("err", errorMessage);
  


  function formatDate(timestamp) {
    // Create a new Date object using the timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);

    // Array of month names
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Get the day, month, and year from the date object
    const day = date.getDate(); // Day of the month (1-31)
    const month = months[date.getMonth()]; // Month name (Jan, Feb, etc.)
    const year = date.getFullYear(); // Full year (e.g., 2024)

    // Get the day of the week (0-6, where 0 is Sunday, 1 is Monday, etc.)
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' });

    // Construct the formatted date string
    const formattedDate = `${dayOfWeek} ${month} ${day.toString().padStart(2, '0')} ${year}`;

    return formattedDate;
}

// Example usage:
const timestamp = 1713159351;
const formattedDate = formatDate(timestamp);
console.log(formattedDate); // Output: "Thu Feb 08 2024"


  const handleSubscription = async () => {
    setShowError(false)
    setErrorMessage("")
    let amount;
    switch (selectedPlan) {
      case "One Month Plan":
        amount = 15;
        break;
      case "Three Month Plan":
        amount = 42;
        break;
      case "Six Month Plan":
        amount = 81;
        break;
      case "Twelve Month Plan":
        amount = 157;
        break;
      default:
        return;
    }
    let durationInDays;
    switch (selectedPlan) {
      case "One Month Plan":
        durationInDays = 30;
        break;
      case "Three Month Plan":
        durationInDays = 90;
        break;
      case "Six Month Plan":
        durationInDays = 180;
        break;
      case "Twelve Month Plan":
        durationInDays = 360;
        break;
      default:
        break;
    }
    setIsSubscribing(false)
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + durationInDays);
    setDurationInDays(durationInDays);
    setEndDate(endDate.toISOString());
    try {
      if (!stripe || !elements) {
        return;
      }
      setPaymentLoading(true);

      let paymentMethod;
      try {
        setIsSubscribing(true)

        setShowErrorSnackbar(false);
        // Create Payment Method
        paymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement("card"),
          billing_details: {
            name: name,
            email: email,
            phone: "+1 555-555-5555",
            address: {
              city: "NY",
              country: "AU",
              line1: "123 abc",
              line2: "abc",
              postal_code: "10001",
            },
          },
        });
        if (paymentMethod?.error) {
          setIsSubscribing(false)
          setErrorMessage(paymentMethod.error.message);
          setShowErrorSnackbar(true);
          setPaymentLoading(false); // Stop loading state
          return; // Stop further execution
        }
      } catch (error) {
        setIsSubscribing(false)
        setErrorMessage(error);
        setShowErrorSnackbar(true);
        setPaymentLoading(false); // Stop loading state
        return; // Stop further execution
      }

      // If paymentMethod is truthy, i.e., payment method creation was successful
      if (paymentMethod?.paymentMethod?.id) {
        try {
          // Create Subscription
          setShowErrorSnackbar(false);
          setIsSubscribing(true)

          const { data } = await createSubscription({
            variables: {
              priceId: priceID,
              paymentMethod: paymentMethod?.paymentMethod?.id,
              ...(promoCode && { promoCode }),   
              // name:selectedPlan,
              // durationInDays:durationInDays.toString() 
                   },
          });
          console.log("data", data);
          // Check if client secret is available
          if (data?.createSubscriptionInStripe?.clientSecret) {
            try {
              // Confirm Card Payment
              setShowErrorSnackbar(false);
              setIsSubscribing(true)

              const confirm = await stripe.confirmCardPayment(
                data?.createSubscriptionInStripe?.clientSecret
              );
              if (confirm.paymentIntent) {
                setErrorMessage(null);
                setShowError(true)

                const start =
                  data?.createSubscriptionInStripe?.current_period_start;
                const end =
                  data?.createSubscriptionInStripe?.current_period_end;
                const date = new Date(start * 1000);
                const edat = new Date(end * 1000);
                // const sDate = date.toLocaleDateString();
                // const edate = edat.toLocaleDateString();
                const sDate=formatDate(start)
                const edate=formatDate(end)
                // Call createSubscriptionDetails with confirm card payment details
                props.subscriptionDetails({
                  name: selectedPlan,
                  durationInDays: durationInDays,
                  startDate: sDate,
                  endDate: edate,
                  subscriptionID:
                    data?.createSubscriptionInStripe?.subscriptionID,
                  amount: confirm?.paymentIntent?.amount,
                  isFreeTrial: false,
                  currency: data?.createSubscriptionInStripe?.currency,
                  invoiceNumber:
                    data?.createSubscriptionInStripe?.invoiceNumber,
                  quantity: data?.createSubscriptionInStripe?.quantity,
                  invoiceCreated:
                    data?.createSubscriptionInStripe?.invoiceCreated,
                  trial_start: "",
                  trial_end: "",
                  latestInvoiceAmountDue:
                    data?.createSubscriptionInStripe?.latestInvoiceAmountDue,
                  latestInvoiceAmountPaid:
                    data?.createSubscriptionInStripe?.latestInvoiceAmountPaid,
                  paymentId: confirm?.paymentIntent?.id,
                });
                if(props.subscriptionSuccess){
                  setShowSnackbar(true);
                  setIsSubscribing(false)

                  setSuccess(true)
                }
                if(props.subscriptionFailure){
                  // setErrorMessage(props?.error);
                                  setShowError(true)

                  setShowErrorSnackbar(true);
                  setIsSubscribing(false)

                }
                
              } else {
                setIsSubscribing(false)
                console.log("error",confirm.error.message)

                setErrorMessage(confirm.error.message);
                setShowErrorSnackbar(true);
              }
            } catch (error) {
              console.log("error1",error)
              setErrorMessage(error);
              setIsSubscribing(false)

              setShowErrorSnackbar(true);
            }
          } else {

            const start =
              data?.createSubscriptionInStripe?.current_period_start;
            const end = data?.createSubscriptionInStripe?.current_period_end;
            const date = new Date(start * 1000);
            const edat = new Date(end * 1000);
            const sDate=formatDate(start)
            const edate=formatDate(end)
            // const sDate = date.toLocaleDateString();
            // const edate = edat.toLocaleDateString();
            // If no client secret, call createSubscriptionDetails directly
            setShowError(true)

            props.subscriptionDetails({
              name: selectedPlan,
              durationInDays:  durationInDays,
              startDate: sDate,
              endDate: edate,
              subscriptionID: data?.createSubscriptionInStripe?.subscriptionID,
              amount: "",
              isFreeTrial: false,
              currency: data?.createSubscriptionInStripe?.currency,
              invoiceNumber: data?.createSubscriptionInStripe?.invoiceNumber,
              quantity: data?.createSubscriptionInStripe?.quantity,
              invoiceCreated: data?.createSubscriptionInStripe?.invoiceCreated,
              trial_start: data?.createSubscriptionInStripe?.trial_start,
              trial_end: data?.createSubscriptionInStripe?.trial_end,
              latestInvoiceAmountDue:
                data?.createSubscriptionInStripe?.latestInvoiceAmountDue,
              latestInvoiceAmountPaid:
                data?.createSubscriptionInStripe?.latestInvoiceAmountPaid,
              paymentId: "",
            })
            if(props.subscriptionSuccess){
              setSuccess(true)
                setShowSnackbar(true)
                setIsSubscribing(false)

            }
             if(props.subscriptionFailure){
              setShowErrorSnackbar(true); 
                          setShowError(true)

              setIsSubscribing(false)

            }
            
          }
        } catch (error) {
          setShowErrorSnackbar(true);
          console.log("errror", error.message);
          setErrorMessage(error.message);
          setIsSubscribing(false)

          // if (error?.message === "Error: Maker has already subscribed to a plan") {
          //   // Navigate to the '/home' route
          //   navigate("/home");
          // }
        }
      }
    } catch (err) {
      if (err && err.message) {
        setErrorMessage(err.message);
        setShowErrorSnackbar(true);
        setIsSubscribing(false)

      } else {
      }
    } finally {
      setIsSubscribing(false)

      setPaymentLoading(false); // Stop loading state in case of success or failure
    }
  };
  console.log("emees",errorMessage)
  const handleInputChange = (event) => {
    // Update the couponCode state with the input field value
    setCoupon(event.target.value);
   
  };
  const freetrail = async () => {

    var currentDate = new Date();
    var currentTimeInMilliseconds = currentDate.getTime();

    // Convert milliseconds to seconds (Unix timestamp is in seconds)
    var currentUnixTimestamp = Math.floor(currentTimeInMilliseconds / 1000);

    // Calculate one month from now (approximately 30 days)
    var thirtyDaysInSeconds =  30 * 24 * 60 * 60;
        var endTimestamp = currentUnixTimestamp + thirtyDaysInSeconds;
console.log("cur",currentUnixTimestamp)
var startDate = new Date(currentUnixTimestamp * 1000);
var endDate = new Date(endTimestamp * 1000);

// Calculate duration in days
var durationInMilliseconds = endDate.getTime() - startDate.getTime();
var durationInDays = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));
    
    const subscriptionDetails = await new Promise((resolve) => {
      resolve({
        name:"Free Trial",
        durationInDays: durationInDays,
        startDate: formatDate(currentUnixTimestamp),
        endDate:formatDate(endTimestamp),
        subscriptionID: "",
        amount: "",
        isFreeTrial: true,
        currency: "",
        invoiceNumber: "",
        quantity: "",
        invoiceCreated: "",
        trial_start: "",
        trial_end: "",
        latestInvoiceAmountDue: "",
        latestInvoiceAmountPaid: "",
        paymentId: "",
      });
    });
  
    // Pass subscription details to props.subscriptionDetails
    props.subscriptionDetails(subscriptionDetails);
  };
  
  // const discounted=props?.promoCode?.coupon?.amount_off/100
  const percent=props?.promoCode?.coupon?.percent_off
  
  console.log("props",props)
  console.log("props.subscriptionSuccess:", props.subscriptionSuccess);
  console.log("trail",props.trailSuccess && trail)
  console.log("trailfail",props.trailFailure && trail)
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" 
    });
    if (!("scrollBehavior" in document.documentElement.style)) {
  }
  };


  return (
    <>
     <DisableZoom/>
       <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1 ,maximum-scale=1.0," />
      </Helmet>
    <div className="container-fluid subscription-conatiner">
     
      <div className="row">
        <div className="col-12">
          <TopNavbar {...props} />
        </div>
        <div className="col-12 sus-head">
          <span className="sus-makers">MAKERS </span>
          <span className="sus-portal">PORTAL</span>
        </div>
        <div className=" col-12 sus-content">
          <h1 className="content-header">
            Almost there!
            <br />
          </h1>
          <span className="content-sub">
            Please select your plan to unlock your portal access
          </span>
        </div>
      </div>
      <div className="row suscard-row ">
      <div className="col-md-1 col-sm-1 col-lg-1 suscard-class"></div>

        <div className="col-md-2 col-sm-2 col-lg-2 suscard-class">
        <div className="trail-card">
            <div className="trail-h">
              <h1 className="trail-header">Limited Time Only<br/>
One Month Free Subscription</h1>
            </div>
            <div className="value_div">
              {" "}
              <span className="trial-value"> One time use only - No Payment Information Required</span>
            </div>

            <div className="get-button">
              <button
                className="trial-btn"
                onClick={() => {
                  setSelectedAmount("");
                  freetrail();
                  setShowSnackbar(false);
                  setShowError(false);
                  setTrail(true)
                }}
              >
                Get started
              </button>
            </div>
          </div></div>
        <div className="col-md-2 col-sm-2 col-lg-2 suscard-class ">
          <div className="sub-card">
            <div className="month-header">
              <h1 className="suscard-header">1 month</h1>
            </div>
            <div className="value_div">
              {" "}
              <span className="sus-value">AU$16</span>
            </div>

            <div className="get-button">
              <button
                className="get"
                onClick={() => {
                  setPayment(true);
                  setSelectedPlan("One Month Plan");
                  setPriceID("price_1PGblISIkmktzAYFf0gfEp1I");
                  setSelectedAmount("16");
                  setFinalAmount("")
                  setCoupon("")
                  setPromoSnackbar(false)
                  setShowErrorSnackbar(false)
                  setShowSnackbar(false)
                  setShowError(false)


                }}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-2  col-sm-2 col-lg-2 suscard-class ">
          <div className="sub-card">
            <div className="month-header">
              {" "}
              <h1 className="suscard-header">3 months</h1>
            </div>
            <div className="value_div">
              {" "}
              <span className="sus-value">AU$45</span>
            </div>
            <div className="get-button">
              <button
                className="get"
                onClick={() => {
                  setPayment(true);
                  setSelectedPlan("Three Month Plan");
                  setSelectedAmount("45");
                  setPriceID("price_1PGbtjSIkmktzAYFkmzYEwkE");
                  setFinalAmount("")
                  setCoupon("")
                  setPromoSnackbar(false)
                  setShowErrorSnackbar(false)
                  setShowSnackbar(false)
                  setShowError(false)

                }}
              >
                Get started
              </button>

              {paymentError && <div>{paymentError}</div>}
            </div>
          </div>
        </div>
        <div className="col-md-2  col-sm-2 col-lg-2 suscard-class ">
          <div className="sub-card">
            <div className="month-header">
              {" "}
              <h1 className="suscard-header">6 months</h1>
            </div>
            <div className="value_div">
              {" "}
              <span className="sus-value">AU$86</span>
            </div>

            <div className="get-button">
              <button
                className="get"
                onClick={() => {
                  setPayment(true);
                  setSelectedPlan("Six Month Plan");
                  setSelectedAmount("86");
                  setPriceID("price_1PGc5TSIkmktzAYFJ1rWutw6");
                  setFinalAmount("")
                  setCoupon("")
                  setPromoSnackbar(false)
                  setShowErrorSnackbar(false)
                  setShowSnackbar(false)
                  setShowError(false)
                  handleButtonClick()

                }}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-2  col-sm-2 col-lg-2 suscard-class ">
          <div className="sub-card">
            <div className="month-header">
              {" "}
              <h1 className="suscard-header">12 months</h1>
            </div>
            <div className="value_div">
              {" "}
              <span className="sus-value">AU$167</span>
            </div>

            <div className="get-button">
              <button
                className="get"
                onClick={() => {
                  setPayment(true);
                  setSelectedPlan("Twelve Month Plan");
                  setSelectedAmount("167");
                  setPriceID("price_1PGc7VSIkmktzAYFtdNrJGJW");
                  setFinalAmount("")
                  setCoupon("")
                  setPromoSnackbar(false)
                  setShowErrorSnackbar(false)
                  setShowSnackbar(false)
                  setShowError(false);
handleButtonClick()
                }}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-1 col-sm-1 col-lg-1 suscard-class"></div>

      </div>
      <div>
        <Footer {...props} />
      </div>

      {payment && (
        <div className="payment_popup" {...props}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xl-6 col-lg-6 planColumn">
                <div className="planheader">
                <p>Subscribe to Makers Portal</p>
                <h1 >AU${selectedAmount}</h1>
                </div>
                <div className="planprice">
                <div className="col-6 ">
                  <p className="card-label">{selectedPlan}</p>
                  </div>
                  <div className="col-6 price">
                    <span className="poptext">AU${selectedAmount}</span>
                  </div>
                  </div>
                  <div className="planprice">
                <div className="col-6 ">
                  <p className="card-label">Subtotal</p>
                  </div>
                  <div className="col-6 price">
                    <span className="poptext">AU${selectedAmount}</span>
                  </div>
                  </div>
                <div className="coupon">
                  <label htmlFor="coupon" className="coupon-label">
                    Add Promotion Code<span className="poptext">(optional)</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      name="coupon"
                      value={coupon}
                      className="field-coupon"
                      placeholder="Add Promotion Code"
                      onChange={handleInputChange}
                    />
                    <div  className="coupon-div">
            <button type="button"  className="coupon-btn" onClick={handleCoupon}  >
              Apply </button>
              </div>
                  </div>
                </div>
                <div className="planprice">
                <div className="col-6 ">
                  <p className="card-label">Discount Applied</p>
                  </div>
                  
                  <div className="col-6 price">
                    <span className="poptext">{discounted !== "" ? (
    discounted >= 0 ? `-AU$${discounted}` :`AU$0`
  ) : (   `AU$0` 
  )}
</span>
                  </div>
                  </div>
                <div className="planprice">
                <div className="col-6 ">
                  <p className="card-label">Amount due</p>
                  </div>
                  <div className="col-6 price">
                    <span className="poptext">AU${finalAmount ? finalAmount :selectedAmount}</span>
                  </div>
                  </div>
              </div>
              <div className="col-md-6 col-xl-6 col-lg-6 cardColumn">
                <div>
                  <h1 className="pay-header">Enter your card details...</h1>
                  <div className="planprice">
                <div className="col-6 ">
                  <p className="card-label">Selected Plan</p>
                  </div>
                  <div className="col-6 price">
                    <span className="poptext"> {selectedPlan}</span>
                  </div>
                  </div>
                  <div className="planprice">
                <div className="col-6 ">
                  <p className="card-label">Amount to pay</p>
                  </div>
                  <div className="col-6 price">
                  <span className="poptext">AU${finalAmount ? finalAmount : selectedAmount}</span>
                  </div>
                  </div>
                </div>
                <div className="cardElement">
                   <label htmlFor="coupon" className="card-label">
                    Card information
                  </label>
                  <div className="cardborder">
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                     
                    },
                  }}
                />
                </div>
                </div>
                <div className="button-div">
           
            {props.subscriptionSuccess ===true ? (
              <button className="paid-btn" disabled>
                Success
              </button>
            ) : (
              <button
                className="pay-btn"
                onClick={() => {
                  handleSubscription();
                }}
                disabled={isSubscribing}
              >
                {isSubscribing ? 'Subscribing...' : 'Subscribe'}
              </button>
            )}{" "}
             <button onClick={handleCancel} className="cancel-btn">
              Cancel
            </button>
          </div>
              </div>
            </div>
          </div>
          {paymentLoading && <Loader />}
      {showSnackbar && (
        <Snackbar message="Subscribed Successfully" type="success" />
      )}
      {showErrorSnackbar && <Snackbar message={errorMessage} type="errors" />}
      { props.subscriptionSuccess ? (
        <Snackbar message="Subscribed Successfully" type="success" />
      ): ""}

      {}

      {(props.subscriptionFailure ===true && showError)&& (<Snackbar message={props?.error} type="errors" />)}
      {showPromoSnackbar && props.promosuccess === true &&(
          <Snackbar message="Promotion code applied" type="success" />
        )}
       
        {showPromoSnackbar &&  props.promofailure === true&&(
          <Snackbar message="No such promotion code" type="errors" />
        )}
        
        </div>
      )}
     { (props.trailSuccess && trail) ? (
        <Snackbar message="Subscribed Successfully" type="success" />
      ): ""}
      {}
      {props.trailFailure && trail && <Snackbar message={props?.trailerror} type="errors" /> }
      {/* {showErrorSnackbar && <Snackbar message={errorMessage} type="errors" />} */}

    </div>
    </>
  );
};

const SubscriptionWithStripe = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Subscription {...props} />
    </Elements>
  );
};

export default SubscriptionWithStripe;
