import React, { useState, useEffect } from "react";
import "./TopNavbar.css";
import { useLocation, Link } from "react-router-dom";
import profile3 from "../../icons/profile3.png";
import avatar from "../../Images/avatar.png";
import Subscription from "../../icons/Subscription.js";
import reset from "../../icons/reset.svg"
import Logout from "../../icons/logout.svg";
import { useNavigate } from "react-router-dom";
import userguide from "../../Images/userguide.pdf"

const TopNavbar = (props) => {
  const [popupVisible, setPopupVisible] = useState(false);
  let navigate = useNavigate();

  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  const handleClick= ()=>{
    navigate("/home/changepassword");
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupVisible &&
        !event.target.closest(".popup") &&
        !event.target.closest(".account-icon-div")
      ) {
        setPopupVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupVisible]);
  const { pathname } = location; // Get the current path

  // Check if the path is "/home"
  const isHomePath = pathname.startsWith("/home");

  


  return (
    
    <div className="top-navbar">
      {/* <span className='breadcrumb-div'>
      <Link style={{ color: 'rgba(28, 28, 28, 0.40)', fontSize: '18px', fontFamily: 'Inter', fontWeight: '400', textDecoration:'none',cursor:'pointer'}} to="/home" >Dashboard</Link>
      {pathSegments.length > 1 && '/'}
        {capitalizeFirstLetter(pathSegments[pathSegments.length - 1])}
      
      </span> */}
    
      <span className="account-icon-div">
      <div className="userguide">
      <a href={userguide} download  >Download User Guide</a>

      </div>
        <img
          src={profile3}
          alt="avatarprofile"
          style={{ width: "55px" }}
          className="avatar"
          onClick={togglePopup}
        />
        {popupVisible && (
          <div className="popup">
            <button className="close-button" onClick={togglePopup}>
              &times;
            </button>
            <div className="user-info">
              <span
                style={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {name}
              </span>
              <p>{email}</p>
            </div>
            {isHomePath ? (
            <div  className="nav-sub" onClick={()=>handleClick()}>
              {/* <img src={reset} style={{ width: "2%" }} /> */}
              <span
              className="change"
                style={{
                
                }}
              >
                Change Password
              </span>
            </div>):("")}
            
            <span
              style={{
                color: "#FF7C5F",
                fontSize: 16,
                fontFamily: "Inter",
                fontWeight: "700",
                wordWrap: "break-word",
                cursor: "pointer",
              }}
              className="logout"
              onClick={handleLogout}
            >
              Logout
            </span>
            </div>
         
        )}
      </span>
    </div>
  );
};

export default TopNavbar;
