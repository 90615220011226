import React from "react";
import SuppliesForm from "./SuppliesForm";

const AddSupplies = (props) => {  
  return (
    <SuppliesForm
    title="New Supply Details"
    type="submit"
    text="Create Supply"
    subtitle="New Supplies"
    {...props}
  />
  );
};

export default AddSupplies;
