import React, {useEffect} from "react";
import ClientForm from "./ClientForm";


const EditClient = (props) => {
  useEffect(() => {
    props.listallClients({offset: 0,
      first: 10});
  }, []); 
  return <ClientForm title="Edit Client" text="Save Changes" {...props} />;
};

export default EditClient;
