import React, { useEffect, useState } from "react";

import BackButton from "../Buttons/BackButton";

import { useFormik } from "formik";
import * as Yup from "yup";
import "./suppliersform.css";
import { useParams } from "react-router-dom";
import { useLocation ,useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import Snackbar from "../Snackbar/Alert";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Loader from "../Buttons/Loader"

const SuppliersForm = (props) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    props.categoriesListing();
  }, [props.categoriesListing]);
  const location = useLocation();
  let navigate = useNavigate();

  const { supplier } = location.state || {};

  useEffect(() => {
    if (supplier) {
    }
  }, [supplier]);
  const [categoryIds, setCategoryIds] = useState(() => {
    if (Array.isArray(supplier?.categories)) {
      return supplier.categories.map(category => category.id);
    } else {
      return [supplier?.categories?.id] || [];
    }
  });
  const availableCategories = props?.categoryData?.categoriesListing || [];

  
  useEffect(() => {
    if (categoryIds.length > 0) {
      const existingCategories = availableCategories
        .filter((category) => categoryIds.includes(category.id))
        .map((category) => ({ label: category.value, value: category.id }));
  
      const newCategories = selectedCategories.filter(
        (category) => !categoryIds.includes(category.value)
      );
  
      const preSelectedCategories = [...existingCategories, ...newCategories];
  
      setSelectedCategories(preSelectedCategories);
    }
  }, [categoryIds,availableCategories ]);
  
  const handleCategoryChange = (selectedValues) => {
    const selectedCategoryIds = selectedValues.map((category) => category.value);
  
    setSelectedCategories(selectedValues);
    formik.setFieldValue("categories", selectedCategoryIds);
  };
  const handleRemoveCategory = (categoryToRemove) => {
    const selectedCategoryIds = categoryToRemove.map((category) => category.value);

    setSelectedCategories(categoryToRemove);
    setCategoryIds(selectedCategoryIds)
    formik.setFieldValue("categories", selectedCategoryIds);
  };
  const initialValues = supplier
    ? {
        Name: supplier.name,
        Email: supplier.email,
        countryCode: supplier.countryCode,
        MobileNumber: supplier.mobile,
        Address: supplier.address,
        categories: categoryIds,
        Description:supplier.description
        
      }
    : {
        Name: "",
        Email: "",
        countryCode: "",
        MobileNumber: "",
        Address: "",
        categories: [],
        Description: ""

      };

  const [formState, setFormState] = useState(initialValues);

  const [showSnackbar, setShowSnackbar] = useState(false);
  // const handlePhoneInputChange = (value, country) => {
  

  //     formik.setFieldValue("MobileNumber", country.inputValue);
  //     formik.setFieldValue("countryCode", country.country.dialCode);
     
    
  // };
  const handlePhoneInputChange = (value, country) => {
    console.log("country",country.inputValue.length > 4)
    if(country.inputValue.length > 4){
      formik.setFieldValue("MobileNumber", country.inputValue);
      formik.setFieldValue("countryCode", country.country.dialCode);
    }
    else{
      formik.setFieldValue("MobileNumber", "");
      formik.setFieldValue("countryCode", "");
    }
  
  };

  const inputFields = [
    { type: "text", label: "Name", placeholder: "Name", name: "Name" },
    { type: "email", label: "Email", placeholder: "Email", name: "Email" },
    {
      type: "text",
      label: "Mobile Number",
      placeholder: "Mobile Number",
      name: "MobileNumber",
    },
    { type: "text", label: "Address", placeholder: "Address", name: "Address" },
    {
      type: "category",
      label: "Categories",
      placeholder: "Categories",
      name: "categories",
    },
    {
      type: "textArea",
      label: "Description",
      placeholder: "Write your description here",
      name: "Description",
    },
  ];
  const onkey=(log)=>{
    console.log("log",log)
  }
  const removeNonNumeric = (str) => str.replace(/[^0-9]/g, '');

  // Assuming `val` contains the entire phone number including country code
  const validatePhoneNumber = (val) => {
    const numericValue = removeNonNumeric(val);
    // Check if numericValue has at least 6 digits
    return numericValue.length >= 6;
  };


  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is required *")    
    // .matches(/^[aA-zZ\s]+$/, "Please use only letters ")
    .max(36, "Name length must be less than or equal to 36 characters long")
    ,
    Email: Yup.string()
    .transform((value) => value.toLowerCase())
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email Address must be valid"
    )
    .required("Email is required *"),
    MobileNumber: Yup.string(),
    // .required("Mobile Number is required *")
    // .test('len', 'Phone number must be at least 6 digits', validatePhoneNumber),


    // .matches(/^\+?[1-9]\d{7,14}$/, 'Please Enter a valid phonenumber'),
    Address: Yup.string().required("Address is required *")
    .min(5,"Address length must be at least 5 characters long")
    .max(100, "Address length must be less than or equal to 100 characters long")
    ,
   categories: Yup.array().min(1, "At least one category must be selected *"),
   Description: Yup.string().required("Description is required *")
   .max(300, "Description length must be less than or equal to 300 characters long"),
  });

  const formik = useFormik({
    initialValues: formState,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setShowLoader(true)
      const lowercaseEmail = values.Email.toLowerCase();
    const name=values.Name.trim()
    const description=values.Description.trim()
      const address=values.Address.trim()
      const updatedValues = { ...values, Email: lowercaseEmail ,Name:name,Description:description,Address:address};
      if (props.text === "Add supplier") {
        props.createSupplier(updatedValues);
        setShowSnackbar(true)
        

      } else if (props.text === "Edit supplier") {
        props.updateSupplier(updatedValues, { supplierId: supplier.id });
        setShowSnackbar(true)
      
      }
    },
  });
  useEffect(() => {
    if (props.addSupplierSuccess===true) {
      setShowSnackbar(true);
      setShowLoader(false)
      setTimeout(() => {
        navigate("/home/suppliers");
      }, 1000); 
    }
    else if (props.addSupplierFailure===true){
      setShowLoader(false)

    }
  }, [props.addSupplierSuccess, navigate]);

  useEffect(() => {
    if (props.editSupplierSuccess) {
      setShowSnackbar(true);
      setShowLoader(false)

      setTimeout(() => {
        navigate("/home/suppliers");
      }, 1000);
    }
    else if(props.editSupplierFailure === true){
      setShowLoader(false)

    }
  }, [props.editSupplierSuccess, navigate]);
  console.log("phome",PhoneInput)
  return (
    <div className="container suppliersform">
      <div className="row">
        <div className="col-1 backbutton">
          <BackButton {...props} />
        </div>
        <div className="col-12 header">
          <span
            style={{
              color: "#4005A0",
              fontSize: "24px",
              fontFamily: "Inter",
              fontWeight: "600",
            }}
          >
            {props.title}
          </span>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="row textfield">
          {inputFields.map((field, index) => (
            <div key={index} className="col-lg-6 mb-4">
              <label htmlFor={field.name} className="form-labelss">
                {field.label}
              </label>
              {field.type === "textArea" ? ( <textarea
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
                required
                
                className={`field-area ${
                  formik.touched[field.name] && formik.errors[field.name]
                    ? "error"
                    : ""
                }`}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              />):field.type === "category" ? (
                <div
                // className="categoryField"
                  
                >
                  <Multiselect
                  options={availableCategories.map((category) => ({
                    label: category.value,
                    value: category.id,
                  }))}
                  onSelect={handleCategoryChange}
                  onRemove={handleRemoveCategory}
                  displayValue="label"
                  selectedValues={selectedCategories}
                  avoidHighlightFirstOption
                  />
                 
                </div>
              ) : field.name === "MobileNumber" ? (
                <PhoneInput
                defaultCountry="us"

                  value={formik.values[field.name]}
                  onChange={(value, countryCode) =>
                    handlePhoneInputChange(value, countryCode)
                  }
                  style={{ width: "100%" }}
                  name={field.name}
                  placeholder={field.placeholder}
                  onKeyPress={onkey}
                  avoidHighlightFirstOption
autoFormat={true}
                />
              ) : (
                <input
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  className={`field ${
                    formik.touched[field.name] && formik.errors[field.name]
                      ? "error"
                      : ""
                  }`}
                  placeholder={field.placeholder}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                />
              )}

              {formik.touched[field.name] && formik.errors[field.name] && (
                <div className="error-message" style={{ color: "red" }}>
                  {formik.errors[field.name]}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="row justify-content-end add">
          <div className="col-3 mb-1 text-end">
            <button type="submit" className="addbuttons">
              {props.text}
            </button>
          </div>
        </div>
      </form>
      <div>  {showSnackbar && props.addSupplierSuccess === true && (
          <Snackbar message="Supplier Added Successfully" type="success" />
        )}
        {showSnackbar && props.addSupplierFailure === true && (
          <Snackbar message={props.errors} type="errors" />
        )}
        {showSnackbar && props.editSupplierSuccess === true && (
          <Snackbar message="Supplier Edited Successfully" type="success" />
        )}
        {showSnackbar && props.editSupplierFailure === true && (
          <Snackbar message={props.errors} type="errors" />
        )}
     {showLoader && props.loading=== true && (
          <Loader />
      )}
      </div>
    </div>
  );
};

export default SuppliersForm;
