import React from "react";
import SuppliesForm from "./SuppliesForm";

const EditSupplies = (props) => {  
  return (
    <SuppliesForm
    title="Edit Supply Details"
    text="Save Changes"
    subtitle="Edit Supplies"
    {...props}
  />
  );
};

export default EditSupplies;
