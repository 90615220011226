import React, { useState, useEffect } from "react";
import Threedots from "../../icons/threedots.svg";
import Tick from "../../icons/tickmark.svg";
import greentick from "../../icons/greentick.svg";
import edit from "../../icons/edit.svg";
import deleteIcon from "../../icons/deleteIcon.svg";
import exportIcon from "../../icons/export.svg";
import { FaEye } from "react-icons/fa";
import DeletePopup from "../Buttons/DeletePopup";
import inprogress from "../../icons/inprogress.png";
import open from "../../icons/open.png"
import "./DynamicTable.css";

const DynamicTable = (props) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);

  const handleDeleteClick = (rowIndex) => {
    setDeleteRowIndex(rowIndex);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    if (deleteRowIndex !== null) {
      props.onDeleteClick(props.ids[deleteRowIndex]);
      setSelectedRowIndex(null);
      setShowDeleteConfirmation(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteRowIndex(null);
    setShowDeleteConfirmation(false);
  };

  const handleInfoClick = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close infocard if clicked outside of it
      if (selectedRowIndex !== null && !event.target.closest(".infocard")) {
        setSelectedRowIndex(null);
      }
    };
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedRowIndex]);

  return (
    <div>
      <table className="dynamic-table">
        <thead>
          <tr className="heading-row">
            {props.headings.map(
              (heading, index) =>
                (props.showStatus || index !== heading.length - 1) && (
                  <th key={index}>{heading}</th>
                )
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.rows.map((rowData, rowIndex) => (
            <tr
              key={props.ids[rowIndex]}
              className={`data-row ${
                Array.isArray(props.highlightedOrders) &&
                props.highlightedOrders.includes(props.ids[rowIndex])
                  ? "highlighted-row"
                  : ""
              }`}
            >
              {rowData.map((data, dataIndex) => (
                <React.Fragment key={dataIndex}>
                  <td>
                    {props.showStatus &&
                    props.headings[dataIndex] === "Status" ? (
                      <div
                        className="done-status"
                        style={{
                          backgroundColor:
                            data === "Active"
                              ? "rgb(198 213 194)"
                              : data === "In progress"
                              ? "rgb(156 167 240)"
                              : "#CCFFBE",
                        }}
                      >
                        {data === "Active" && (
                          <img
                            src={open}
                            style={{
                              width: "17px",
                              marginRight: "9px",
                              marginLeft: "2px",
                            }}
                          />
                        )}
                         {data === "Done" && (
                          <img
                            src={Tick}
                            style={{
                              width: "17px",
                              marginRight: "9px",
                              marginLeft: "2px",
                            }}
                          />
                        )}
                        {data === "In progress" && (
                          <img
                            src={inprogress}
                            style={{
                              width: "17px",
                              marginRight: "9px",
                              marginLeft: "2px",
                            }}
                          />
                        )}
                        {data}
                      </div>
                    ) : (
                      <div
                        style={{
                          color:
                            Array.isArray(props.highlightedOrders) &&
                            props.highlightedOrders.includes(
                              props.ids[rowIndex]
                            )
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {data}
                      </div>
                    )}
                  </td>
                </React.Fragment>
              ))}
                <td>
                {props.showInfoButton && (

                  <div className="popup-container">
                    <img
                      src={Threedots}
                      alt="Three Dots"
                      style={{ width: "24px", height: "24px" }}
                      onClick={() => handleInfoClick(rowIndex)}
                    />
                    {selectedRowIndex === rowIndex && (
                      <div className="infocard">
                        {props.onEditClick && (
                          <div
                            onClick={() =>
                              props.onEditClick(props.ids[rowIndex])
                            }
                            className="infobuttons"
                            style={{ alignItems: "center", cursor: "pointer" }}
                          >
                            <img src={edit} alt="edit" />
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "black",
                                fontSize: "16px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                              }}
                              className="pop-btn"
                            >
                              Edit
                            </span>
                          </div>
                        )}
                        {props.onDoneClick && (
                          <div
                            onClick={() => {
                              props.onDoneClick(props.ids[rowIndex]);
                              setSelectedRowIndex(null);
                            }}
                            className="infobuttons"
                            style={{ alignItems: "center", marginTop: "13px" }}
                          >
                            <img
                              src={greentick}
                              alt="Tick"
                              className="tick-icon"
                            />
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "darkorchid",
                                fontSize: "16px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                              }}
                              className="pop-btn"
                            >
                               Done
                            </span>
                          </div>
                        )}
                        {props.onProgressClick && (
                          <div
                            onClick={() => {
                              props.onProgressClick(props.ids[rowIndex]);
                              setSelectedRowIndex(null);
                            }}
                            className="infobuttons"
                            style={{ alignItems: "center", marginTop: "13px" }}
                          >
                            <img
                              src={inprogress}
                              alt="Tick"
                              className="in-icon"
                            />
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "darkorchid",
                                fontSize: "16px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                              }}
                              className="pop-btn"
                            >
                              In progress
                            </span>
                          </div>
                        )}
                        {props.onOpenClick && (
                          <div
                            onClick={() => {
                              props.onOpenClick(props.ids[rowIndex]);
                              setSelectedRowIndex(null);
                            }}
                            className="infobuttons"
                            style={{ alignItems: "center", marginTop: "13px" }}
                          >
                            <img
                              src={open}
                              alt="Tick"
                              className="in-icon"
                            />
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "darkorchid",
                                fontSize: "16px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                              }}
                              className="pop-btn"
                            >
                              Active
                            </span>
                          </div>
                        )}
                        {props.onViewOrder && (
                          <div
                            onClick={() => {
                              props.onViewOrder(props.ids[rowIndex]);
                              setSelectedRowIndex(null);
                            }}
                            className="infobuttons"
                            style={{ alignItems: "center", marginTop: "13px" }}
                          >
                            <FaEye
                              style={{
                                color: "darkorchid",
                                width: "22px",
                                height: "16px",
                              }}
                            />
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "darkorchid",
                                fontSize: "16px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                              }}
                              className="pop-btn"
                            >
                              View Order
                            </span>
                          </div>
                        )}
                        {props.onDeleteClick && (
                          <div
                            // onClick={() => {

                            //   setSelectedRowIndex(null);
                            // }}
                            onClick={() => handleDeleteClick(rowIndex)}
                            className="infobuttons"
                            style={{ alignItems: "center", marginTop: "13px" }}
                          >
                            <img src={deleteIcon} alt="delete" />
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "#FF7C5F",
                                fontSize: "16px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                              }}
                              className="pop-btn"
                            >
                              Delete
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  )}
                </td>
            
            </tr>
          ))}
        </tbody>
      </table>
      {showDeleteConfirmation && (
        <DeletePopup
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          text="delete"
          className="delete-card"
          {...props}
        />
      )}
    </div>
  );
};

export default DynamicTable;
