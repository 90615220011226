import React, { useState, useEffect } from 'react';
import './alert.css';

const Snackbar = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000); 
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`snackbar ${props.type} ${isVisible ? 'show' : 'hide'}`}>
      {props.message}
    </div>
  );
};

export default Snackbar;
