import { createSelector } from "reselect";

const getSignInReducer = (state) => state.SignInReducer;
const getLogInReducer = (state) => state.LoginReducer;

const mergeSelectedReducers = (SignInReducer, LoginReducer) => ({
  ...SignInReducer,
  ...LoginReducer,
});

export const SelectState = createSelector(
  [getSignInReducer, getLogInReducer],
  mergeSelectedReducers
);
