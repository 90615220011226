import React, { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./contact.css";
import Snackbar from "../Snackbar/Alert";
import Loader from "../Buttons/Loader";

const Contact = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);


  const initialValues = {
    Name: "",
    Email: "",
    countryCode: "",
    MobileNumber: "",
    Address: "",
    message: "",
  };
 
  const handlePhoneInputChange = (value, country) => {
    const countryCode = country?.country?.dialCode;

    formik.setFieldValue("MobileNumber", country.inputValue);
    formik.setFieldValue("countryCode", countryCode);
    setPhoneNumber(value);
  };
  const inputFields = [
    { type: "text", label: "Name", placeholder: "Name", name: "Name" },
    { type: "text", label: "Email", placeholder: "Email", name: "Email" },
    {
      type: "text",
      label: "Mobile Number",
      placeholder: "Mobile Number",
      name: "MobileNumber",
    },
    { type: "text", label: "Address", placeholder: "Address", name: "Address" },
    {
      type: "textArea",
      label: "Message",
      placeholder: "write your message here..",
      name: "message",
    },
  ];
  const removeNonNumeric = (str) => str.replace(/[^0-9]/g, '');

// Assuming `val` contains the entire phone number including country code
const validatePhoneNumber = (val) => {
  const numericValue = removeNonNumeric(val);
  // Check if numericValue has at least 6 digits
  return numericValue.length >= 6;
};


  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .required("Name is required *")
      // .matches(/^[aA-zZ\s]+$/, "Please use only letters ")
      .max(36, "name length must be less than or equal to 36 characters long"),

    Email: Yup.string()
      .transform((value) => value.toLowerCase())
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "Email address must be valid"
      )
      .required("Email is required *"),
    MobileNumber: Yup.string().required("Phone number is required *")
    .test('len', 'Phone number must be at least 6 digits *', validatePhoneNumber),
    // .min(6, "Mobile Number must be at least 6 characters *"),
    // .matches(/^\+?[1-9]\d{7,14}$/, 'Please Enter a valid phonenumber'),

    Address: Yup.string()
      .required("Address is required *")
      .min(5, "Address length must be at least 5 characters long")
      .max(
        100,
        "Address length must be less than or equal to 100 characters long"
      ),
    message: Yup.string()
      .required("Message is required *")
      .max(
        300,
        "Message length must be less than or equal to 300 characters long"
      ),
  });
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values) => {
     
      const lowercaseEmail = values.Email.toLowerCase();
      const name = values.Name.trim();
      const message = values.message.trim();
      const updatedValues = {
        ...values,
        Email: lowercaseEmail,
        Name: name,
        message: message,
      };
      props.contactUs(updatedValues);
      
        setShowSnackbar(true)
      
     
      setShowLoader(true);
      setShowErrorSnackbar(true)
    },
  });

 
  
  return (
    <div className="container">
      <form onSubmit={formik.handleSubmit}>
        <div className="row textfield1">
          {inputFields.map((field, index) => (
            <div key={index} className="col-lg-6 mb-4">
              <label htmlFor={field.name} className="form-labels1">
                {field.label}
              </label>
              {field.name === "MobileNumber" ? (
                <PhoneInput
                  defaultCountry="us"
                  value={formik.values[field.name]}
                  onChange={(value, countryCode) =>
                    handlePhoneInputChange(value, countryCode)
                  }
                  name={field.name}
                  placeholder={field.placeholder}
                  autoFormat={true}
                
                />
              ) : field.type === "textArea" ? (
                <textarea
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeholder}
                  required
                  className={`field-areas ${
                    formik.touched[field.name] && formik.errors[field.name]
                      ? "error"
                      : ""
                  }`}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                />
              ) : (
                <input
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  className={`fields ${
                    formik.touched[field.name] && formik.errors[field.name]
                      ? "error"
                      : ""
                  }`}
                  placeholder={field.placeholder}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                />
              )}

              {formik.touched[field.name] && formik.errors[field.name] && (
                <div className="error-message" style={{ color: "red" }}>
                  {formik.errors[field.name]}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="row justify-content-center adde">
          <div className="col-3 mb-1 text-end">
            <button type="submit" className="addformbutton" >
              Submit
            </button>
          </div>
        </div>
      </form>
      
      {showSnackbar&&(
        <Snackbar message="Thank you for submitting your enquiry" type="success"/>
      )}
       {props.contactusfailure===true&&showErrorSnackbar&&(
        <Snackbar message={props.error} type="errors"/>
      )}
      {props.loading===true&&showLoader&&(
        <Loader/>
      )}
    </div>
  );
};
export default Contact;
