export const LoginReducer = (
  state = {
    loading: false,
    data: null,
    error: null,
    loginFailure: false,
    loginSuccess: false,
    signInFailure:false,
    signInSuccess:false,
    subscriptionSuccess:false,
    subscriptionFailure:false,
    resetSuccess:false,
    resetFailure:false,
    makerData:null,
    LoginData:null,
    dashboardData:null,
    subscription:null,
    subscriptionData:null,
    stripepayment:"",
    stripeerror:"",
    promosuccess:false,
    promofailure:false,
    promoCode:null
  },
  action
) => {
  switch (action.type) {
    case "CREATING_MAKER_REQEST":
      return {
        ...state,
        loading: true,
        error: null,
        signInSuccess: false,
        signInFailure:false,
        loginSuccess:false,
        loginFailure:false

      };
    case "CREATING_MAKER_SUCCESS":
      return {
        ...state,
        loading: false,
        makerData: action.payload,
        error: null,
        signInSuccess: true,
        signInFailure:false,
        loginSuccess:false,
        loginFailure:false

      };
    case "CREATING_MAKER_FAILURE":
      return {
        ...state,
        loading: false,
        makerData: null,
        error: action.payload,
        signInFailure:true,
        signInSuccess:false,
        loginSuccess:false,
        loginFailure:false
      };
    case "LOGIN_REQEST":
      return {
        ...state,
        loading: true,
        data: null,
        signInSuccess: false,
        signInFailure:false,
        loginSuccess:false,
        loginFailure:false

      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        LoginData: action.payload,
        error: null,
        loading: false,

        loginSuccess: true,
        signInFailure:false,
        signInSuccess:false,
        loginFailure: false,

      };
    case "LOGIN_ACCESS_FAILED":
      return {
        ...state,
        error: action.payload,
        loading: false,

        loginSuccess: false,
        signInFailure:false,
        signInSuccess:false,
        loginFailure: true,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        loginFailure: true,
        loginSuccess: false,
        signInFailure:false,
        signInSuccess:false,

      };
      case "RESET_PASSWORD_REQEST":
        return{
          ...state,
          loading:true,
message:null,
errors:null,
resetSuccess:false,
resetFailure:false,
        }
        case "RESET_PASSWORD_SUCCESS":
        return{
         ...state,
         loading:false,
         message:action.payload,
         resetSuccess:true,
         resetFailure:false,
          
        }
        case "RESET_PASSWORD_FAILURE":
          return{
            ...state,
            loading:false,
            resetSuccess:false,
            resetFailure:true,
            errors:action.payload
          }
         case "DASHBOARD_REQUEST":
          return{
            ...state,
            loading:true,
            dashboardData:null
          }
          case "DASHBOARD_SUCCESS":
            return{
              ...state,
              loading:false,
              dashboardData:action.payload

            } 
            case "DASHBOARD_FAILURE":
            return{
              ...state,
              loading:false,

            }
            case "SUBSCRIPTION_REQEST":
              return{
                ...state,
                loading:true,

              }
              case "SUBSCRIPTION_SUCCESS":
              return{
                ...state,
                loading:false,
                subscription:action.payload
              }
              case "SUBSCRIPTION_FAILURE":
              return{
                ...state,
                loading:true,
                error:action.payload
              }
              case "CREATE_SUBSCRIPTION_REQUEST":
                return{
                  ...state,
                  loading:true,
                  error:null,
                  subscriptionSuccess:false,
                  subscriptionFailure:false,
                  trailSuccess:false,
                  trailFailure:false
                }
                case "CREATE_SUBSCRIPTION_SUCESS":
                  return{
                    ...state,
                    subscriptionData:action.payload,
                    subscriptionFailure:false,
                    subscriptionSuccess:true,
                    error:null,
                    trailSuccess:true,
                    trailFailure:false
                  } 
                   case "CREATE_SUBSCRIPTION_FAILURE":
                  return{
                    ...state,
                    error:action.payload,
                    trailerror:action.payload,
                    subscriptionFailure:true,
                    subscriptionSuccess:false,
                    trailSuccess:false,
                    trailFailure:true
                  }
                  case"CONTACT_US_REQUEST":
                  return{
                    ...state,
                    loading:true,
            
                  }
                  case"CONTACT_US_SUCCESS":
                  return{
                    ...state,
                    loading:false,
                    message:action.payload
                    
                  }
                  case"CONTACT_US_FAILURE":
                  return{
                    ...state,
                    loading:false,
                    error:action.payload
                    
                  }
                  case"STRIPE_PAYMENT_REQUEST":
                  return{
                    ...state,
                    loading:true,
            
                  }
                  case"STRIPE_PAYMENT_SUCCESS":
                  return{
                    ...state,
                    loading:false,
                    stripepayment:action.payload
            
                  } 
                  case"STRIPE_PAYMENT_FAILURE":
                  return{
                    ...state,
                    loading:false,
                    stripeerror:action.payload
            
                  }
                  case"GET_PROMOCODE_REQ":
                  return{
                    ...state,
                    loading:true,
                    promoCode:null,
                    promosuccess:false,
                    promofailure:false,
                    error:""
                  }
                  case"GET_PROMOCODE_SUCCESS":
                  return{
                    ...state,
                    loading:false,
                    promoCode:action.payload,
                    promosuccess:true,
                    promofailure:false,
                  } 
                  case"GET_PROMOCODE_FAILURE":
                  return{
                    ...state,
                    loading:false,
                    error:action.payload,
                    promosuccess:false,
                    promofailure:true,
                    promoCode:null,

                  }
    default:
      return state;
  }
};
