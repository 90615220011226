import React,{useEffect} from "react";
import "./button.css"

const DeletePopup = ({ onConfirm, onCancel ,className,text}, props) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (!event.target.closest(`.${className}`)) {
            onCancel(); // Close the delete popup if clicked outside
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [className, onCancel]);
    
  return (
    <div className="delete-popup">
      <p>Are you sure you want to {text}?</p>
      <button onClick={onConfirm} className="confirm-button"><span style={{color:"blue"}}>Yes</span></button>
      <button onClick={onCancel} className="no-button"><span style={{color:"red"}}>No</span></button>
    </div>
  );
};

export default DeletePopup;
