import "./contact.css"

const About=()=>{
    return(
        <div >
            <p  className="about-us">
            Welcome to our website, where creativity meets technology in the world of jewelry making.
           

            <center/>   Our team is a diverse blend of talent, expertise, and passion. From software engineers to experts in AI and automation, from seasoned creators and makers to artisans skilled in handcrafting and operating tools, and even hobbyists who bring their enthusiasm and unique perspectives – we've pooled our collective knowledge to create a comprehensive solution tailored for jewelry makers like you.<br/><br/>


At our core, we are driven by the belief that technology should empower creativity, not hinder it. That's why we've developed a platform that simplifies the complexities of managing supplies, clients, and orders, while also providing robust tracking capabilities to streamline your workflow.<br/><br/>

Whether you're a seasoned professional or a budding enthusiast, our goal is simple: to make your journey in jewelry making as smooth and rewarding as possible. With our platform, you can focus on what you do best – creating stunning pieces – while we handle the rest.<br/>

Join us on this exciting journey where innovation meets tradition, and where expertise meets simplicity. Let's craft something extraordinary together. Welcome to our community.
            </p>
        </div>
    )
}
export default About