import Back from "../../icons/back.svg"
import { useNavigate } from 'react-router-dom';

const BackButton =(props) =>{
    let navigate = useNavigate();

    function handleClick(){
      navigate(-1)
    }
    return(
              <div onClick={handleClick}  style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                <img src={Back} alt="back"/> &nbsp;
            <span style={{color: "#8692A6" ,fontSize: "19px", fontFamily: "Inter", fontWeight: "600",}}>Back</span>
        </div>
    )
}
export default BackButton
