import React, { useState, useEffect } from "react";
import View from "../../icons/View.svg"
import Download from "../../icons/Download.svg";
import "./sub.css"

const Subtable = (props) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  

  
  

  
  

  return (
    <div>
      <table className="dynamic-table">
        <thead>
          <tr className="heading-row">
            {props.headings.map(
              (heading, index) =>
                (props.showStatus || index !== heading.length - 1) && (
                  <th key={index}>{heading}</th>
                )
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.rows.map((rowData, rowIndex) => (
            <tr
              key={props.ids[rowIndex]}
              className="data-row"
            >
              {rowData.map((data, dataIndex) => (
                <React.Fragment key={dataIndex}>
                  <td>
                   <div>
                        {data}
                      </div>
                    
                  </td>
                </React.Fragment>
              ))}
              <td>
             {rowData.length < props.headings.length && (
                  <React.Fragment >
                    <div style={{display:"flex"}}>
                    <img
                    src={View}
                      onClick={() => props.handleViewClick(rowIndex)}
                      style={{ marginRight: "25%" }}
                    />
                     
                    <img src={Download} onClick={() =>props.handleDownloadClick(rowIndex)}/>
                    </div>
                  </React.Fragment>
                )}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    
    </div>
  );
};

export default Subtable;
