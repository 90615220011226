
import "./contact.css"


const Refund=()=>{
    return(
        <div>
            <p className="about-us">
            <h1 className="fo-head">Processing and Refunds Policy</h1>
We are happy to help you out if you are seeking a cancellation of Services and refund of fees.<br/>
 refund amounts and eligibility shall be determined in accordance with the following schedule <br/>
 (also referred to as the “Refund Schedule”):<br/><br/>
<table>
    <thead>
    <tr>
<th>Type of Subscription Service</th>
<th>Subscription Duration Lapse</th>
<th>Refund Percentage</th>

</tr>
    </thead>
    <tbody>
        <tr>
            <td>1 Month</td>
            <td>Up to 5 days</td>
            <td>90%</td>

        </tr>
         <tr>
            <td>1 Month</td>
            <td>Up to 10 days </td>
            <td>70%</td>

        </tr>
        <tr>
            <td>1 Month</td>
            <td>Up to 15 days</td>
            <td>45%</td>

        </tr>
        <tr>
            <td>1 Month </td>
            <td>Greater than 15 days</td>
            <td> 0%</td>

        </tr>
        <tr>
            <td>3 Months</td>
            <td>Up to 15 days</td>
            <td>90%</td>

        </tr>
        <tr>
            <td>3 Months</td>
            <td>Up to 30 days</td>
            <td>70%</td>

        </tr>
        <tr>
            <td>3 Months</td>
            <td>Up to 45 days</td>
            <td>45%</td>

        </tr>
        <tr>
            <td>3 Months</td>
            <td>Up to 60 days </td>
            <td>30%</td>

        </tr>
        <tr>
            <td>3 Months</td>
            <td>Greater than 60 days </td>
            <td>0%</td>

        </tr>
        <tr>
            <td>6 Months</td>
            <td>Up to 15 days </td>
            <td>95%</td>

        </tr>
        <tr>
            <td>6 Months</td>
            <td>Up to 30 days </td>
            <td>90%</td>

        </tr>
        <tr>
            <td>6 Months</td>
            <td>Up to 45 days </td>
            <td>85%</td>

        </tr>
        <tr>
            <td>6 Months</td>
            <td>Up to 60 days </td>
            <td>70%</td>

        </tr>  <tr>
            <td>6 Months</td>
            <td>Up to 90 days </td>
            <td>45%</td>

        </tr>
        <tr>
            <td>6 Months</td>
            <td>Up to 120 days </td>
            <td>30%</td>

        </tr>
        <tr>
            <td>6 Months</td>
            <td>Up to 160 days </td>
            <td>10%</td>

        </tr>
        <tr>
            <td>6 Months</td>
            <td> Greater than 160 days </td>
            <td>0%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 30 days </td>
            <td>95%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 90 days </td>
            <td>75%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 120 days </td>
            <td>70%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 160 days </td>
            <td>60%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 200 days </td>
            <td>40%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 200 days </td>
            <td>40%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 260 days </td>
            <td>20%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 300 days </td>
            <td>10%</td>

        </tr>
        <tr>
            <td>12 Months</td>
            <td> Up to 300 days </td>
            <td>0%</td>

        </tr>
        
        </tbody>
</table>
  

To initiate a termination of service, you must contact us first at info@makertechsolutions.com . If your request is accepted, we will provide you with instructions on how to proceed.<br/><br/>


<h1 className="fo-head">Refunds</h1> For terminated services, we will notify you once we’ve completed the calculation of lapsed days, matched it against the above mentioned Refund Schedule and determined the amount to be refunded if applicable.

If refunds are approved, you will be automatically refunded on your original payment method. Please remember it can take some time for your bank or credit card company to process and post the refund too.</p>
        </div>
    )
}
export default Refund