import React, { useEffect, useState } from "react";
import BackButton from "../Buttons/BackButton";
import AddButton from "../Buttons/AddButton";
import { Formik, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { useFormik } from "formik";
import { useLocation,useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import Snackbar from "../Snackbar/Alert";

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Loader from "../Buttons/Loader"
import "./clientform.css";



const ClientForm = (props) => {
  useEffect(() => {
    props.listallClients({ offset: 0, first: 10 });
  }, []);
  let navigate = useNavigate();

  const location = useLocation();
  const { client } = location.state || {};
  useEffect(() => {
   
    if (client) {
      
      // console.log("client Details:", client);
    }
  }, [client]);
 
 


  const initialValues = client
    ? {
        Name: client.name,
        Email: client.email,
        countryCode:client.countryCode,
        MobileNumber: client.mobile,
        Address: client.address,
        Description:client.description
        
      }
    : {
        Name: "",
        Email: "",
        countryCode:"",
        MobileNumber: "",
        Address: "",
        Description:"",
      };

  const [formState, setFormState] = useState(initialValues);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const handlePhoneInputChange = (value, country) => {
    console.log("country",country.inputValue.length > 4)
    if(country.inputValue.length > 4){
      formik.setFieldValue("MobileNumber", country.inputValue);
      formik.setFieldValue("countryCode", country.country.dialCode);
    }
    else{
      formik.setFieldValue("MobileNumber", "");
      formik.setFieldValue("countryCode", "");
    }
  
  };
  
  const inputFields = [
    { type: "text", label:"Name", placeholder: "Name", name: "Name" },
    { type: "text",label:"Email", placeholder: "Email", name: "Email" },
    { type: "text", label:"Mobile Number",placeholder: "Mobile Number", name: "MobileNumber" },
    { type: "text", label:"Address",placeholder: "Address", name: "Address" },
    { type: "textArea", label:"Description",placeholder: "Write your description here..", name: "Description" },
  ];
  useEffect(() => {
    if (props.addClientSuccess===true) {
      setShowLoader(false)

      setShowSnackbar(true);
      setTimeout(() => {
        navigate("/home/clients");
      }, 2000); 
    }
    else if (props.addclientFailure===true){
      setShowLoader(false)

      setTimeout(()=>{
        setShowErrorSnackbar(false)
      })
    }
  }, [props.addClientSuccess, navigate]);

  useEffect(() => {
    if (props.editClientSuccess) {
      setShowSnackbar(true);
      setShowLoader(false)
      setTimeout(() => {
        navigate("/home/clients");
      }, 2000);
    }
    else if (props.editClientFailure===true){
      setShowLoader(false)

    }
  }, [props.editClientSuccess, navigate]);



  useEffect(() => {
    if (showSnackbar) {
      const timer = setTimeout(() => {
        setShowSnackbar(false);
        setShowLoader(false)

      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSnackbar]);
const removeNonNumeric = (str) => {
    if (typeof str !== 'string') {
        return ''; // or handle the error in another way
    }
    return str.replace(/[^0-9]/g, '');
};

  // Assuming `val` contains the entire phone number including country code
  const validatePhoneNumber = (val) => {
    if (!val) {
        // If the phone number is empty or undefined, consider it as valid
        return false;
    }

    const numericValue = removeNonNumeric(val);
    // Check if numericValue has at least 6 digits
    return numericValue.length >= 6;
};


  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is required *")
    // .matches(/^[aA-zZ\s]+$/, "Please use only letters ")    
    .max(36, "Name length must be less than or equal to 36 characters long")
    

    ,
    Email: Yup.string()
    .transform((value) => value.toLowerCase())
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email address must be valid"
    )    
    .required("Email is required *"),
    MobileNumber: Yup.string(),
    // .required("Phone number is required *")
    // .test('len', 'Phone number must be at least 6 digits *', validatePhoneNumber),

  //   .test('len', 'Phone number must be at least 6 digits *', (val) => {
  //     // If the value is empty or undefined, consider it as valid
  //     if (!val) {
  //         return true;
  //     }
  //     // Otherwise, validate the length of the numeric value
  //     const numericValue = removeNonNumeric(val);
  //     return numericValue.length >= 6;
  // }),
    // .matches(/^\+?[1-9]\d{7,14}$/, 'Please Enter a valid phonenumber'),
  
  
    Address: Yup.string().required("Address is required *")
    .min(5,"Address length must be at least 5 characters long")
    .max(100, "Address length must be less than or equal to 100 characters long"),
    Description: Yup.string().required("Description is required")
    .max(300, "Description length must be less than or equal to 300 characters long"),

  });

  const formik = useFormik({
    initialValues: formState,
    validationSchema: validationSchema,
    
    onSubmit: (values) => {
      // console.log("values", values);
setShowErrorSnackbar(false)
setShowLoader(true)
      const lowercaseEmail = values.Email.toLowerCase();
    
     const name = values.Name.trim()
     const description= values.Description.trim()
     const address=values.Address.trim()
      const updatedValues = { ...values, Email: lowercaseEmail, Name: name,Description:description ,Address:address};
      if (props.title === "Add Client") {
        props.createClient(updatedValues);
        setShowSnackbar(true);
        setShowErrorSnackbar(true)
      
   
      } else if (props.title === "Edit Client") {
        props.updateClient(  client.id  ,updatedValues);
        setShowSnackbar(true);
      
        setShowErrorSnackbar(true)

      }
    },
  });
  console.log("formik",formik)

// console.log("loadrt",showLoader)
  return (
    <div className="container clientform">
      <div className="row">
        <div className="col-1 backbutton">
          <BackButton {...props} />
        </div>
        <div className="col-12 header">
          <span
            style={{
              color: "#4005A0",
              fontSize: "26px",
              fontFamily: "Inter",
              fontWeight: "600",
            }}
          >
            {props.title}
          </span>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="row textfield">
          {inputFields.map((field, index) => (
            <div key={index} className="col-lg-6 mb-4">
                <label htmlFor={field.name} className="form-labels">
              {field.label}
            </label>
              {field.name === "MobileNumber" ? (
                <PhoneInput
                defaultCountry="us"
                  value={formik.values[field.name]}
                  onChange={(value, countryCode) => handlePhoneInputChange(value, countryCode)}
                  style={{width:'100%'}}
                  name={field.name} 
                  placeholder={field.placeholder}
                  autoFormat={true}
                  inputProps={{ autoFocus: true }}
                />
              ) : field.type === "textArea" ? ( <textarea
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
                required
                
                className={`field-area ${
                  formik.touched[field.name] && formik.errors[field.name]
                    ? "error"
                    : ""
                }`}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              />): (
                <input
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  className={`field ${
                    formik.touched[field.name] && formik.errors[field.name]
                      ? "error"
                      : ""
                  }`}
                  placeholder={field.placeholder}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                 

               
                />
              )}

              {formik.touched[field.name] && formik.errors[field.name] && (
                <div className="error-message" style={{ color: "red" }}>
                  {formik.errors[field.name]}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="row justify-content-end add">
          <div className="col-3 mb-1 text-end">
            <button type="submit" className="addformbutton">
              {props.text}
            </button>
          </div>
        </div>
      </form>

      <div>
        {/* {console.log("clientfailue",props.addClientFailure)} */}
        {/* {console.log("clienteditfailue",props.editClientFailure)} */}

        {showSnackbar && props.addClientSuccess === true && (
          <Snackbar message="Client Added Successfully" type="success" />
        )}
       
        {showSnackbar && props.editClientSuccess === true && (
          <Snackbar message="Client Edited Successfully" type="success" />
        )}
        {showSnackbar && props.editClientFailure === true && (
          <Snackbar message={props.errors} type="errors" />
        )}
       {showErrorSnackbar && props.addclientFailure === true && (
          <Snackbar message={props.errors} type="errors" />
        )}
      {showLoader  && (
          <Loader />
      )}

      </div>
      {/* {console.log("error",props.errors)} */}
      <div></div>
    </div>
  );
};

export default ClientForm;
