import React, { useState, useEffect } from "react";
import DynamicTable from "../Tables/index";
import AddButton from "../Buttons/AddButton";
import Search from "../Search/Search";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
import next from "../../../src/icons/next.svg";
import prev from "../../../src/icons/prev.svg";
import Snackbar from "../Snackbar/Alert";
import Loader from "../Buttons/Loader";
import * as XLSX from 'xlsx';
import "./suppliers.css";

const Suppliers = (props) => {

  let navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [suppliersRows, setSuppliersRows] = useState([]);
  const [suppliersIds, setSuppliersIds] = useState([]);
  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const itemsPerPage = 10;
  const [totalCount,setTotalCount]=useState("")
  
  

  const totalPages = Math.ceil(totalCount / itemsPerPage);
  useEffect(() => {

    props.listallSuppliers({
      offset: (currentPage - 1) * itemsPerPage,
      first: itemsPerPage,
    });
    setTotalCount(props?.suppliersData?.count)
}, [currentPage,props.deleteSupplierSuccess,suppliersRows]);

  useEffect(() => {
    props.categoriesListing();
  }, [props.categoriesListing]);

  const availableCategories = props?.categoryData?.categoriesListing || [];

  useEffect(() => {
    const defaultSuppliersItems =
      props?.suppliersData?.items || [];
    const searchSuppliersItems =
      props?.searchSuppliersData?.searchSuppliers?.items || [];

    const combinedItems =
      searchValue.trim() === "" ? defaultSuppliersItems : searchSuppliersItems;
    
      setSuppliersRows((prevRows) => {
      const updatedRows = combinedItems.map((item) => {
       
        const categoryIds = Array.isArray(item?.categories)
    ? item.categories.map(category => category.id)
    : [item?.categories?.id];
        const categoryNames = categoryIds.map((categoryId) => {
          const category = availableCategories.find(
            (category) => category.id === categoryId
          );
          return category ? category.value : "";
        });

        return [
          item?.name || "",
          item?.mobile || '',
          item?.email || "",         
          item?.address || "",
          categoryNames.join(", ") || "",
        ];
      });

      if (!isEqual(prevRows, updatedRows)) {
        return updatedRows;
      }

      return prevRows;
    });
    if (currentPage > totalPages) {
      
      setCurrentPage(Math.max(1, totalPages));
    }
  
    if (totalPages === 0) {
      setCurrentPage(1);
    }
    setSuppliersIds((prevIds) => {
      if (
        !isEqual(
          prevIds,
          combinedItems.map((item) => item.id)
        )
      ) {
        return combinedItems.map((item) => item.id);
      }

      return prevIds;
    });
  }, [props?.suppliersData?.items || [], searchValue, availableCategories,]);
  const formatNumber = (mobileNumber) => {
    const countryCode = mobileNumber.slice(0, 2);
    const formattedNumber = `+${countryCode} ${mobileNumber.slice(2, 5)} ${mobileNumber.slice(5)}`;
    return formattedNumber;
  };

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchValue(searchInput);

    if (searchInput.trim() === "") {
      props.listallSuppliers({ offset: 0, first: totalCount });
      setTotalCount(props?.suppliersData?.count)
    } else {
      props.searchSuppliers({
        pagination: { offset: 0, first: totalCount },
        searchKey: searchInput,
      });
      setTotalCount(props?.searchSuppliersData?.searchSuppliers?.count)
    }
    setSuppliersIds([]);
  };

  function handleClick() {
    navigate("/home/suppliers/addsuppliers");
  }

  function handleEditClick(id) {
    const selectedSupplier = props?.suppliersData?.items.find(
      (supplier) => supplier.id === id
    );
  
    if (selectedSupplier) {
      navigate("/home/suppliers/editsuppliers", { state: { supplier: selectedSupplier } });
    }
  }
  
  function handleDeleteClick(ids) {
    props.deleteSupplier(ids);
    setShowDeleteSnackbar(true)
    setShowErrorSnackbar(true)
    setShowLoader(true)
    if (suppliersIds.length === 1 && totalPages > 1) {
      // If current page becomes empty and there are more than one page,
      // move back to the previous page
      setCurrentPage(currentPage - 1);
    }
  }
  
  const suppliersHeadings = ["Name", "Mobile", "Email", ,"Address", "Category"];
  const excelHeadings = ["Name", "Address", "Email", "Mobile", "Category" ,"Description"];

  useEffect(() => {
   props.excelSuppliers({ offset: 0, first: props?.suppliersData?.count });
  }, []);
  
  const handleDownloadClick = async () => {
    try {
      const allSupplierRows = props?.excelData?.items.map((item) => {
        const catIds = Array.isArray(item?.categories)
          ? item.categories.map(category => category.id)
          : [item?.categories?.id];
    
    
        // Declare catNames outside the map callback
        const catNames = catIds.map((categoryId) => {
          const cat = availableCategories.find(
            (category) => category.id === categoryId
          );
          return cat ? cat.value : "N/A";
        });
    
        return [
          item?.name || '',
          item?.address || '',
          item?.email || '',
      item?.mobile || '',
          catNames.join(", ") || "N/A",
          item?.description || ''
        ];
      }) || [];
      const sheet = XLSX.utils.aoa_to_sheet([excelHeadings, ...allSupplierRows]);
  
      // Create the Excel workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, 'Suppliers');
  
      // Trigger the download
      XLSX.writeFile(wb, 'Suppliers.xlsx');
    } catch (error) {
    }
  
      // Create the Excel sheet
     
    
  };
  useEffect(() => {
    if (showDeleteSnackbar||showErrorSnackbar) {
      const timer = setTimeout(() => {
        setShowDeleteSnackbar(false);
        setShowErrorSnackbar(false)
      }, 3000); 

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showDeleteSnackbar,showErrorSnackbar]);
  return (
    <div className="suppliers">
      <div className="headers">
        <div className="suppliersheader">
          <div>Suppliers</div>
        </div>
        
      </div>
      <div className="search-add-container">
    <div className="search" style={{ flex: 1 }}>
      <Search value={searchValue} onChange={handleSearch} />
    </div>
    <div className="export-button">
    <button
    className="export"
        text="Download"
        type="button"
        onClick={handleDownloadClick}
        {...props}
      >Download</button>
    </div>
    <div className="add-button">
      <AddButton
        text="Add Supplier"
        type="button"
        onclick={handleClick}
        {...props}
      />
    </div>
  </div>
      <div className="table">
        <DynamicTable
          ids={suppliersIds}
          headings={suppliersHeadings}
          rows={suppliersRows}
          showStatus={true}
          showEditIcon={true}
          showExportIcon={true}
          showInfoButton={true}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          
          {...props}
        />
         {suppliersRows.length === 0 ? ( <div className="no-data-found">No data found</div>):totalCount<=10?(""): props.loading  ? (
          <Loader />
        ):(   <div className="pagination-buttons">
          <div
            onClick={() =>
              setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
            }
            disabled={currentPage === 1}
            className="pagination-button"
          >
            <img src={prev} alt="prev" className="pagebuttons" />
          </div>

          {Array.from({ length: totalPages }).map((_, index) => {
            const pageNumber = index + 1;

            if (totalPages <= 3 || Math.abs(currentPage - pageNumber) <= 1) {
              return (
                <div key={pageNumber} className="numbers">
                  <button
                    onClick={() => setCurrentPage(pageNumber)}
                    className={
                      currentPage === pageNumber ? "active" : "disabled"
                    }
                  >
                    {pageNumber}
                  </button>
                </div>
              );
            } else if (
              (currentPage <= 3 && pageNumber === 4) ||
              (currentPage >= totalPages - 2 && pageNumber === totalPages - 3)
            ) {
              return (
                <div key={pageNumber} className="ellipsis">
                  ...
                </div>
              );
            }
            return null;
          })}

          <div
            onClick={() =>
              setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            <img src={next} alt="next" className="pagebuttons" />
          </div>
        </div>)}
     
      </div>
      {showDeleteSnackbar && props.deletesuppliersuccess === true && (
          <Snackbar message="Supplier Deleted Successfully" type="success" />
        )}
        {showErrorSnackbar && props.deletesupplierfailure === true && (
          <Snackbar message={props?.errors} type="errors" />
        )}
        {showLoader && props.loading=== true && (
          <Loader />
      )}
    </div>
  );
};

export default Suppliers;
