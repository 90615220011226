import React, { useState, useEffect } from "react";
import BackButton from "../Buttons/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Snackbar from "../Snackbar/Alert";
import * as Yup from "yup";
import Loader from "../Buttons/Loader";
import "./SuppliesForm.css";

const SuppliesForm = (props) => {
  const location = useLocation();
  let navigate = useNavigate();
  const unit=localStorage.getItem("uom")

  const [supplier, setSupplier] = useState("");
  const [category, setCategory] = useState("");
  const [childCategory, setChildCategory] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [uom, setUom]=useState('')

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      borderColor: state.isFocused ? "#4005A0" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(64, 5, 160, 0.2)" : "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4005A0" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  const { supplies } = location.state || {};
  useEffect(() => {
    props.listallSuppliers({
      offset: 0,
      first: props?.listallSuppliers?.count,
    });
  }, [props.listallSuppliers]);
  
  const supplierOptions =
    props?.suppliersData.items?.map((supplier) => ({
      value: supplier.id,
      label: supplier.name,
      id: supplier.id,
    })) || [];
  const supplierCategory =
    props?.suppliersCategory?.supplierDetails?.categories?.map((category) => ({
      value: category.id,
      label: category.value,
      id: category.id,
    })) || [];
  const childCategoryData =
    props?.childCategory?.categoryDetails?.childCategories?.map(
      (childCategory) => ({
        value: childCategory.id,
        label: childCategory.value,
        id: childCategory.id,
      })
    ) || [];
  useEffect(() => {
    if (supplies) {
      props.supplierDetails({ supplierId: supplies?.supplier?.id });
      props.categoryDetails({ categoryId: supplies?.category?.id });
    }
  }, [supplies]);


  useEffect(() => {
    if (props.addSuppliesSuccess === true) {
      setShowSnackbar(true);
      setShowLoader(false);
      setTimeout(() => {
        navigate("/home/supplies");
      }, 1000);
    }
    else if(props.addSuppliesFailure === true){
      setShowLoader(false);

    }
  }, [props.addSuppliesSuccess, navigate]);

  useEffect(() => {
    if (props.editSuppliesSuccess) {
      setShowSnackbar(true);
      setShowLoader(false);

      setTimeout(() => {
        navigate("/home/supplies");
      }, 1000);
    }
    else if (props.editSuppliesFailure === true){
      setShowLoader(false);

    }
  }, [props.editSuppliesSuccess, navigate]);

  const initialValues = supplies
    ? {
        supplier: supplies?.supplier?.id,
        category: supplies?.category?.id,
        childCategory: supplies?.childCategory?.id,
        parameters: supplies?.parameters?.map((param) => ({
          name: param.name,
          value: param.value,
          hasUom: param.hasUom,
          uom: param.uom || null,
        
        })),
        totalQuantity: supplies.quantity,
        description: supplies.description,
      }
    : {
        supplier: "",
        category: "",
        childCategory: "",
        parameters: [{ name: "", value: "", hasUom: false,  }],
        totalQuantity: "",
        description: "",
      };
      
  const validationSchema = Yup.object().shape({
    supplier: Yup.string().required("Supplier is required"),
    category: Yup.string().required("Category is required"),
    childCategory: Yup.string().required("Type is required"),
    totalQuantity: Yup.string()
      .required("Total Quantity is required")
      .matches(
        /^\+?[1-9]/,
        "only numbers is allowed and quantity must be greater than zero"
      ),
    description: Yup.string()
      .required("description is required")
      .max(
        300,
        "description length must be less than or equal to 300 characters long"
      ),
    parameters: Yup.array().of(
      Yup.object().shape({
        // name: Yup.string().required("Parameter Name is required"),
        // value: Yup.string()
       
      
        // hasUom: Yup.boolean().required("Parameter UOM flag is required"),
        // uom: Yup.string().when('hasUom', {
        //   is: true,
        //   then: Yup.string().required('Parameter UOM is required when "hasUom" is true'),
        // }),
      })
    ),
  });
  useEffect(() => {
    if(!supplies){
    if (props?.childCategory?.categoryDetails?.parameters) {
      const updatedParameters = props?.childCategory?.categoryDetails?.parameters.map(parameter => {
        let uom = null;
      if (parameter.hasUom) {
        uom = unit === "Metric System" ? (parameter.metricSystemUom || null) : (parameter.imperialSystemUom || null);
      }
        return {
          
          uom: uom // Autopopulate uom if hasUom is true
        };
      });
      formik.setValues({
        ...formik.values,
        parameters: updatedParameters
      });
    }
  }
  }, [props?.childCategory?.categoryDetails?.parameters]);
  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values, actions) => {
      const description=values.description.trim()
      setShowLoader(true);
      const updatedValues = { ...values,description:description};
      const invalidParameterIndex = values.parameters.findIndex((parameter) =>
        isNaN(parameter.value)
      );

      if (isNaN(values.totalQuantity)) {
        actions.setFieldError(
          "totalQuantity",
          "Total Quantity must be a valid number"
        );
      } 
      // else if (invalidParameterIndex !== -1) {
      //   actions.setFieldError(
      //     `parameters[${invalidParameterIndex}].value`,
      //     "Parameter value must be a valid number"
      //   );
      // }
       else {
        if (props.title === "New Supply Details") {
          props.createSupplies(updatedValues);
          setShowSnackbar(true);
        
        } else if (props.title === "Edit Supply Details") {
          props.updateSupplies(values, { suppliesId: supplies.id });
          setShowSnackbar(true);
          
        }
      }
    },
  });
  const handleCategoryChange = (selectedOption) => {
    setCategory(supplierCategory.find(
      (option) => option.value === selectedOption.values
    ));
    const id = selectedOption ? selectedOption.id : null;
    props.categoryDetails({ categoryId: id });
    formik.setFieldValue("parameters", []);
    formik.setFieldValue("totalQuantity", "");
    setChildCategory([])
   

  };

  const handleChildCategoryChange = (selectedOption) => {
    setChildCategory(childCategoryData.find(
      (option) => option.value === selectedOption.value
    ));
    const id = selectedOption ? selectedOption.id : null;

  };

  const handleSupplierChange = (selectedOption) => {
    setSupplier(supplierOptions.find(
      (option) => option.value === selectedOption.value
    ));
    const id = selectedOption ? selectedOption.id : null;
    props.supplierDetails({ supplierId: id });
   setCategory([])
   setChildCategory([])
  //  formik.setFieldValue("category",)
   formik.setFieldValue("parameters", []);
    formik.setFieldValue("totalQuantity", "");
    
  };
 
 

  return (
    <div className="container suppliesform">
      <div className="row">
        <div className="col-1 backbutton">
          <BackButton {...props} />
        </div>
        <div className="col-12 header">
          <span
            style={{
              color: "#4005A0",
              fontSize: "24px",
              fontFamily: "Inter",
              fontWeight: "600",
            }}
          >
            {props.title}
          </span>
        </div>
        <div className="col-12 header" style={{ marginBottom: "14px" }}>
          <span
            style={{
              color: "#3E3636 ",
              fontSize: "16px",
              fontFamily: "Inter",
              fontWeight: "600",
            }}
          >
            {props.subtitle}
          </span>
        </div>
      </div>
      <form
        style={{
          border: "2px solid #ddd",
          padding: "23px",
          borderRadius: "10px",
        }}
        onSubmit={formik.handleSubmit}
      >
        <div className="row ">
          <div className="col-md-4 cat-label">
            <label htmlFor="Client" className="form-labels">
              Choose Supplier
            </label>
            <div
              style={{
                width: "100%",
                height: "75%",
                position: "relative",
                borderRadius: "10px",
              }}
            >
              <Select
                name="supplier"
                value={supplier||supplierOptions.find(option => option.value === formik.values.supplier)}
                onChange={(selectedOption) => {
                  formik.setFieldValue("supplier", selectedOption.value);
                  handleSupplierChange(selectedOption);
                }}
                options={supplierOptions}
                styles={customStyles}
              />
              {formik.touched.supplier && formik.errors.supplier && (
                <div className="sup-error">{formik.errors.supplier}</div>
              )}
            </div>{" "}
          </div>

          <div className="col-md-4 cat-label">
            <label htmlFor="Client" className="form-labels">
              Category{" "}
            </label>
            <Select
              name="category"
              value={category|| supplierCategory.find(option => option.id === formik.values.category)}
              onChange={(selectedOption) => {
                formik.setFieldValue(
                  "category",
                  selectedOption ? selectedOption.value : null
                );

                handleCategoryChange(selectedOption);
              }}
              options={supplierCategory}
              styles={customStyles}
            />
            {formik.touched.category && formik.errors.category && (
              <div className="sup-error">{formik.errors.category}</div>
            )}
          </div>
          {props?.childCategory?.categoryDetails?.childCategories &&
            props?.childCategory?.categoryDetails?.childCategories.length >
              0 && (
              <div className="col-md-4 cat-label">
                <label htmlFor="Client" className="form-labels">
                  Type
                </label>
                <Select
                  name="childCategory"
                  value={childCategory || childCategoryData.find(option => option.id === formik.values.childCategory)}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "childCategory",
                      selectedOption ? selectedOption.value : null
                    );

                    handleChildCategoryChange(selectedOption);
                  }}
                  options={childCategoryData}
                  styles={customStyles}
                />
                {formik.touched.childCategory &&
                  formik.errors.childCategory && (
                    <div className="sup-error">
                      {formik.errors.childCategory}
                    </div>
                  )}
              </div>
            )}
          {props?.childCategory?.categoryDetails?.parameters &&
            props?.childCategory?.categoryDetails?.parameters.length > 0 && (
              <div className="container d-grid">
                <div className="col-12 header">
                  <span
                    style={{
                      color: "#3E3636 ",
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                    }}
                  >
                    Parameters{" "}
                  </span>
                </div>
                {props?.childCategory?.categoryDetails?.parameters.map(
                  (parameter, index) => (
                    <div key={index} className="params">
                      <div className="row units d-flex ">
                        <div className="col-md-2 col-xs-2 product-label">
                          <label
                            htmlFor={parameter.name}
                            className="form-labels"
                            style={{ textAlign: "center", margin: "13px" }}
                          >
                            {parameter.value}
                          </label>
                        </div>
                        <div className="col-md-3 col-xs-3">
                          <input
                            type="text"

                            name={`parameters[${index}].value`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `parameters[${index}].value`,
                                e.target.value
                              );

                              formik.setFieldValue(
                                `parameters[${index}].name`,
                                parameter.value
                              );
                              formik.setFieldValue(
                                `parameters[${index}].hasUom`,
                                parameter.hasUom
                              );
                              // formik.setFieldValue(
                              //   `parameters[${index}].uom`,
                              //   parameter.metricSystemUom
                              //   || ""
                              // );
                            }}
                            value={
                              formik.values.parameters &&
                              formik.values.parameters[index]
                                ? formik.values.parameters[index].value
                                : ""
                            }
                            className="form-controls"
                          />
                          {/* {formik.touched.parameters &&
                            formik.touched.parameters[index] &&
                            formik.errors.parameters && (
                              <div className="sup-error">
                                {formik.errors.parameters[index]?.value}
                              </div>
                            )} */}
                        </div>
                        {parameter.hasUom && (
                          <>
                            <div className="col-md-1 col-xs-1 product-label">
                              <label
                                htmlFor={`parameters[${index}].uom`}
                                className="form-labels"
                                style={{ textAlign: "center", margin: "13px" }}
                              >
                                UOM
                              </label>
                            </div>
                            <div className="col-md-3 col-xs-3">
                              <input
                                type="text"
                                name={`parameters[${index}].uom`}
                                className="form-controls"
                                onChange={(e)=>{formik.setFieldValue(
                                  `parameters[${index}].uom`,
                                  e.target.value || ""
                                )}}
                                value={ formik.values.parameters &&
                                  formik.values.parameters[index]
                                    ? formik.values.parameters[index].uom
                                    : ""}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          <div className="row units form-with-borders">
            <div className="col-md-2 product-label">
              <label
                htmlFor="Total Quantity"
                className="form-labels"
                style={{
                  textAlign: "center",
                  margin: "13px",
                  marginLeft: "1px",
                }}
              >
                Total Quantity
              </label>
            </div>
            <div className="col-md-5">
              <input
                type="number"
                name="totalQuantity"
                className="form-controlsquan"
                value={formik.values.totalQuantity}
                onChange={formik.handleChange}
              />
              {formik.touched.totalQuantity && formik.errors.totalQuantity && (
                <div className="sup-error">{formik.errors.totalQuantity}</div>
              )}
            </div>
            <div className="col-md-5" style={{display:"grid"}}>
              <label htmlFor="description" className="form-labels">
                Description
              </label>
              <textarea
                name="description"
                placeholder="Write your description here"
                required
                className="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="sup-error">{formik.errors.description}</div>
              )}
            </div>
          </div>
          <div className="row justify-content-end add">
            <div className="col-3 mb-1 text-end">
              <button type="submit" className="addbutton">
                {props.text}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showSnackbar && props.addSuppliesSuccess === true && (
        <Snackbar message="Supplies Added Successfully" type="success" />
      )}
      {showSnackbar && props.addSuppliesFailure === true && (
        <Snackbar message={props.errors} type="errors" />
      )}
      {showSnackbar && props.editSuppliesSuccess === true && (
        <Snackbar message="Suppplies Edited Successfully" type="success" />
      )}
      {showSnackbar && props.editSuppliesFailure === true && (
        <Snackbar message={props.errors} type="errors" />
      )}
      {showLoader && props.loading === true && <Loader />}
    </div>
  );
};
export default SuppliesForm;
