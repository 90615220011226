import { gql } from "@apollo/client";

export const LIST_ALL_CLIENTS = gql`
  query listallClients($pagination: paginationArgType!) {
    listallClients(pagination: $pagination) {
      count
      items {
        id
        name
        address
        email
        countryCode
        mobile
        description
      }
    }
  }
`;
export const CREATE_CLIENT = gql`
  mutation createClient(
    $name: String!
    $address: String!
    $email: String!
    $countryCode: String!
    $mobile: String!
    $description: String!
  ) {
    createClient(
      name: $name
      address: $address
      email: $email
      countryCode: $countryCode
      mobile: $mobile
      description: $description
    ) {
      id
      name
      address
      email
      countryCode
      mobile
      description
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $clientId: ID!
    $name: String!
    $email: String!
    $countryCode: String!
    $mobile: String!
    $address: String!
    $description: String!
  ) {
    updateClient(
      clientId: $clientId
      name: $name
      email: $email
      countryCode: $countryCode
      mobile: $mobile
      address: $address
      description: $description
    ) {
      id
      name
      address
      mobile
      countryCode
      email
      description
    }
  }
`;
export const DELETE_CLIENT = gql`
  mutation deleteOneClient($clientId: ID!) {
    deleteOneClient(clientId: $clientId)
  }
`;
export const SEARCH_CLIENTS = gql`
  query SearchClients($pagination: paginationArgType!, $searchKey: String!) {
    searchClients(pagination: $pagination, searchKey: $searchKey) {
      count
      items {
        id
        name
        address
        email
        countryCode
        mobile
        description
      }
      hasNext
    }
  }
`;
export const CATEGORIES_LISTING = gql`
  query categoriesListing {
    categoriesListing {
      id
      name
      value
    }
  }
`;
export const CREATE_SUPPLIER = gql`
  mutation createSupplier(
    $name: String!
    $address: String!
    $email: String!
    $countryCode: String!
    $mobile: String!
    $categories: [ID!]!
    $description: String!
  ) {
    createSupplier(
      name: $name
      address: $address
      email: $email
      countryCode: $countryCode
      mobile: $mobile
      categories: $categories
      description: $description
    ) {
      id
      name
      address
      email
      countryCode
      mobile
      description
      categories {
        id
        name
        value
      }
    }
  }
`;
export const LIST_ALL_SUPPLIERS = gql`
  query listAllSuppliers($pagination: paginationArgType!) {
    listAllSuppliers(pagination: $pagination) {
      count
      items {
        id
        name
        address
        email
        countryCode
        mobile
        description
        categories {
          id
          name
          value
        }
      }
    }
  }
`;
export const SEARCH_SUPPLIERS = gql`
  query searchSuppliers($pagination: paginationArgType!, $searchKey: String!) {
    searchSuppliers(pagination: $pagination, searchKey: $searchKey) {
      count
      items {
        id
        name
        address
        email
        countryCode
        mobile
        description
        categories {
          id
          name
          value
        }
      }
      hasNext
    }
  }
`;
export const DELETE_SUPPLIERS = gql`
  mutation deleteOneSupplier($supplierId: ID!) {
    deleteOneSupplier(supplierId: $supplierId)
  }
`;
export const UPDATE_SUPPLIERS = gql`
  mutation updateSupplier(
    $supplierId: ID!
    $name: String!
    $address: String!
    $email: String!
    $countryCode: String!
    $mobile: String!
    $categories: [ID!]!
    $description: String!
  ) {
    updateSupplier(
      supplierId: $supplierId
      name: $name
      address: $address
      email: $email
      countryCode: $countryCode
      mobile: $mobile
      categories: $categories
      description: $description
    ) {
      id
      name
      address
      mobile
      countryCode
      email
      description
      categories {
        id
        name
        value
      }
    }
  }
`;
export const CATEGORY_DETAILS = gql`
  query categoryDetails($categoryId: ID!) {
    categoryDetails(categoryId: $categoryId) {
      id
      name
      value
      childCategories {
        id
        name
        value
      }
      parameters {
        name
        value
        hasUom
        uom
        metricSystemUom
        imperialSystemUom
      }
    }
  }
`;
export const SUPPLIES_DETAILS = gql`
  query suppliesDetails($suppliesId: ID!) {
    suppliesDetails(suppliesId: $suppliesId) {
      id
      supplier {
        name
      }
      category {
        name
      }
      quantity
    }
  }
`;
export const SUPPLIER_DETAILS = gql`
  query supplierDetails($supplierId: ID!) {
    supplierDetails(supplierId: $supplierId) {
      id
      name
      address
      email
      countryCode
      mobile
      makerId
      categories {
        id
        name
        value
      }
    }
  }
`;

export const CREATE_SUPPLIES = gql`
  mutation createSupplies(
    $supplier: ID!
    $category: ID!
    $childCategory: ID!
    $parameters: [parameterInputType!]!
    $quantity: Int!
    $description: String!
  ) {
    createSupplies(
      supplier: $supplier
      category: $category
      childCategory: $childCategory
      parameters: $parameters
      quantity: $quantity
      description: $description
    ) {
      id
      supplier {
        id
        name
        email
      }
      category {
        id
        name
      }
      childCategory {
        name
      }
      parameters {
        name
        value
        hasUom
        uom
        metricSystemUom

        imperialSystemUom
      }
      quantity
      makerId
      description
    }
  }
`;

export const PARAMETER_INPUT = gql`
  input parameterInputType {
    name: String!
    value: String!
    hasUom: Boolean!
    uom: String!
    metricSystemUom:String!

imperialSystemUom:String!
  }
`;

export const LIST_ALL_SUPPLIES = gql`
  query listAllSupplies($pagination: paginationArgType!) {
    listAllSupplies(pagination: $pagination) {
      count
      items {
        id
        supplier {
          id
          name
        }
        category {
          id
          name
          value
        }
        childCategory {
          id
          name
          value
        }
        parameters {
          name
          value
          hasUom
          uom
          metricSystemUom

imperialSystemUom
        }
        quantity
        description
      }
      hasNext
    }
  }
`;
export const UPDATE_SUPPLIES = gql`
  mutation updateSupplies(
    $suppliesId: ID!
    $supplier: ID!
    $category: ID!
    $childCategory: ID!
    $parameters: [parameterInputType!]!
    $quantity: Int!
    $description: String!
  ) {
    updateSupplies(
      suppliesId: $suppliesId
      supplier: $supplier
      category: $category
      childCategory: $childCategory
      parameters: $parameters
      quantity: $quantity
      description: $description
    ) {
      id
      supplier {
        id
        name
        email
      }
      category {
        id
        name
        value
      }
      childCategory {
        id
        name
        value
      }
      parameters {
        name
        value
        uom
        metricSystemUom

imperialSystemUom
      }
      quantity
      makerId
      description
    }
  }
`;
export const DELETE_SUPPLIES = gql`
  mutation ($suppliesId: ID!) {
    deleteOneSupplies(suppliesId: $suppliesId)
  }
`;
export const SEARCH_SUPPLIES = gql`
  query searchSupplies($pagination: paginationArgType!, $searchKey: String!) {
    searchSupplies(pagination: $pagination, searchKey: $searchKey) {
      count
      items {
        id
        supplier {
          id
          name
        }
        category {
          id
          name
          value
        }
        childCategory {
          id
          name
          value
        }
        parameters {
          id
          name
          value
          metricSystemUom

imperialSystemUom
        }
        quantity
        description
      }
      hasNext
    }
  }
`;
export const PRODUCTS_LISTING = gql`
  query productsListing {
    productsListing {
      id
      name
      status
    }
  }
`;
export const PRODUCTS_TYPE = gql`
  input MaterialInput {
    suppliesId: ID!
    suppliesQuantity: Float
  }
  input orderProductsInputType {
    productId: ID!
    productDescription: String
    materials: [MaterialInput!]!
  }
`;
export const CREATE_ORDER = gql`
  mutation createOrder(
    $client: ID!
    $dueDate: String!
    $orderDescription: String!
    $products: [orderProductsInputType]!
  ) {
    createOrder(
      client: $client
      dueDate: $dueDate
      orderDescription: $orderDescription
      products: $products
    ) {
      id
      client {
        id
        name
        email
      }
      dueDate
      orderDescription
      products {
        id
        productId {
          id
          name
          status
        }
        materials {
          suppliesId {
            id
            supplier {
              name
            }
            category {
              name
            }
            childCategory {
              name
            }
            quantity
          }

          suppliesQuantity
        }
      }
      createdAt
    }
  }
`;
export const LIST_ALL_ORDERS = gql`
  query listAllOrders($pagination: paginationArgType!) {
    listAllOrders(pagination: $pagination) {
      count
      items {
        id
        client {
          id
          name
        }
        dueDate
        orderDescription
        products {
          productId {
            id
            name
          }
          productDescription
          materials {
            suppliesId {
              id
              category {
                id
                name
              }
              childCategory {
                id
                name
              }
              parameters {
                id
                name
                value
                metricSystemUom

imperialSystemUom
              }
            }
            suppliesQuantity
          }
        }
        orderStatus
        totalProductsCount
        status
        makerId
        createdAt
      }
    }
  }
`;

export const DELETE_ORDERS = gql`
  mutation deleteOneOrder($orderId: ID!) {
    deleteOneOrder(orderId: $orderId)
  }
`;
export const UPDATE_ORDERS = gql`
  mutation updateOrderDetails($orderId: ID!, $orderStatus: String) {
    updateOrderDetails(orderId: $orderId, orderStatus: $orderStatus) {
      id
      products {
        productId {
          name
        }
        productDescription
        materials {
          suppliesId {
            category {
              name
            }
          }
          suppliesQuantity
        }
      }
      orderStatus
      orderDescription
      dueDate
      status
      totalProductsCount
      createdAt
    }
  }
`;
export const GET_CLIENT_ORDER = gql`
  query getOrderOfClient($pagination: paginationArgType!, $clientId: ID!) {
    getOrderOfClient(pagination: $pagination, clientId: $clientId) {
      count
      items {
        id
        dueDate
        orderDescription
        client {
          id
          name
        }
        products {
          id
          productId {
            id
            name
          }
          productDescription
          materials {
            suppliesId {
              id
            }
            suppliesQuantity
          }
        }

        orderStatus
        totalProductsCount
        status
        makerId
        createdAt
      }
      hasNext
    }
  }
`;
export const EDIT_ORDER = gql`
  mutation editOrder(
    $orderId: ID!
    $client: ID
    $dueDate: String
    $orderDescription: String
    $products: [orderProductsInputType]
  ) {
    editOrder(
      orderId: $orderId
      client: $client
      dueDate: $dueDate
      orderDescription: $orderDescription
      products: $products
    ) {
      id
      client {
        id
        name
      }
      dueDate
      orderDescription
      products {
        id
        productId {
          id
          name
        }
        productDescription
        materials {
          suppliesId {
            id
            supplier {
              id
              name
            }
          }
          suppliesQuantity
        }
      }
      totalProductsCount
      orderStatus
      status
      makerId
      createdAt
    }
  }
`;
export const SET_MATERIALS_AND_PRODUCTS = "SET_MATERIALS_AND_PRODUCTS";

export const CONTACT_US = gql`
  mutation contactUsMail(
    $fullName: String!
    $email: String!
    $location: String!
    $message: String!
    $phoneNumber: String!
  ) {
    contactUsMail(
      fullName: $fullName
      email: $email
      location: $location
      message: $message
      phoneNumber: $phoneNumber
    )
    
  }
`;
export const PRODUCT_IDS = gql`
query getListOfAllActiveProductsFromStripe{
  getListOfAllActiveProductsFromStripe{
    id
    object
    active
    created
    default_price
    description
    features
    name
  }}`

  export const CANCEL_SUBSCRIPTION =gql`
    mutation cancelSubscriptionInStripe($subscriptionID: String!) {
      cancelSubscriptionInStripe(subscriptionID: $subscriptionID)
  }
  `

  export const SUBSCRIPTION_DETAILS =gql`
  query getSubscriptionDetails{
    getSubscriptionDetails{
      id
name
durationInDays
startDate
endDate
subscriptionID
amount
makerId{
id 
name 
email}
status
isFreeTrial
currency
invoiceNumber
quantity
invoiceCreated
expiryEmailSend
trial_start
trial_end
latestInvoiceAmountDue
latestInvoiceAmountPaid
subscriptionHistory{
  id
name
isFreeTrial

}
    }}`
    export const CHANGE_PASSWORD = gql`
    mutation changePassword($oldPassword:String! $newPassword:String!){
    changePassword(oldPassword:$oldPassword newPassword:$newPassword)
    
    }
    `