import React, { useState, useEffect ,useRef} from "react";
import DynamicTable from "../Tables/index";
import "./client.css";
import AddButton from "../Buttons/AddButton";
import Search from "../Search/Search";
import { useNavigate } from "react-router-dom";
import Snackbar from "../Snackbar/Alert";
import { isEqual } from "lodash";
import Loader from "../Buttons/Loader";
import next from "../../../src/icons/next.svg";
import prev from "../../../src/icons/prev.svg";
import * as XLSX from 'xlsx';




const Client = (props) => {
  let navigate = useNavigate();
  const tableRef = useRef(null);
  const formatNumber = (mobileNumber) => {
    const countryCode = mobileNumber.slice(0, 2);
    const formattedNumber = `+${countryCode} ${mobileNumber.slice(2, 5)} ${mobileNumber.slice(5)}`;
    return formattedNumber;
  };
  

  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);


  function handleClick() {
    navigate("/home/clients/addclients");
  }
  // console.log("prop", props);
  const handleEditClick = (id) => {
    const selectedClient = props?.clientdata?.items.find(
      (items) => items.id === id
    );
    // console.log("selec", selectedClient);
    // console.log("selected", id);

    if (selectedClient) {
      navigate("/home/clients/editclients", {
        state: { client: selectedClient },
      });
    }
  };
  const clientHeadings = ["Name", "Address", "Email", "Mobile"];
  const excelHeadings = ["Name", "Address", "Email", "Mobile","Description"];

  const [searchValue, setSearchValue] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const[totalCount,setTotalCount]=useState("")
  const [clientRows, setClientRows] = useState([]);
  const [clientIds, setClientIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // const searchCount = props?.searchdata?.searchClients?.count;
  // const totalCount = searchCount !== undefined ? searchCount : (props.count || 0);
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  useEffect(() => {
    props.listallClients({
      offset: (currentPage - 1) * itemsPerPage,
      first: itemsPerPage,
    });
    setTotalCount(props.count)
  }, [currentPage, props.deleteClientSuccess,clientRows]);

  // console.log("props", props);
// console.log("search",totalCount)  
useEffect(() => {
    const defaultClientItems = props?.clientdata?.items || [];
    const searchClientItems = props?.searchdata?.searchClients?.items || [];
    // console.log("defaultClientItems", defaultClientItems);
    // console.log("searchClientItems", searchClientItems);
    const combinedItems =
      searchValue.trim() === "" ? defaultClientItems : searchClientItems;

    // console.log("items", combinedItems);
    setClientRows((prevRows) => {
      if (
        !isEqual(
          prevRows,
          combinedItems.map((item) => [
            item?.name || "",
            item?.address || "",
            item?.email || "",
           item?.mobile, 

          ])
        )
      ) {
        return combinedItems.map((item) => [
          item?.name || "",
          item?.address || "",
          item?.email || "",
          item?.mobile, 

        ]);
      }

      return prevRows;
    });
    if (currentPage > totalPages) {
      setCurrentPage(Math.max(1, totalPages));
    }

    if (totalPages === 0) {
      setCurrentPage(1);
    }
    setClientIds((prevIds) => {
      if (
        !isEqual(
          prevIds,
          combinedItems.map((item) => item.id)
        )
      ) {
        return combinedItems.map((item) => item.id);
      }

      return prevIds;
    });
  }, [
    props?.clientdata?.items || [],
    props?.searchdata?.searchClients?.items || [],
    clientRows
  ]);

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchValue(searchInput);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (searchValue.trim() === "") {
        props.listallClients({ offset: 0, first: 10 });
        setTotalCount(props.count);
      } else {
        const response = await props.searchClients({
          pagination: { offset: 0, first: totalCount },
          searchKey: searchValue,
        });
  
        // Assuming response contains count for search results
        setTotalCount(response?.count || 0);
        setClientIds(response?.items?.map((item) => item.id) || []);
      }
    };
  
    fetchData();
  }, [searchValue]);
  
// console.log("client",clientRows)
  const handleDeleteClick = (id) => {
    props.deleteClient(id);
    setShowDeleteSnackbar(true);
    setShowErrorSnackbar(true)
    setShowLoader(true);
    if (clientIds.length === 1 && totalPages > 1) {
      // If current page becomes empty and there are more than one page,
      // move back to the previous page
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    if (showDeleteSnackbar||showErrorSnackbar) {
      const timer = setTimeout(() => {
        setShowDeleteSnackbar(false);
        setShowErrorSnackbar(false)
      }, 3000); 

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showDeleteSnackbar]);
  // console.log("props", props.count);
 
useEffect(() => {
  props.excelClients({ offset: 0, first: props?.count  });
}, []);

const handleDownloadClick = async () => {
  
  try {
    // Fetch all clients data
    

    // Extract the data
    const allClientRows = props.excelData?.items.map((item) => [
      item?.name || '',
      item?.address || '',
      item?.email || '',
      item?.mobile || '',
      item?.description || ''
    ]) || [];

    // Create the Excel sheet
    const sheet = XLSX.utils.aoa_to_sheet([excelHeadings, ...allClientRows]);

    // Create the Excel workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, 'Clients');

    // Trigger the download
    XLSX.writeFile(wb, 'Clients.xlsx');
  } catch (error) {
    // console.error('Error fetching data:', error);
  }
};

  const handleViewOrder=(id)=>{
    const selectedClient = props?.clientdata?.items.find(
      (items) => items.id === id
    );
    // console.log("selec", selectedClient);
    // console.log("selected", id);

    if (selectedClient) {
      navigate("/home/orders", {
        state: { client: selectedClient },
      });
    }

  }
  
  
  return (
    <div className="client">
      <div className="headers">
        <div className="clientheader">
          <div>Client</div>
        </div>
      </div>
      <div className="search-add-container">
    <div className="search" style={{ flex: 1 }}>
      <Search value={searchValue} onChange={handleSearch} />
    </div>
    <div className="export-button">
    <button
    className="export"
        text="Download"
        type="button"
        onClick={handleDownloadClick}
        {...props}
      >Download</button>
    </div>
    <div className="add-button">
      <AddButton
        text="Add Client"
        type="button"
        onclick={handleClick}
        {...props}
      />
    </div>
  </div>
     
      <div className="table">
      
        <DynamicTable
        ref={tableRef}
         ids={clientIds}
          headings={clientHeadings}
          rows={clientRows}
          showStatus={false}
          showEditIcon={true}
          showExportIcon={true}
          {...props}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          onExportClick={handleDownloadClick}
          onViewOrder={handleViewOrder}
          showInfoButton={true}
         
        />
        {clientRows.length === 0 ? (
          <div className="no-data-found">No data found</div>
        )
          : props.count <= 10 ?  (
          ""
        ) : props.loading  ? (
          <Loader />
        ) : (
          <div className="pagination-buttons">
            <div
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
              }
              disabled={currentPage === 1}
              className="pagination-button"
            >
              <img src={prev} alt="prev" className="pagebuttons" />
            </div>

            {Array.from({ length: totalPages }).map((_, index) => {
              const pageNumber = index + 1;

              if (totalPages <= 3 || Math.abs(currentPage - pageNumber) <= 1) {
                // Show page numbers 1, 2, 3 and ellipsis (...) if more pages
                return (
                  <div key={pageNumber} className="numbers">
                    <button
                      onClick={() => setCurrentPage(pageNumber)}
                      className={
                        currentPage === pageNumber ? "active" : "disabled"
                      }
                    >
                      {pageNumber}
                    </button>
                  </div>
                );
              } else if (
                (currentPage <= 3 && pageNumber === 4) ||
                (currentPage >= totalPages - 2 && pageNumber === totalPages - 3)
              ) {
                return (
                  <div key={pageNumber} className="ellipsis">
                    ...
                  </div>
                );
              }
              return null;
            })}

            <div
              onClick={() =>
                setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              <img src={next} alt="next" className="pagebuttons" />
            </div>
          </div>
        )}
      </div>
      {/* {console.log("showErrorSnackbar",showErrorSnackbar,props.errors)} */}
      {showDeleteSnackbar && props.deleteclientsuccess===true && (
        <Snackbar
          message= "Client Deleted Successfully" 
          type= "success" 
        />
      )}
        {showErrorSnackbar && props.deleteclientfailure === true && (
          <Snackbar message={props.errors} type="errors" />
        )}
      {showLoader && props.loading === true && <Loader />}
    </div>
  );
};

export default Client;
