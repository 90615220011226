import React, { useState,useEffect } from "react";
import { Navbuttons } from "./Navbuttons";
import { Home } from '../../icons/Home';
import { Cart } from '../../icons/Cart';
import  {Clients }   from '../../icons/Clients';
import { Suppliers } from '../../icons/Suppliers';
import  Subscription  from "../../icons/Subscription";
import Notification from "../../icons/Notification";
import { Supplies } from '../../icons/Supplies';
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import close from "../../icons/close.svg";


import './Sidebar.css'; // Import your custom styles
import Menu from "../../icons/Menu.svg"

const Sidebar = (props) => {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isRouteActive = (path) => location.pathname === path;
const [popup, setPopup]= useState(false)
const menuitems = [
  {
    index:1,
    icon:(state)=><Home className="icon-instance-node" color={state==="active"?"white":"#646161" }/>,
    text:"Home",
    name:"home",
    path:"/home"
  },
  {
    index:2,
    icon:(state)=><Clients className="icon-instance-node" color={state==="active"?"white":"#646161" } fill={state==="active"?"white":"#5C5353" } />,
    text:"Clients",
    name:"clients",
    path:"/home/clients"

  },
  {
    index:3,
    icon:(state)=><Supplies className="icon-instance-node" color={state==="active"?"white":"#646161" } fill={state==="active"?"white":"#5C5353" } />,
    text:"Supplies",
    name:"supplies",
    path:"/home/supplies"


  },
  {
    index:4,
    icon:(state)=><Suppliers className="icon-instance-node" color={state==="active"?"white":"#646161" } fill={state==="active"?"white":"#5C5353" } />,
    text:"Suppliers",
    name:"suppliers",
    path:"/home/suppliers"

  },
  {
    index:5,
    icon:(state)=><Cart className="icon-instance-node" color={state==="active"?"white":"#646161" } fill={state==="active"?"white":"#5C5353" } />,
    text:"Orders",
    name:"orders",
    path:"/home/orders"

  },
  {
    index:6,
    icon:(state)=><Subscription className="icon-instance-node" color={state==="active"?"white":"#646161" } fill={state==="active"?"white":"#5C5353" } />,
    text:"Subscription",
    name:"subscription",
    path:"/home/subdetails"

  },
  {
    index:7,
    icon:(state)=><Notification className="icon-instance-node" color={state==="active"?"white":"#646161" } fill={state==="active"?"white":"#5C5353" } />,
    text:"Anouncements",
    name:"Anouncements",
    onClick: () => setPopup(true)
    
  }
];
const togglePopup = () => {
  setPopup(!popup);
};

  const [menu, setMenu] = useState({
    home: isRouteActive("/home") ? "active" : "inactive",
    clients: isRouteActive("/home/clients") || isRouteActive("/home/clients/addclients") || isRouteActive("/home/clients/editclients") ? "active" : "inactive",
  supplies: isRouteActive("/home/supplies")|| isRouteActive("/home/supplies/addsupplies") || isRouteActive("/home/supplies/editsupplies") ? "active" : "inactive",  
  suppliers: isRouteActive("/home/suppliers")|| isRouteActive("/home/suppliers/addsuppliers") || isRouteActive("/home/suppliers/editsuppliers") ? "active" : "inactive",
  orders: isRouteActive("/home/orders")|| isRouteActive("/home/orders/addorders") || isRouteActive("/home/orders/editorders") ? "active" : "inactive",
  subscription: isRouteActive("/home/subdetails")? "active": "inactive"
  });

  const setClickedIndex = (name, status) => {
    setMenu({
      [name]: status === "active" ? "inactive" : "active"
    });
  };
  useEffect(() => {
    const pathname = location.pathname;
    setMenu({
      home: isRouteActive("/home") ? "active" : "inactive",
      clients: isRouteActive("/home/clients") || isRouteActive("/home/clients/addclients") || isRouteActive("/home/clients/editclients") ? "active" : "inactive",
      supplies: isRouteActive("/home/supplies")|| isRouteActive("/home/supplies/addsupplies") || isRouteActive("/home/supplies/editsupplies") ? "active" : "inactive",  
      suppliers: isRouteActive("/home/suppliers")|| isRouteActive("/home/suppliers/addsuppliers") || isRouteActive("/home/suppliers/editsuppliers") ? "active" : "inactive",
      orders: isRouteActive("/home/orders")|| isRouteActive("/home/orders/addorders") || isRouteActive("/home/orders/editorders") ? "active" : "inactive",
      subscription: isRouteActive("/home/subdetails")? "active": "inactive"
    });
  }, [location.pathname]);
  console.log("popup",popup)
  return (
    <div style={{height:"100%",width:"100%"}}>
      {isMobile ? (
        <div id="mobileSidebar" className={menu.home === 'active' ? 'active' : 'inactive'}>
          <div className="sidebar-header">
            <span style={{color: "#2B3674",fontWeight: "700",fontFamily: "Poppins",fontSize:'22px'}}>MAKERS</span>&nbsp;
            <span style={{color: "#2B3674",fontWeight: "400",fontFamily: "Poppins",fontSize:'22px'}}>PORTAL</span>
          </div>
          <ul className="list-unstyled components">
            {menuitems.map((obj) => (
              <li key={obj.index} onClick={() => setClickedIndex(obj.name, menu[obj.name])} onDoubleClick={(e) => e.preventDefault()}>
                <Link to={obj.path} style={{ textDecoration: 'none',color:'inherit' }}>
                  <Navbuttons
                    frameClassName="frame-instance"
                    icon={obj.icon(menu[obj.name])}
                    property1={menu[obj.name]}
                    text={obj.text}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ):( <div className="index">
      <div className="frame-2" >
        {menuitems.map((obj)=>
        <div style={{paddingBottom:'24px',}} key={obj.index} onClick={()=>setClickedIndex(obj.name,menu[obj.name])}>
        <Link to={obj.path} style={{ textDecoration: 'none',color:'inherit' }}>

        <Navbuttons
          frameClassName="frame-instance"
          icon={obj.icon(menu[obj.name])}
          property1={menu[obj.name]}
          text={obj.text}
          onClick={obj.onClick}

        />
        </Link>
        </div>
        )}
      </div>
      
      <div className="logo">
        <div className="text">
          <p className="MAKERS-PORTAL">
            <span className="span">MAKERS </span>
            <span className="text-wrapper-3">PORTAL</span>
          </p>
        </div>
      </div>
    </div>)
      }
      <div id="content">
        {isMobile && (
          <img
          src={Menu}
            type="button"
            id="sidebarCollapse"
            className={menu.home === 'active' ? 'active' : 'inactive'}
            onClick={() => setClickedIndex('home', menu.home)}
            style={{position:"fixed"}}
          />   
          
        )}
      </div>
      {popup && (
         <div id="invc">
         <div className="overlay">
           <div className="container anounce-pop"  >
            <div className="announce-head">
              Anouncements
              </div>
           <button
            className="close-display"
            onClick={() => {
              togglePopup()
            }}
          >
            <img src={close} className="close-icon"/>
          </button>
            
           </div>
         </div>
       </div>
      )}

    </div>
  );
};

export default Sidebar;
